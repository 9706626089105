import styles from './IncidentList.module.scss';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { Text } from '../../components/typography';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../constants/strings';
import { getLocalTime } from '../../utilities/time';
import EmptyStateView from '../../components/empty_state_view/EmptyStateView';
import { Spinner } from 'react-bootstrap';
import Label from '../../components/action_items/Label';
import party from '../../assets/empty_state_images/party_actions.svg';
import { useState, useMemo, useEffect } from 'react';
import {
  convertIncidentFiltersToQueryParams,
  incidentFilterOptions,
} from '../../utilities/filtering';
import TableFiltering from '../../components/sorting/TableFiltering';
import TableSorting from '../../components/sorting/TableSorting';
import { sortIncidentStrings } from '../../constants/strings';
import { getLabel, incidentStatusTypes } from '../../utilities/incidents';
import { incidentsStatusTypes } from '../../utilities/filtering';
import TablePagination from '../../components/pagination/TablePagination';
import { Icon } from '@mui/material';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { getRoute } from '../../constants/strings';
import { useTranslation } from 'react-i18next';

import {
  keys,
  getTranslationKey,
} from '../../utilities/translator/translation_keys';
const incidentsQuery = loader('./IncidentList.fetch.graphql');
const pageSize = 11;

export default function IncidentList() {
  const { workspace } = useWorkspace();
  const [sortBy, setSortBy] = useState('dateCreated');
  const [isAscending, setIsAscending] = useState(false);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const sortingOptions = [
    // 'creatorId',
    'dateCreated',
    'status',
    'title',
    'type',
    'subtype',
  ];
  const [rawFilters, setRawFilters] = useState({});
  const navigate = useNavigate();

  const filters = useMemo(() => {
    return Object.keys(rawFilters).length
      ? convertIncidentFiltersToQueryParams(rawFilters)
      : [];
  }, [rawFilters]);

  const {
    refetch,
    loading,
    data: { incidents, incidentsCount = 0 } = {},
  } = useQuery(incidentsQuery, {
    skip: !workspace?.id,
    variables: {
      options: {
        page,
        pageSize,
        sort: [
          {
            field: sortBy,
            order: isAscending ? 'asc' : 'desc',
          },
        ],
        filters: [
          ...filters,
          {
            field: 'workspaceId',
            operator: 'or',
            value: [`${workspace?.id}`, 'null'],
          },
        ],
      },
    },
  });
  useEffect(() => {
    if (workspace?.id) {
      refetch();
    }
    //eslint-disable-next-line
  }, [filters, rawFilters, setRawFilters, refetch]);

  return (
    <div className={styles.container}>
      <div className={styles.sort}>
        <TableFiltering
          filters={filters}
          setFilters={setRawFilters}
          rawFilters={rawFilters}
          filterOptions={incidentFilterOptions}
          statusOptions={incidentsStatusTypes}
          setPage={setPage}
        />
        <TableSorting
          setSortBy={setSortBy}
          sortBy={sortBy}
          setIsAscending={setIsAscending}
          refetch={refetch}
          isAscending={isAscending}
          sortingOptions={sortingOptions}
          sortStrings={sortIncidentStrings}
        />
      </div>
      <br />
      <div className={styles.content}>
        {incidents?.length ? (
          <div className={styles.tableContainer}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>
                    <Text size="sm" weight="semiBold" noMargin noSelect>
                      {t(keys.common.TITLE)}
                    </Text>
                  </th>
                  <th className={styles.hideOnMobile}>
                    <Text size="sm" weight="semiBold" noMargin noSelect>
                      {t(keys.common.TYPE)}
                    </Text>
                  </th>
                  <th className={styles.hideOnMobile}>
                    <Text size="sm" weight="semiBold" noMargin noSelect>
                      {t(keys.common.SUBTYPE)}
                    </Text>
                  </th>
                  <th>
                    <Text size="sm" weight="semiBold" noMargin noSelect>
                      {t(keys.common.CREATOR)}
                    </Text>
                  </th>
                  <th>
                    <Text size="sm" weight="semiBold" noMargin noSelect>
                      {t(keys.common.DATE)}
                    </Text>
                  </th>
                  <th>
                    <Text size="sm" weight="semiBold" noMargin noSelect>
                      {t(keys.common.STATUS)}
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {incidents?.map((incident) => (
                  <tr
                    key={incident?.id}
                    className={styles.row}
                    onClick={() =>
                      navigate(
                        getRoute(workspace?.id, paths.incident, incident?.id),
                      )
                    }
                  >
                    <td>
                      <Text size="sm" noMargin noSelect>
                        {incident?.title ? incident?.title : 'None'}
                      </Text>
                    </td>
                    <td className={styles.hideOnMobile}>
                      <Text size="sm" noMargin noSelect>
                        {t(getTranslationKey(incident?.type, 'incidents')) ||
                          incident?.type}
                      </Text>
                    </td>
                    <td className={styles.hideOnMobile}>
                      <Text size="sm" noMargin noSelect>
                        {t(getTranslationKey(incident?.subtype, 'incidents')) ||
                          incident?.subtype}
                      </Text>
                    </td>
                    <td>
                      <Text size="sm" noMargin noSelect>
                        {`${incident?.creator?.firstName} ${incident?.creator?.lastName}`}
                      </Text>
                    </td>
                    <td>
                      <Text size="sm" noMargin noSelect>
                        {getLocalTime(incident?.dateCreated).format('MM/DD/YY')}
                      </Text>
                    </td>
                    <td>
                      <div className={styles.hideOnMobile}>
                        <Label
                          className={styles.label}
                          name={getLabel(incident?.status)}
                          color={incidentStatusTypes[incident?.status]?.color}
                        />
                      </div>
                      <div className={styles.hideOnDesktop}>
                        <Icon
                          sx={{
                            color:
                              incidentStatusTypes[incident?.status]?.color ===
                              'yellow'
                                ? '#ffc107'
                                : incidentStatusTypes[incident?.status]?.color,
                            opacity: 0.7,
                            fontSize: '1.2rem',
                          }}
                        >
                          {incidentStatusTypes[incident?.status]?.icon}
                        </Icon>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <>
            {loading ? (
              <div className={styles.spinnerContainer}>
                <Spinner
                  animation="border"
                  variant="primary"
                  className={styles.spinner}
                />
              </div>
            ) : (
              <div className={styles.emptyStateContainer}>
                <EmptyStateView
                  title={t(keys.incidents.EMPTY_STATE_TITLE)}
                  text={t(keys.incidents.EMPTY_STATE_MESSAGE)}
                  image={party}
                />
              </div>
            )}
          </>
        )}
      </div>
      <TablePagination
        pageSize={pageSize}
        count={incidentsCount}
        setPage={setPage}
        page={page}
      />
    </div>
  );
}
