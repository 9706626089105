import React from 'react';
import Button from '../Button';
import styles from './WorkflowStageFooter.module.scss';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { Spinner } from 'react-bootstrap';

export default function WorkflowStageFooter({
  onNext,
  onBack,
  nextStageDisabled,
  hideBackButton,
  nextValue,
  loading = false,
}) {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      {/* <Button
        className={classNames(
          styles.nextButton,
          hideBackButton ? styles.hide : null,
        )}
        outlined={true}
        value="Back"
        onClick={onBack}
      /> */}
      {loading ? (
        <Spinner
          className={styles.spinner}
          animation="border"
          variant="primary"
        />
      ) : (
        <Button
          testId={'workflow-next'}
          className={styles.nextButton}
          disabled={nextStageDisabled}
          value={nextValue || t(keys.action.NEXT)}
          onClick={onNext}
        />
      )}
    </div>
  );
}
