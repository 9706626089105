import { loader } from 'graphql.macro';
import styles from './ObservationList.module.scss';
import { NetworkStatus, useQuery } from '@apollo/client';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { useMemo, useEffect } from 'react';
import {
  observationStatus,
  observationTypes,
} from '../../utilities/observations';
import Icon from '../../components/Icon';
import TablePrototype from '../../components/TablePrototype';
import { useState } from 'react';
import { Text } from '../../components/typography';
import { getLocalTime } from '../../utilities/time';
import SimpleUserCard from '../../components/SimpleUserCard';
import Button from '../../components/Button';
import { useMutation } from '@apollo/client';
import { useCurrentUser } from '../../providers/UserProvider';
import { useActionItemModal } from '../../hooks/misc';
import ActionItemCard from '../../components/action_items/ActionItemCard';
import { useNavigate } from 'react-router-dom';
import { getRoute, paths } from '../../constants/strings';
import { useModal } from '../../providers/ModalProvider';
import { modals } from '../../providers/modals';
import { Form } from 'react-bootstrap';
import { useWorkspacePermissions } from '../../providers/WorkspacePermissionsProvider';
import { CustomDateRangeFilter } from '../../components/tables/MTableComponents';
import { useTranslation } from 'react-i18next';
import {
  getTranslationKey,
  keys,
} from '../../utilities/translator/translation_keys';
import defaultLogo from '../../assets/workspace_image.png';
import emptyStateObservation from '../../assets/empty_state_images/templates.svg';
import { formatFilters } from '../../components/tables/MTableComponents';
import Label from '../../components/action_items/Label';
import { imageExtensions } from '../../utilities/files';
import SimpleFileCard from '../../components/files/SimpleFileCard';

const observationsQuery = loader('./ObservationList.fetch.graphql');
const updateObservationMutation = loader('./ObservationList.update.graphql');
const addActionItemMutation = loader('./ObservationList.addActionItem.graphql');

export default function ObservationsList() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState('dateCreated');
  const [isAscending, setIsAscending] = useState(false);
  const navigate = useNavigate();
  const { updateModal, modalState } = useModal();
  const { show } = modalState.observationModal;
  const { workspaceId, availableWorkspaces } = useWorkspace();
  const [filterData, setFilterData] = useState([]);
  const { allUsers } = useWorkspacePermissions();
  const { user: currentUser } = useCurrentUser();
  const [dateSelectors, setDateSelectors] = useState({});
  const { t } = useTranslation();

  const users = allUsers.map((u) => ({
    label: `${u.firstName} ${u.lastName}`,
    value: `${u.id}`,
  }));

  const workspaces = availableWorkspaces.map((w) => ({
    label: `${w.title}`,
    value: `${w.id}`,
  }));

  const filters = useMemo(() => {
    if (filterData?.length) {
      return (
        filterData
          ?.map((f) => formatFilters(f.id, f.value))
          .filter((filter) => filter !== null) || []
      );
    }
    return [];
  }, [filterData]);

  const {
    refetch,
    networkStatus,
    previousData: { observationCount: oldCount } = {},
    data: { observations = [], observationCount = 0 } = {},
  } = useQuery(observationsQuery, {
    variables: {
      options: {
        page,
        pageSize,
        sort: [{ field: sortBy, order: isAscending ? 'asc' : 'desc' }],
        filters,
      },
    },
  });

  useEffect(() => {
    updateModal({
      modalName: modals.observation,
      variables: { refetch },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'negative',
        header: '',
        size: 85,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: [
          ...Object.values(observationTypes).map((o) => ({
            value: o.key,
            label: o.title,
          })),
        ],
        Cell: ({ cell }) => {
          const key = cell.getValue() === true ? 'NEGATIVE' : 'POSITIVE';
          const value = {
            icon: observationTypes[key].icon,
            color: observationTypes[key].color,
          };
          return (
            <Icon opacity={0.5} color={value.color}>
              {value.icon}
            </Icon>
          );
        },
      },
      {
        accessorKey: 'description',
        header: t(keys.common.DESCRIPTION),
        grow: true,
        Cell: ({ cell }) => (
          <Text noMargin weight="semiBold" size="sm" truncate>
            {cell.getValue()}
          </Text>
        ),
      },
      {
        accessorKey: 'creator',
        header: t(keys.common.CREATOR),
        filterVariant: 'select',
        filterSelectOptions: users,
        enableSorting: false,
        grow: true,
        Cell: ({ cell }) =>
          !!cell.getValue() ? (
            <SimpleUserCard size="sm" user={cell.getValue()} />
          ) : (
            <Text noMargin size="sm" weight="semiBold">
              Anonymous
            </Text>
          ),
      },
      {
        accessorKey: 'status',
        header: t(keys.common.STATUS),
        size: 120,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: [
          ...Object.values(observationStatus)
            .filter((s) =>
              currentUser.role !== 'ADMIN'
                ? s.key !== 'NEW' && s.key !== 'INVALID'
                : s.key !== 'INVALID',
            )
            .map((s) => ({
              value: s.key,
              label: s.title,
            })),
        ],
        Cell: ({ cell }) => {
          return cell.getValue() ? (
            <Label
              noMargin
              name={
                t(
                  getTranslationKey(
                    observationStatus[cell.getValue()]?.title,
                    'common',
                  ),
                ) ?? cell.getValue()
              }
              weight="semiBold"
              color={
                !cell.getValue()
                  ? 'green'
                  : observationStatus[cell.getValue()]?.color || 'primary'
              }
            />
          ) : null;
        },
      },
      {
        accessorKey: 'dateTime',
        header: t(keys.observations.DATE_AND_TIME),
        filter: 'date-range',
        size: 275,
        grow: true,
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dateTimeMin}
            maxDate={dateSelectors.dateTimeMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateTimeMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateTimeMax: value })
            }
          />
        ),
        Cell: ({ cell }) => (
          <Text noMargin weight="semiBold" size="sm">
            {getLocalTime(cell.getValue()).format('MMMM Do, YYYY HH:mm')}
          </Text>
        ),
      },
      {
        accessorKey: 'reviewer',
        header: t(keys.observations.REVIEWER),
        grow: true,
        enableSorting: false,
        filterVariant: 'select',
        filterSelectOptions: users,
        Cell: ({ cell }) => <SimpleUserCard size="sm" user={cell.getValue()} />,
      },
      {
        accessorKey: 'closer',
        header: t(keys.observations.CLOSER),
        grow: true,
        enableSorting: false,
        filterVariant: 'select',
        filterSelectOptions: users,
        Cell: ({ cell }) => <SimpleUserCard size="sm" user={cell.getValue()} />,
      },
      {
        accessorKey: 'workspace',
        header: t(keys.common.WORKSPACE),
        enableSorting: false,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: workspaces,
        Cell: ({ row, cell }) => (
          <>
            <img
              src={cell.getValue().logoUrl || defaultLogo}
              className={styles.workspaceLogo}
              alt={'workspace_img'}
            />
            <Text noMargin weight="semiBold" size="sm" truncate>
              {cell.getValue().title}
            </Text>
          </>
        ),
      },
      {
        accessorKey: 'dateCreated',
        header: t(keys.common.DATE_CREATED),
        filterVariant: 'date-range',
        grow: true,
        size: 350,
        Cell: ({ cell }) => (
          <Text noMargin weight="semiBold" size="sm" truncate>
            {getLocalTime(cell.getValue()).fromNow()}
          </Text>
        ),
      },
      {
        accessorKey: 'id',
        header: 'ID',
        Cell: ({ cell }) => (
          <Text noMargin weight="semiBold" size="sm" truncate>
            {cell.getValue()}
          </Text>
        ),
      },
    ],
    [dateSelectors, t, users, workspaces, currentUser],
  );

  return (
    <div className={styles.table}>
      <TablePrototype
        isLoading={networkStatus !== NetworkStatus.ready}
        columns={columns}
        data={observations || []}
        count={observationCount || oldCount}
        setPage={setPage}
        setPageSize={setPageSize}
        setSortBy={setSortBy}
        setIsAscending={setIsAscending}
        setFilters={(filters) => {
          setFilterData(filters);
        }}
        customToolbar={
          <ToolbarComponents
            t={t}
            setFilterData={setFilterData}
            workspaceId={workspaceId}
          />
        }
        enableExpanding={true}
        enableExpandAll={true}
        renderDetailPanel={({ row }) => (
          <ObservationExpansionCard row={row} refetch={refetch} />
        )}
        onClearAllFilters={() => {
          setFilterData([]);
          setDateSelectors({});
        }}
        onRowClick={(observation) =>
          navigate(
            getRoute(
              observation.workspace.id,
              paths.observation,
              observation.id,
            ),
          )
        }
        emptyState={{
          title: t(keys.action.NOT_FOUND, {
            variable: t(keys.common.OBSERVATIONS),
          }),
          text:
            filters.length > 2
              ? t(keys.action.EMPTY_STATE_CHECK_FILTERS, {
                  variable: t(keys.common.OBSERVATIONS),
                })
              : t(keys.action.EMPTY_STATE_MESSAGE, {
                  variable: t(keys.common.OBSERVATIONS),
                }),
          image: emptyStateObservation,
        }}
      />
    </div>
  );
}

const ToolbarComponents = ({ workspaceId, setFilterData, t }) => (
  <div className={styles.additionalFilters}>
    <div className={styles.check}>
      <Form.Check
        size="sm"
        onChange={(e) => {
          const newFilter = {
            id: 'workspaceId',
            value: `${workspaceId}`,
          };
          if (e.target.checked) {
            setFilterData((prevFilters) => [...prevFilters, newFilter]);
          } else {
            setFilterData([]);
          }
        }}
      />
      <Text noMargin size="sm">
        {t(keys.observations.THIS_WORKSPACE)}
      </Text>
    </div>
  </div>
);

function ObservationExpansionCard({ row, refetch }) {
  const {
    original: {
      dateTime,
      participants,
      location,
      description,
      negative,
      images,
      creator,
      reviewer,
      id,
      dateReviewed,
      actionItems,
      workspace,
      status,
      incident,
      closer,
      dateClosed,
    },
  } = row || {};

  const { t } = useTranslation();
  const { user, isAdmin } = useCurrentUser();
  const [updateObservation] = useMutation(updateObservationMutation);
  const [addActionItem] = useMutation(addActionItemMutation);
  const { openActionItemModal } = useActionItemModal();
  const navigate = useNavigate();
  const readOnly =
    status === observationStatus.COMPLETE.key ||
    status === observationStatus.INVALID.key ||
    !isAdmin;

  const isCreator = user?.id === creator?.id;

  return (
    <div
      className={styles.container}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className={
          status === observationStatus.INVALID.key
            ? styles.gray
            : negative
            ? styles.red
            : styles.green
        }
      />
      <div className={styles.card}>
        <div className={styles.left}>
          <Text
            noMargin
            weight="semiBold"
            color={observationStatus[status]?.color}
            className={styles.statusText}
          >
            {t(getTranslationKey(status, 'common'))?.toUpperCase() ?? ''}
          </Text>
          <div className={styles.row}>
            <div className={styles.column}>
              <Text weight="semiBold" size="sm" color="secondaryLight" noMargin>
                {t(keys.observations.OBSERVER)}
              </Text>
              {!!creator ? (
                <SimpleUserCard user={creator} />
              ) : (
                <Text noMargin weight="semiBold">
                  {t(keys.observations.ANONYMOUS)}
                </Text>
              )}
            </div>
            <div className={styles.column}>
              <Text weight="semiBold" size="sm" color="secondaryLight" noMargin>
                {t(keys.observations.DATE_AND_TIME)}
              </Text>
              <Text weight="semiBold" noMargin>
                {getLocalTime(dateTime).format('dddd, MMM D YYYY HH:mm')}
              </Text>
            </div>
            <div className={styles.column}>
              <Text weight="semiBold" size="sm" color="secondaryLight" noMargin>
                {t(keys.common.LOCATION)}
              </Text>
              {!location ? (
                <Text noMargin weight="semiBold">
                  {t(keys.common.NONE)}
                </Text>
              ) : (
                <Text weight="semiBold" noMargin>
                  {location}
                </Text>
              )}
            </div>
          </div>
          <div className={styles.bottom}>
            <Text weight="semiBold" size="sm" color="secondaryLight" noMargin>
              {t(keys.common.DESCRIPTION)}
            </Text>
            <Text weight="semiBold" noMargin>
              {description}
            </Text>
            <Text weight="semiBold" size="sm" color="secondaryLight" noMargin>
              {t(keys.common.FILES)}
            </Text>
            {!images?.length ? (
              <Text noMargin weight="semiBold">
                {t(keys.common.NONE)}
              </Text>
            ) : (
              <div className={styles.images}>
                {images.map((image, index) => {
                  const extension = image.imageUrl
                    .split('.')
                    .pop()
                    .toLowerCase();
                  const isImage = imageExtensions.includes(extension);
                  const file = {
                    ...image,
                    fileType: extension,
                    url: image.imageUrl,
                  };
                  return isImage ? (
                    <img
                      key={image.id}
                      className={styles.image}
                      alt={`observation-${image.id}`}
                      src={image.imageUrl}
                    />
                  ) : (
                    <SimpleFileCard
                      extension={extension}
                      file={file}
                      fileName={`${extension.toUpperCase()}-Attachment`}
                    />
                  );
                })}
              </div>
            )}
            <Text weight="semiBold" size="sm" color="secondaryLight" noMargin>
              {t(keys.observations.TEAM_MEMBERS_PRESENT)}
            </Text>
            {!participants?.length ? (
              <Text weight="semiBold" noMargin>
                {t(keys.common.NONE)}
              </Text>
            ) : (
              <div className={styles.team}>
                {participants.map((user) => (
                  <SimpleUserCard user={user} key={user.id} size="sm" />
                ))}
              </div>
            )}
          </div>
          <br />
          <Text
            color="accentPrimary"
            size="sm"
            hover
            onClick={() =>
              navigate(getRoute(workspace.id, paths.observation, id))
            }
          >
            {t(keys.dashboard.SHOW_MORE).toUpperCase()}
          </Text>
        </div>
        <div className={styles.right}>
          {!!incident ? (
            <div className={styles.incidentText}>
              <Icon color="red" style={{ fontSize: '1.2rem' }}>
                fmd_bad
              </Icon>
              <Text noMargin weight="semiBold" color="red">
                {`${t(keys.boards.INCIDENT_REPORTED)}`}
              </Text>
              {(isCreator || isAdmin) && (
                <Text
                  color="accentPrimary"
                  hover
                  size="sm"
                  noMargin
                  onClick={() =>
                    navigate(
                      getRoute(
                        incident?.workspace?.id,
                        paths.incident,
                        incident?.id,
                      ),
                    )
                  }
                >
                  {t(keys.assessments.VIEW).toUpperCase()}
                </Text>
              )}
            </div>
          ) : null}
          {!!reviewer ? (
            <div className={styles.column}>
              <div className={styles.header}>
                <div>
                  <Text
                    weight="semiBold"
                    size="sm"
                    color="secondaryLight"
                    noMargin
                    className={styles.text}
                  >
                    {`${t(keys.files.REVIEWED)} ${getLocalTime(
                      dateReviewed,
                    ).format('dddd MMM DD YYYY')}`}
                  </Text>
                  <SimpleUserCard user={reviewer} />
                </div>
                {!readOnly ? (
                  <Button
                    value={t(keys.action_items.CLOSE)}
                    variant="success"
                    size="sm"
                    align="right"
                    className={styles.smallButton}
                    onClick={() => {
                      updateObservation({
                        variables: {
                          id,
                          closerId: user.id,
                          status: observationStatus.COMPLETE.key,
                        },
                      });
                    }}
                  />
                ) : null}
                {status === observationStatus.COMPLETE.key ? (
                  <div>
                    <Text
                      weight="semiBold"
                      size="sm"
                      color="secondaryLight"
                      noMargin
                      className={styles.text}
                    >
                      {`${t(keys.action_items.CLOSED)} ${getLocalTime(
                        dateClosed,
                      ).format('dddd MMM DD YYYY')}`}
                    </Text>
                    <SimpleUserCard user={closer} />
                  </div>
                ) : null}
              </div>
              <div className={styles.spacer} />
              <div className={styles.header}>
                <Text color="accentPrimary" noMargin weight="semiBold">
                  {t(keys.common.ACTION_ITEMS)}
                </Text>
                {!readOnly && (
                  <Icon
                    color="primary"
                    hover
                    onClick={() => {
                      addActionItem({
                        variables: {
                          title: `Observation ${id}`,
                          description,
                          type: 'OBSERVATION',
                          observationId: id,
                          workspaceId: workspace.id,
                        },
                      }).then(
                        ({
                          data: {
                            addActionItem: { id },
                          },
                        }) => {
                          openActionItemModal(id, 'OBSERVATION', () =>
                            refetch(),
                          );
                        },
                      );
                    }}
                  >
                    add
                  </Icon>
                )}
              </div>
              {actionItems?.length ? (
                <div className={styles.actionItems}>
                  {actionItems.map((a) => (
                    <ActionItemCard
                      actionItem={a}
                      onClick={() =>
                        openActionItemModal(a.id, 'OBSERVATION', () => {})
                      }
                    />
                  ))}
                </div>
              ) : (
                <Text noMargin weight="semiBold">
                  {t(keys.common.NONE)}
                </Text>
              )}
            </div>
          ) : !readOnly ? (
            <div className={styles.buttons}>
              <Button
                value={t(keys.common.INVALID)}
                variant="danger"
                size="sm"
                className={styles.smallButton}
                onClick={() => {
                  updateObservation({
                    variables: {
                      id,
                      reviewerId: user.id,
                      status: observationStatus.INVALID.key,
                    },
                  });
                }}
              />
              <Button
                value={t(keys.observations.ACKNOWLEDGE)}
                size="sm"
                className={styles.smallButton}
                onClick={() => {
                  updateObservation({
                    variables: {
                      id,
                      reviewerId: user.id,
                      status: observationStatus.IN_PROGRESS.key,
                    },
                  });
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
