import React, { useMemo, useState } from 'react';
import WorkflowSidebar from '../../components/workflows/WorkflowSidebar';
import styles from './CreateInspectionTemplate.module.scss';
import CreateVehicleFeatures from './CreateVehicleFeatures';
import CreateVehicleImage from './CreateVehicleImage';
import CreateVehicleInfo from './CreateVehicleInfo';
import ReviewVehicle from './ReviewVehicle';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';

export default function CreateVehicle() {
  const { t, i18n } = useTranslation();
  const createVehicleWorkflow = useMemo(
    () => [
      {
        title: t(keys.assets.P1_SIDEBAR_HEADER),
        subtitle: t(keys.assets.P1_SIDEBAR_SUBTEXT),
        icon: 'edit',
        component: CreateVehicleInfo,
        key: 'General Info',
      },
      {
        title: t(keys.assets.P2_HEADER),
        subtitle: t(keys.assets.P2_SIDEBAR_SUBTEXT),
        icon: 'photo_camera',
        component: CreateVehicleImage,
        key: 'Reference Image',
      },
      {
        title: t(keys.assets.P3_SIDEBAR_HEADER),
        subtitle: t(keys.assets.P3_SIDEBAR_SUBTEXT),
        icon: 'category',
        component: CreateVehicleFeatures,
        key: 'Features',
      },
      {
        title: t(keys.assets.P4_SIDEBAR_HEADER),
        subtitle: t(keys.assets.P4_SIDEBAR_SUBTEXT),
        icon: 'done',
        component: ReviewVehicle,
        key: 'Review and Finalize',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.resolvedLanguage],
  );

  const [vehicleId, setVehicleId] = useState();
  const [currentWorkflow, setCurrentWorkflow] = useState(
    createVehicleWorkflow[0].key,
  );
  const [completedWorkflows, setCompletedWorkflows] = useState([]);

  const Page = useMemo(
    () =>
      createVehicleWorkflow.find((workflow) => workflow.key === currentWorkflow)
        .component,
    [currentWorkflow, createVehicleWorkflow],
  );

  return (
    <div className={styles.container}>
      <div className={styles.pageContainer}>
        <div className={styles.subPageContainer}>
          <Page
            workflow={createVehicleWorkflow}
            currentStage={currentWorkflow}
            completed={completedWorkflows}
            vehicleId={vehicleId}
            setVehicleId={(id) => setVehicleId(id)}
            onCompleted={(completedWorkflow) => {
              setCompletedWorkflows([...completedWorkflows, completedWorkflow]);
              const currentIndex = createVehicleWorkflow.findIndex(
                (item) => item.key === currentWorkflow,
              );

              if (currentIndex + 1 === createVehicleWorkflow.length) {
              } else {
                setCurrentWorkflow(createVehicleWorkflow[currentIndex + 1].key);
              }
            }}
          />
        </div>
      </div>
      <WorkflowSidebar
        workflow={createVehicleWorkflow}
        selected={currentWorkflow}
        completed={completedWorkflows}
        onChange={(newSelection) => setCurrentWorkflow(newSelection)}
      />
    </div>
  );
}
