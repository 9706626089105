import Modal from '../Modal';
import { useState } from 'react';
import { useModal } from '../../../providers/ModalProvider';
import { modals } from '../../../providers/modals';
import SpeechToText from '../../SpeechToText';
import UserMultiSelector from '../../UserMultiSelector';
import { Text } from '../../typography';
import { useTranslation } from 'react-i18next';
import { keys } from '../../../utilities/translator/translation_keys';
import { Form } from 'react-bootstrap';
import styles from './RecordMeetingModal.module.scss';
import { useWorkspace } from '../../../providers/WorkspaceProvider';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { Icon } from '@mui/material';
import {
  openAnalysisModal,
  openFileViewer,
  showToast,
} from '../../../graphql/cache/modal';
import Button from '../../Button';
import { useApolloClient } from '@apollo/client';
import { Spinner } from 'react-bootstrap';
import findFileIcon from '../../../utilities/files';
import {
  getExtension,
  internalTemplateMEETING,
} from '../../../utilities/inspection';
import { fileType, toastLength, toastVariant } from '../../../constants/misc';

const createMeetingInspectionMutation = loader(
  './RecordMeetingModal.create.graphql',
);
const inspectionQuery = loader('./RecordMeetingModal.fetch.graphql');

export default function RecordMeetingModal() {
  const {
    closeModal,
    modalState,
    updateModal,
    openModal,
    openConfirmationModal,
  } = useModal();
  const { workspaceId } = useWorkspace();
  const { t } = useTranslation();
  const {
    show,
    participants,
    internalType,
    location,
    description,
    safetyTopic,
    externalParticipants,
    attachment,
    refetch,
    onView,
  } = modalState.recordMeetingModal;
  const [file, setFile] = useState(null);
  const [createMeetingInspection] = useMutation(
    createMeetingInspectionMutation,
  );

  const onClose = () => {
    closeModal({ modalName: modals.recordMeeting });
    setTranscript('');
    setIsRecording(false);
    setSecondSight(true);
    setFile(null);
    refetch();
  };
  const onHide = () => {
    if (transcript || file || attachment) {
      openConfirmationModal({
        title: t(keys.assessments.CLOSE_CONFIRMATION_TITLE),
        description: t(keys.assessments.CLOSE_CONFIRMATION_MESSAGE),
        variant: 'danger',
        buttonText: t(keys.action_items.CLOSE),
        onSubmit: () => {
          onClose();
        },
      });
    } else {
      onClose();
    }
  };
  const update = (variables) =>
    updateModal({
      modalName: modals.recordMeeting,
      variables: { ...variables },
    });
  const [isRecording, setIsRecording] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [loading, setLoading] = useState(false);
  const [secondSight, setSecondSight] = useState(true);
  const client = useApolloClient();

  const footer = (
    <div className={styles.footer}>
      <div className={styles.check}>
        <Text noMargin weight="semiBold">
          {t(keys.assessments.SECOND_SIGHT_ANALYSIS)}
        </Text>
        <Form.Check
          type="switch"
          onChange={(e) => setSecondSight(e.target.checked)}
          checked={secondSight}
        />
      </div>
      <Button
        disabled={isRecording || loading}
        value={t(keys.action.SUBMIT)}
        onClick={() => {
          const items = internalTemplateMEETING(
            description,
            file,
            transcript,
            safetyTopic,
            location,
            externalParticipants,
            attachment,
          );
          openConfirmationModal({
            title: t(keys.assessments.SUBMIT_CONFIRMATION_TITLE),
            description: t(keys.assessments.SUBMIT_CONFIRMATION_MESSAGE),
            variant: 'warning',
            onSubmit: async () => {
              try {
                const {
                  data: {
                    useInternalTemplate: { id },
                  },
                } = await createMeetingInspection({
                  variables: {
                    workspaceId,
                    internalType,
                    items,
                    participants: participants?.map(({ id }) => id) || [],
                  },
                });

                if (!!secondSight) {
                  const {
                    data: { inspections: [inspection] = [{ setFile }] },
                  } = await client.query({
                    query: inspectionQuery,
                    variables: { id: `${id}` },
                  });
                  onClose();
                  openAnalysisModal({
                    inspection,
                  });
                } else {
                  onClose();
                }
                showToast({
                  title: t(keys.assessments.SUBMIT_TITLE),
                  message: t(keys.assessments.SUBMIT_TITLE),
                  variant: toastVariant.info,
                  time: toastLength.md,
                });
              } catch (error) {
                console.error(error);
              }
            },
          });
        }}
      />
    </div>
  );

  return (
    <Modal
      open={show || false}
      onClose={onHide}
      title={t(keys.assessments.RECORD_MEETING)}
      allowClose={!isRecording}
      hideCancel
      hideSubmit
      beta
      footer={footer}
      titleImage={
        <Text size="lg" noMargin>
          📆
        </Text>
      }
    >
      <div className={styles.warning}>
        <Text color="yellow" textAlign="center" weight="semiBold" noMargin>
          Early Access Feature
        </Text>
        <Text
          className={styles.textBody}
          color="secondary"
          size="sm"
          textAlign="center"
        >
          Your company has early access to this experimental feature. The voice
          to text tool may have some bugs which will be fixed in the near
          future. You can always contact us at support@opasmobile.com if you
          have any issues.
        </Text>
      </div>
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <div className={styles.header}>
            <Text noMargin weight="semiBold">
              {t(keys.assessments.SAFETY_TOPIC)}
            </Text>
            {!!onView && (
              <Text
                hover
                onClick={() => onView()}
                noMargin
                size="sm"
                color="secondary"
              >
                {t(keys.assessments.VIEW)}
              </Text>
            )}
          </div>
          <Form.Control
            value={safetyTopic}
            as="textarea"
            rows={3}
            size="sm"
            onChange={(e) => update({ safetyTopic: e.target.value })}
            className={styles.input}
          />
          <Text noMargin weight="semiBold">
            {t(keys.assessments.MEETING_DESCRIPTION)}
          </Text>
          <Form.Control
            as="textarea"
            value={description}
            size="sm"
            onChange={(e) => update({ description: e.target.value })}
            className={styles.input}
          />
          <Text noMargin weight="semiBold">
            {t(keys.assessments.LOCATION)}
          </Text>
          <Form.Control
            size="sm"
            value={location}
            onChange={(e) => update({ location: e.target.value })}
            className={styles.input}
          />
          <Text weight="semiBold" noMargin>
            {t(keys.assessments.EXTERNAL_PARTICIPANTS)}
          </Text>
          <Form.Control
            as="textarea"
            size="sm"
            className={styles.input}
            value={externalParticipants}
            onChange={(e) => update({ externalParticipants: e.target.value })}
          />
          <Text noMargin weight="semiBold">
            {t(keys.common.PARTICIPANTS)}
          </Text>
          <UserMultiSelector
            selected={participants}
            type="users"
            onUserAdded={(user) =>
              update({ participants: [...participants, user] })
            }
            onUserRemoved={(user) => {
              update({
                participants: participants.filter((u) => u.id !== user.id),
              });
            }}
          />
          <div className={styles.header}>
            <Text noMargin weight="semiBold">
              {t(keys.assessments.ATTACHMENT)}
            </Text>
            <Icon
              disabled={loading}
              baseClassName="material-icons-outlined"
              className={styles.icon}
              sx={{ color: attachment ? 'grey' : 'blue' }}
              onClick={() => {
                attachment
                  ? update({ attachment: null })
                  : openModal({
                      modalName: modals.fileUpload,
                      variables: {
                        assessment: true,
                        simple: true,
                        onSubmit: ({ url, name }) => {
                          const extension = url.split('.').pop();
                          update({
                            attachment: {
                              url,
                              name,
                              downloadAllowed: true,
                              fileType: fileType[extension],
                            },
                          });
                          setLoading(false);
                        },
                        onLoading: () => {
                          setLoading(true);
                        },
                      },
                    });
              }}
            >
              {attachment ? 'delete' : 'add'}
            </Icon>
          </div>

          {loading ? (
            <Spinner animation="border" variant="primary" />
          ) : attachment ? (
            <div className={styles.header}>
              <div
                className={styles.fileCard}
                onClick={() => openFileViewer(attachment)}
              >
                <img
                  src={findFileIcon(getExtension(attachment?.url))}
                  alt="meeting_attachment"
                  className={styles.image}
                ></img>
                <Text weight="semiBold" noMargin>
                  {attachment?.name}
                </Text>
              </div>
            </div>
          ) : (
            <Text>{t(keys.common.NONE)}</Text>
          )}
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.header}>
            <Text weight="semiBold" noMargin>
              {t(keys.assessments.VOICE_RECORDER)}
            </Text>
            {isRecording && (
              <Icon sx={{ color: 'green' }}>radio_button_checked</Icon>
            )}
          </div>
          <div className={styles.recorder}>
            <SpeechToText
              isRecording={isRecording}
              setIsRecording={setIsRecording}
              transcript={transcript}
              setTranscript={setTranscript}
              setFile={setFile}
              file={file}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
