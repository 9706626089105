import React, { useEffect, useState } from 'react';
import styles from './LeftSidebarPrototype.module.scss';
import fullLogo from '../../../assets/logo_blue_black.svg';
import smallLogo from '../../../assets/logo_grad.svg';
import SidebarOption from './sidebar/SidebarOption';
import { Icon } from '@mui/material';
import { useSpring, animated } from 'react-spring';
import { useNavigate } from 'react-router-dom';
import { Text } from '../../../components/typography';
import { truncate } from '../../../utilities/strings';
import { useReactiveVar } from '@apollo/client';
import { navigationExpandedVar } from '../../../graphql/cache/navbar';
import classNames from 'classnames';
import { useCurrentUser } from '../../../providers/UserProvider';
import { paths } from '../../../constants/strings';
import { useOnlineStatus } from '../../../hooks/offline-hooks/offline-misc';

const companyScopedKeys = [
  'timecards',
  'expenses',
  'announcements',
  'insights',
  'analytics',
  'settings',
  'tasks',
  'toolbox',
];

export default function LeftSidebarPrototype({
  collapsed,
  setCollapsed,
  options,
  bottomOptions,
}) {
  const navigate = useNavigate();
  const isOnline = useOnlineStatus();
  const { user: currentUser } = useCurrentUser();
  const navigationExpanded = useReactiveVar(navigationExpandedVar);

  const { width } = useSpring({
    config: { duration: 500 },
    from: { width: '14rem' },
    width: collapsed ? '4.375rem' : '14rem',
  });

  const [hideText, setHideText] = useState(collapsed);

  useEffect(() => {
    if (collapsed && !hideText) {
      setHideText(true);
    } else if (!collapsed && hideText) {
      setTimeout(() => {
        setHideText(false);
      }, 500);
    }
  }, [collapsed, hideText]);

  return (
    <div className={!navigationExpanded ? styles.hide : null}>
      <animated.div
        id="left-sidebar-prototype"
        style={{ width }}
        className={styles[`container${collapsed ? 'Collapsed' : ''}`]}
      >
        <div className={styles.top}>
          {collapsed ? (
            <img
              onClick={() => navigate('/')}
              src={smallLogo}
              className={styles.smallLogo}
              alt="opas mobile icon"
            />
          ) : (
            <img
              onClick={() => navigate('/')}
              src={fullLogo}
              className={styles.logo}
              alt="opas mobile logo"
            />
          )}

          <div className={styles.hr} />
          <div
            className={classNames([
              styles[`projectContainer${collapsed ? 'Collapsed' : ''}`],
            ])}
          >
            <div
              className={styles.userContainer}
              onClick={() => {
                if (isOnline) {
                  navigate(`/${paths.user}/${currentUser?.id}`);
                }
              }}
            >
              <img
                src={currentUser?.avatarUrl}
                className={styles.avatar}
                alt={currentUser?.firstName}
              />
              {hideText ? null : (
                <div className={styles.right}>
                  <Text noSelect weight="bold" noMargin>
                    {truncate(currentUser?.firstName, 7)}
                  </Text>
                  <Text noSelect weight="semiBold" noMargin color="secondary">
                    {truncate(currentUser?.company?.name, 7)}
                  </Text>
                </div>
              )}
            </div>
            {collapsed ? (
              <Icon
                className={styles.expandIcon}
                onClick={() => setCollapsed(false)}
              >
                chevron_right
              </Icon>
            ) : (
              <Icon
                className={styles.collapseIcon}
                onClick={() => setCollapsed(true)}
              >
                chevron_left
              </Icon>
            )}
          </div>
        </div>
        <div className={styles.middle}>
          {options.map((option) => (
            <SidebarOption
              collapsed={collapsed}
              key={option.title}
              selected={
                window.location.pathname.split('/')[
                  companyScopedKeys.includes(option.param) ? 1 : 3
                ] === option.param
              }
              onSelect={() => {
                if (option.path) {
                  navigate(option.path);
                }
                if (option.onClick) {
                  option.onClick(option.title);
                }
                navigationExpandedVar(false);
              }}
              title={option.title}
              icon={option.icon}
            />
          ))}
        </div>
        <div className={styles.bottom}>
          {bottomOptions.map((option) => (
            <SidebarOption
              collapsed={collapsed}
              key={option.title}
              selected={window.location.pathname.split('/')[1] === option.param}
              onSelect={() => {
                if (option.path) {
                  navigate(option.path);
                }
                if (option.onClick) {
                  option.onClick(option.title);
                }
                navigationExpandedVar(false);
              }}
              title={option.title}
              icon={option.icon}
            />
          ))}
        </div>
      </animated.div>
    </div>
  );
}
