import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './Inspection.module.scss';
import { loader } from 'graphql.macro';
import { Text } from '../../components/typography';
import { useMutation, useQuery } from '@apollo/client';
import { Spinner, Form } from 'react-bootstrap';
import Accordian from '../../components/Accordian';
import InspectionItemText from '../../components/inspections/inspection_items/InspectionItemText';
import { defaultNavbarState, navbarVar } from '../../graphql/cache/navbar';
import Button from '../../components/Button';
import {
  alertVar,
  openAnalysisModal,
  showToast,
} from '../../graphql/cache/modal';
import { toastVariant, toastLength } from '../../constants/misc';
import RightSidebar from './RightSidebar';
import { Icon } from '@mui/material';
import classNames from 'classnames';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { paths } from '../../constants/strings';
import AssessmentAnalysisCard from '../../components/machine_learning/AssessmentAnalysisCard';
import {
  hasObservations,
  noObservations,
} from '../../components/machine_learning/riskAnalysis';
import { getRoute } from '../../constants/strings';
import noImage from '../../assets/icons/image_blank.png';
import { useInspection } from '../../hooks/offline-hooks/createInspectionhook';
import { useOnlineStatus } from '../../hooks/offline-hooks/offline-misc';
import { useCurrentUser } from '../../providers/UserProvider';
import { useTranslation } from 'react-i18next';
import {
  keys,
  getTranslationKey,
} from '../../utilities/translator/translation_keys.js';
import { isValidUUID } from '../../utilities/offline_hooks_setup_apollo/offlineUtils.js';
import { useModal } from '../../providers/ModalProvider.jsx';
import { formatBundleItems } from '../../utilities/inspection.js';
import ReviewFooter from './ReviewFooter/ReviewFooter.jsx';

const inspectionQuery = loader('./Inspection.inspection.graphql');
const publishMutation = loader('./Inspection.complete.graphql');

const checkCategoryFailure = (category, itemMap) =>
  category.questions.some((question) => !!itemMap[question.id]?.failed);

export default function Inspection() {
  const { id } = useParams();
  const { workspace } = useWorkspace();
  const { user: currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [resetTableInput, setResetTableInput] = useState(false);
  const { openConfirmationModal } = useModal();
  const { completeInspection, updateNotes, updateBundle, removeBundle } =
    useInspection();
  useEffect(() => {
    navbarVar({ ...defaultNavbarState, showMenu: false });
  }, []);

  const [publishInspection] = useMutation(publishMutation);
  const online = useOnlineStatus();
  const { t } = useTranslation();

  const completeKey = t(keys.common.COMPLETE);

  const calculateRequiredProgress = (category, itemMap) => {
    let completed = 0;
    let total = category.questions.filter((q) => q.required).length;
    const isBundle = category.isBundle;

    if (isBundle) {
      let bundleMultiplier = 1;
      category.questions.forEach((question) => {
        if (itemMap[question.id]) {
          const numberOfBundles = itemMap[question.id]?.length || 1;
          bundleMultiplier = Math.max(bundleMultiplier, numberOfBundles);
        }
      });
      total *= bundleMultiplier;
    }

    category.questions.forEach((question) => {
      if (isBundle && !!itemMap[question.id]) {
        itemMap[question.id].forEach((b) => {
          if (!!b.value.length && question.required) {
            completed += 1;
          }
        });
      } else if (!!itemMap[question.id] && question.required) {
        completed += 1;
      }
    });

    return completed === total;
  };

  const calculateCategoryProgress = (category, itemMap) => {
    let completed = 0;
    const total = category.questions.length;
    category.questions.forEach((question) => {
      if (!!itemMap[question.id]) {
        completed += 1;
      }
    });
    return completed === total
      ? completeKey
      : `${
          !calculateRequiredProgress(category, itemMap) ? '* ' : ''
        }${completed} / ${total}`;
  };

  const { refetch, data: { inspections: [inspection] = [{}] } = {} } = useQuery(
    inspectionQuery,
    {
      skip: online ? isNaN(id) : !isValidUUID(id) && isNaN(id),
      variables: {
        id: `${id}`,
      },
    },
  );

  const isDraft = inspection.isDraft && !inspection.readyForReview;

  const { itemMap, bundleMap } = useMemo(() => {
    let itemMap = {};
    let bundleMap = {};

    if (!inspection?.items) {
      return { bundleMap, itemMap };
    }

    return formatBundleItems(inspection.items);
  }, [inspection]);

  const submitDisabled = useMemo(() => {
    return !inspection?.template?.categories?.every((category) => {
      return calculateRequiredProgress(category, itemMap);
    });
  }, [inspection?.template?.categories, itemMap]);

  const [notes, setNotes] = useState('');
  useEffect(() => setNotes(inspection?.notes), [inspection]);

  const submitToast = () => {
    setTimeout(() => {
      showToast({
        title: t(keys.action.COMPLETED, {
          variable:
            inspection.inspectionType === 'VEHICLE'
              ? t(keys.common.INSPECTION)
              : t(keys.common.REPORT),
        }),
        message: t(keys.assessments.SUBMITTED_MESSAGE, {
          variable:
            inspection.inspectionType === 'VEHICLE'
              ? t(keys.common.INSPECTION)
              : t(keys.common.REPORT),
        }),
        variant: toastVariant.info,
        time: toastLength.md,
      });
    }, 1000);
  };
  return (
    <div className={styles.split}>
      <div
        className={classNames([styles.container, sidebarOpen && styles.hide])}
      >
        {inspection?.id ? (
          <div className={styles.container}>
            <div className={styles.topContainer}>
              <div className={styles.header}>
                <Text noMargin weight="bold" size="lg" color="accentPrimary">
                  {inspection.template.internalType
                    ? t(
                        getTranslationKey(
                          inspection.template.title,
                          'assessments',
                        ),
                      )
                    : `${inspection.template.title}${
                        inspection.template.revisionNumber
                          ? ` (Rev ${inspection.template.revisionNumber})`
                          : ''
                      }`}
                </Text>
                <Text noMargin weight="bold" size="lg">
                  {inspection.vehicle?.unitNumber}
                </Text>
                <div
                  onClick={() => {
                    setSidebarOpen(true);
                  }}
                  className={classNames([styles.hideDesktop])}
                >
                  <Icon baseClassName="material-icons-outlined">
                    more_horiz
                  </Icon>
                </div>
              </div>
              <Text color="secondary" weight="semiBold">
                {inspection.template.description}
              </Text>
              <div className={styles.imageContainer}>
                {!!inspection?.vehicle?.id && (
                  <img
                    alt="vehicle"
                    className={styles.vehicleImage}
                    src={
                      inspection.vehicle.imageUrl
                        ? inspection.vehicle.imageUrl
                        : noImage
                    }
                  />
                )}
                {!!inspection?.template?.images?.length &&
                  inspection.template.images.map(({ imageUrl }) => (
                    <img
                      key={imageUrl}
                      className={styles.vehicleImage}
                      alt="reference"
                      src={imageUrl ? imageUrl : noImage}
                    />
                  ))}
              </div>
            </div>
            {!isDraft && (
              <div className={styles.middleContainer}>
                <AssessmentAnalysisCard
                  message={
                    inspection?.riskAnalysis
                      ? t(hasObservations.message)
                      : t(noObservations.message)
                  }
                  analysis={inspection?.riskAnalysis?.split('|') || null}
                  disclaimer={
                    inspection?.riskAnalysis
                      ? t(hasObservations.disclaimer)
                      : t(noObservations.disclaimer)
                  }
                />
              </div>
            )}
            <br />
            <div className={styles.bottomContainer}>
              {inspection.template.categories.map((category) => (
                <div key={`category-${category.id}`}>
                  <Accordian
                    isBundle={category?.isBundle}
                    icon={category.isBundle ? 'layers' : null}
                    iconProps={{
                      color: '#0d6efd',
                      outlined: true,
                      position: 'right',
                    }}
                    title={
                      !!inspection.template.internalType
                        ? t(getTranslationKey(category.title, 'assessments'))
                        : category.title
                    }
                    messageVariant={
                      checkCategoryFailure(category, itemMap)
                        ? 'red'
                        : 'primary'
                    }
                    message={calculateCategoryProgress(category, itemMap)}
                  >
                    {category.isBundle &&
                      !!bundleMap[category.id] &&
                      Object.entries(bundleMap[category.id]).map(
                        ([bundleId, answers], idx) => (
                          <div
                            key={`bundle-${bundleId}`}
                            className={styles.bundleContainer}
                          >
                            <Text
                              className={styles.bundleNumber}
                              noMargin
                              weight="semiBold"
                            >{`${idx + 1}. `}</Text>
                            <div
                              className={
                                idx % 2 !== 0
                                  ? styles.oddBundle
                                  : styles.evenBundle
                              }
                            >
                              {Object.values(answers).map((item) => {
                                const question = category.questions.find(
                                  (question) => question.id === item.questionId,
                                );
                                return (
                                  <div
                                    key={`bundle-answer-${item.id}`}
                                    className={styles.bundleQuestion}
                                  >
                                    <InspectionItemText
                                      questionId={question.id}
                                      inspectionId={inspection.id}
                                      vehicleId={inspection.vehicle?.id}
                                      vehicleUnitNumber={
                                        inspection.vehicle?.unitNumber
                                      }
                                      inspectionType={inspection.inspectionType}
                                      title={question.title}
                                      description={question.description}
                                      item={item}
                                      additionalData={question.additionalData}
                                      inspectionQuery={inspectionQuery}
                                      required={question.required}
                                      isDraft={isDraft}
                                      inputType={question.inputType}
                                      inspectionTitle={
                                        inspection.template.title
                                      }
                                      workspaceId={workspace?.id}
                                      internalType={
                                        inspection.template.internalType
                                      }
                                    />
                                  </div>
                                );
                              })}
                              {isDraft && (
                                <Icon
                                  className={styles.deleteIcon}
                                  sx={{ fontSize: '1.2rem' }}
                                  onClick={() =>
                                    openConfirmationModal({
                                      title: t(keys.action.DELETE_VARIABLE, {
                                        variable: t(keys.assessments.BUNDLE),
                                      }),
                                      description: t(
                                        keys.action.DELETE_CONFIRMATION,
                                        {
                                          variable: t(keys.assessments.BUNDLE),
                                        },
                                      ),
                                      variant: 'danger',
                                      confirmText: t(keys.action.DELETE),
                                      onSubmit: () => {
                                        removeBundle({
                                          inspectionId: inspection.id,
                                          bundleId,
                                        });
                                      },
                                    })
                                  }
                                >
                                  delete_outlined
                                </Icon>
                              )}
                            </div>
                          </div>
                        ),
                      )}

                    {!category?.isBundle &&
                      category.questions.map((question, idx) => (
                        <div
                          key={`question-${question.id}`}
                          className={idx % 2 !== 0 ? styles.odd : styles.even}
                        >
                          <InspectionItemText
                            questionId={question.id}
                            inspectionId={inspection.id}
                            vehicleId={inspection.vehicle?.id}
                            vehicleUnitNumber={inspection.vehicle?.unitNumber}
                            inspectionType={inspection.inspectionType}
                            title={question.title}
                            description={question.description}
                            item={itemMap[question.id]}
                            additionalData={question.additionalData}
                            inspectionQuery={inspectionQuery}
                            required={question.required}
                            isDraft={isDraft}
                            inputType={question.inputType}
                            inspectionTitle={inspection.template.title}
                            workspaceId={workspace?.id}
                            internalType={inspection.template.internalType}
                          />
                        </div>
                      ))}
                    {category.isBundle && isDraft && (
                      <div className={styles.bundleButtons}>
                        <Button
                          outlined
                          onClick={() => {
                            const items = [];
                            category.questions.forEach((question) => {
                              items.push(itemMap[question.id]);
                            });
                            updateBundle({
                              inspectionId: inspection.id,
                              categoryId: category.id,
                              questions: category.questions,
                            });
                            setResetTableInput(true);
                          }}
                          icon={'layers'}
                          value={t(keys.assessments.ADD_QUESTIONS)}
                        />
                      </div>
                    )}
                  </Accordian>
                  <br />
                </div>
              ))}
              <Accordian
                title={t(keys.common.NOTES)}
                icon="text_snippet"
                size="md"
                accordianSize={true}
              >
                {isDraft ? (
                  <div>
                    <br />
                    <Form onSubmit={(e) => e.preventDefault()}>
                      <Form.Group>
                        <Form.Control
                          as="textarea"
                          rows="10"
                          value={notes || ''}
                          onChange={(e) => setNotes(e.target.value)}
                          onBlur={() => updateNotes(inspection.id, notes)}
                        ></Form.Control>
                      </Form.Group>
                    </Form>
                  </div>
                ) : (
                  <div>
                    <br />
                    <Text>{inspection.notes || t(keys.common.NONE)}</Text>
                  </div>
                )}
              </Accordian>
              <br />
              <br />
              <ReviewFooter
                allQuestionsAnswered={!submitDisabled}
                inspection={inspection}
                currentUser={currentUser}
                onSecondSight={(completedCallback) => {
                  openAnalysisModal({
                    inspection: inspection,
                    onSubmit: () => {
                      completedCallback();
                    },
                  });
                }}
                onComplete={() => {
                  if (online && inspection?.template?.analysisRequested)
                    openAnalysisModal({
                      inspection: inspection,
                      onSubmit: () => {
                        publishInspection({
                          variables: {
                            id: inspection.id,
                          },
                        }).then(() => {
                          alertVar({ ...alertVar(), show: false });
                          inspection.incident
                            ? navigate(
                                getRoute(
                                  workspace?.id,
                                  paths.incident,
                                  inspection?.incident.id,
                                ),
                              )
                            : inspection.timecard
                            ? navigate(
                                `/${paths.timecard}/?id=${inspection.timecard.id}`,
                              )
                            : navigate(
                                getRoute(workspace?.id, paths.assessments),
                              );
                          submitToast();
                        });
                      },
                    });
                  else {
                    openConfirmationModal({
                      title: t(keys.action.CONFIRM_CHANGES),
                      description: t(keys.action.PUBLISH_CONFIRMATION),
                      variant: 'warning',
                      buttonText: t(keys.action.PUBLISH),
                      onSubmit: () => {
                        completeInspection(inspection.id).then(() => {
                          alertVar({ ...alertVar(), show: false });
                          inspection.incident
                            ? navigate(
                                getRoute(
                                  workspace?.id,
                                  paths.incident,
                                  inspection.incident.id,
                                ),
                              )
                            : inspection.timecard
                            ? navigate(
                                `/${paths.timecard}/?id=${inspection.timecard.id}`,
                              )
                            : navigate(
                                getRoute(workspace?.id, paths.assessments),
                              );
                          submitToast();
                        });
                      },
                    });
                  }
                }}
              />
            </div>
          </div>
        ) : (
          <Spinner
            className={styles.spinner}
            animation="border"
            variant="primary"
          />
        )}
      </div>

      {!!inspection?.id && (
        <RightSidebar
          onClose={() => setSidebarOpen(false)}
          className={!sidebarOpen && styles.hide}
          inspection={inspection}
          refetch={refetch}
        />
      )}
    </div>
  );
}
