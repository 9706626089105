import React from 'react';
import { Page, Text, View, Document, Image, Link } from '@react-pdf/renderer';
import { PageHeader, PageFooter, styles, PageHeaderLogo } from './constants';
import { Notes } from './constants';
import { useTranslation } from 'react-i18next';
import { keys } from '../translator/translation_keys';
import { formatBundleItems, numberCheck } from '../inspection';
import { inputTypes } from '../../constants/strings';

const textPrimary = '#1d1d1f';

// Create Document Component
export const AssessmentDocument = ({ inspection, workspace }) => {
  const { t } = useTranslation();
  const company = inspection?.creator?.company;

  const Column = ({ answer, children, inputType, additional }) => {
    let style = styles.semiBoldText;
    const numberLimit =
      !!answer && !!children && !!additional && inputType === inputTypes.number;

    if (children === 'PASS' || (numberLimit && !answer?.failed)) {
      style = styles.textPass;
    } else if (
      children === 'FAIL' ||
      (numberLimit && answer?.failed) ||
      answer?.failed
    ) {
      style = styles.textFail;
    }

    let component;
    if (children) {
      switch (inputType) {
        case 'USER_MULTI':
          component = (
            <Text style={style}>
              {answer?.users
                ?.map((user) => `${user.firstName} ${user.lastName}`)
                .join(', ')}
            </Text>
          );
          break;
        case 'MATRIX':
          component = (
            <View>
              <Text style={style}>Impact: {answer?.impact}/5</Text>
              <Text style={style}>Probability: {answer?.probability}/5</Text>
            </View>
          );
          break;
        case 'SIGNATURE_SINGLE':
          component = (
            <View wrap={false}>
              <Text style={style}>{answer?.additional}</Text>
              <Image style={styles.signature} src={children} />
            </View>
          );
          break;
        case 'SIGNATURE_MULTI':
          component = (
            <View>
              {children?.split('|').map((child, index) => (
                <View key={child + index} wrap={false}>
                  {!!answer?.additional && (
                    <Text key={child + index} style={style}>
                      {answer?.additional?.split('|')[index]}
                    </Text>
                  )}
                  <Image
                    key={child + index}
                    style={styles.signature}
                    src={child}
                  />
                </View>
              ))}
            </View>
          );
          break;
        case 'IMAGE':
          component = (
            <View style={styles.imageContainer}>
              <Image style={styles.image} src={children} />
            </View>
          );
          break;
        case 'IMAGE_MULTI':
          component = (
            <View style={styles.imageContainer}>
              {children?.split('|').map((child, index) => (
                <Image key={child + index} style={styles.image} src={child} />
              ))}
            </View>
          );
          break;
        case 'USER':
          component = (
            <Text
              style={styles.semiBoldText}
            >{`${answer?.user?.firstName} ${answer?.user?.lastName}`}</Text>
          );
          break;
        case 'DATE':
          component = (
            <Text style={styles.semiBoldText}>
              {answer?.value?.split('T')[0]}
            </Text>
          );
          break;
        case 'SELECT_MULTI':
          component = (
            <Text style={style}>{children?.split('|').join(', ')}</Text>
          );
          break;
        case 'FILE_MULTI':
          component = (
            <View wrap={false}>
              {children?.split('|').map((file, index) => (
                <Link src={file} style={styles.link} key={file + index}>
                  {answer?.additional?.split('|')[index]}
                </Link>
              ))}
            </View>
          );
          break;
        case 'FILE_SINGLE':
          component = (
            <Link src={children} style={styles.link}>
              {answer?.additional}
            </Link>
          );
          break;
        case 'TEST':
          component = (
            <View>
              <Text style={style}>
                {children === 'NOT_APPLICABLE' ? 'N/A' : children}
              </Text>
            </View>
          );
          break;
        case 'AUDIO':
          component = (
            <View>
              <Text style={style}>{children}</Text>
              <Link src={children} style={styles.link}>
                {t(keys.assessments.VOICE_RECORDING)}
              </Link>
            </View>
          );
          break;
        case undefined:
          break;
        default:
          component = <Text style={style}>{children}</Text>;
          break;
      }
    } else {
      component = (
        <Text style={style}>{t(keys.assessments.NO_ANSWER_PROVIDED)}</Text>
      );
    }
    const FailReport = answer?.failed ? (
      <View>
        {answer.user && (
          <Text
            style={styles.semiBoldText}
          >{`Action Item Assigned: ${answer.user.firstName} ${answer.user.lastName} `}</Text>
        )}
        {answer.failNotes && (
          <Text style={styles.failText}>{answer.failNotes}</Text>
        )}
        {answer.failImage && (
          <Image style={styles.failImage} src={answer.failImage} />
        )}
      </View>
    ) : null;

    return (
      <View>
        {component}
        {FailReport}
      </View>
    );
  };

  const Table = ({ question, answer }) => {
    const tableWidth = 500;
    const numberOfColumns = question?.additionalData?.split('|')?.length || 0;
    const columnWidth = tableWidth / numberOfColumns;
    return answer?.value ? (
      <View
        style={{
          maxWidth: tableWidth,
          marginBottom: 2,
          paddingBottom: 3,
          borderBottom: 1,
          borderColor: '#abb5be',
        }}
        wrap={false}
      >
        <Text
          style={{
            ...styles.text,
            marginTop: 5,
            marginBottom: 5,
          }}
        >
          {question?.title}
        </Text>
        <View
          style={{
            ...styles.tableRow,
            border: `1px solid ${textPrimary}`,
            padding: 6,
            paddingBottom: 3,
          }}
        >
          {question?.additionalData?.split('|').map((str, index) => (
            <View
              style={{ width: columnWidth }}
              key={`table-headers-${str}-${index}`}
            >
              <Text
                style={{
                  ...styles.semiBoldText,
                  width: columnWidth,
                }}
              >
                {str}
              </Text>
            </View>
          ))}
        </View>
        {answer?.value?.split('|').map((answerValue, index) => (
          <View
            key={`table-row-${answer.id}-${index}`}
            style={{
              ...styles.tableRow,
              borderBottom: `1px solid ${textPrimary}`,
              borderLeft: `1px solid ${textPrimary}`,
              borderRight: `1px solid ${textPrimary}`,
              padding: 6,
              paddingBottom: 3,
            }}
          >
            {answerValue?.split('\n').map((textValue, index) => (
              <View
                style={{ width: columnWidth }}
                key={`table-content-${answer.id}-${index}`}
              >
                <Text style={styles.text}>{textValue}</Text>
              </View>
            ))}
          </View>
        ))}
      </View>
    ) : (
      <View style={styles.row}>
        <Text style={styles.text}>{question?.title}</Text>
        <Text style={styles.semiBoldText}>
          {t(keys.assessments.NO_ANSWER_PROVIDED)}
        </Text>
      </View>
    );
  };
  const getAnswer = (answers, question) => {
    return answers?.find((a) => a.questionId === question.id)
      ? answers?.find((a) => a.questionId === question.id)?.value
      : null;
  };

  const Category = ({ category, categoryId, isBundle, questions, answers }) => {
    const { bundleMap } = formatBundleItems(answers);

    return (
      <View style={styles.category}>
        <View style={styles.categoryHeaderContainer}>
          <Text style={styles.categoryHeader}>{category}</Text>
          {isBundle && (
            <View>
              <Image
                style={styles.bundleIcon}
                alt={'bundle_icon'}
                src={
                  'https://app.opasmobile.com/assets/graphics/layers_pdf_icon.png'
                }
              />
            </View>
          )}
        </View>
        {isBundle ? (
          <>
            {!!bundleMap[categoryId] &&
              Object.entries(bundleMap[categoryId]).map(
                ([bundleId, bundleAnswers]) => (
                  <View key={`bundle-${bundleId}`} style={styles.bundleAnswer}>
                    {Object.values(bundleAnswers).map((answer, index) => {
                      if (answer.categoryId !== categoryId) return [];
                      const question = questions.find(
                        (question) => question.id === answer.questionId,
                      );

                      return question.inputType === 'TABLE' ? (
                        <Table
                          key={`tbl-${question.id}-${bundleId}-${index}`}
                          question={question}
                          answer={answer}
                        />
                      ) : (
                        <View
                          key={`question-${question.id}-${bundleId}-${index}`}
                        >
                          <View
                            style={styles.bundleRow}
                            wrap={
                              question.inputType === 'IMAGE_MULTI'
                                ? true
                                : false
                            }
                          >
                            <Text style={styles.text}>{question.title}</Text>
                            <Column
                              answer={answer}
                              inputType={question.inputType}
                              additional={question.additionalData}
                            >
                              {answer.value}
                            </Column>
                          </View>
                          {question.inputType === inputTypes.number &&
                          !!question.additionalData ? (
                            <Text style={styles.limitsText}>
                              {numberCheck(question.additionalData)}
                            </Text>
                          ) : null}
                        </View>
                      );
                    })}
                  </View>
                ),
              )}
            <View style={styles.bundleAnswer}>
              {questions.map((question, index) => {
                const answer = answers?.find(
                  (a) => a.questionId === question.id && !a.bundleId,
                );
                return question.inputType === 'TABLE' ? (
                  <Table
                    key={`tbl-${question.id}-${index}`}
                    question={question}
                    answer={answer}
                  />
                ) : (
                  <View key={`question-${question.id}-${index}`}>
                    <View
                      style={styles.bundleRow}
                      wrap={question.inputType === 'IMAGE_MULTI' ? true : false}
                    >
                      <Text style={styles.text}>{question.title}</Text>

                      <Column
                        answer={answer}
                        inputType={question.inputType}
                        additional={question.additionalData}
                      >
                        {answer?.value}
                      </Column>
                    </View>
                    {question.inputType === inputTypes.number &&
                    !!question.additionalData ? (
                      <Text style={styles.limitsText}>
                        {numberCheck(question.additionalData)}
                      </Text>
                    ) : null}
                  </View>
                );
              })}
            </View>
          </>
        ) : (
          questions?.map((question, index) =>
            question.inputType === 'TABLE' ? (
              <Table
                key={`tbl-${question.id}-${index}`}
                question={question}
                answer={answers?.find((a) => a.questionId === question.id)}
              />
            ) : (
              <View key={`question-${question.id}-${index}`}>
                <View
                  style={styles.row}
                  wrap={
                    question.inputType === 'IMAGE_MULTI' ||
                    question.inputType === 'SIGNATURE_MULTI'
                      ? true
                      : false
                  }
                >
                  <View>
                    <Text style={styles.text}>{question.title}</Text>
                    {question.inputType === inputTypes.number &&
                    !!question.additionalData ? (
                      <Text style={styles.limitsText}>
                        {numberCheck(question.additionalData)}
                      </Text>
                    ) : null}
                  </View>
                  <Column
                    answer={answers?.find((a) => a.questionId === question.id)}
                    inputType={question.inputType}
                    additional={question.additionalData}
                  >
                    {getAnswer(answers, question)}
                  </Column>
                </View>
              </View>
            ),
          )
        )}
      </View>
    );
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <PageHeaderLogo company={company} />
        <PageHeader
          company={company}
          data={inspection}
          title={inspection.template.title}
          workspace={workspace}
          type="ASSESSMENT"
        />
        {!!inspection.template?.images?.length && (
          <View style={styles.templateImageContainer}>
            {inspection.template.images.map(({ imageUrl, index }) => (
              <Image
                style={styles.templateImage}
                src={imageUrl}
                key={`${imageUrl.id}-${index}`}
              />
            ))}
          </View>
        )}
        <br />
        <View>
          <Text style={styles.titleHeader}>{inspection.template.title}</Text>
          <Text style={styles.headerText}>
            {inspection.template.description}
          </Text>
        </View>
        <View style={styles.categoryContainer}>
          {inspection.template.categories.map((category, index) => (
            <Category
              key={`${category.id}-${index}`}
              category={category.title}
              categoryId={category.id}
              isBundle={category.isBundle}
              questions={category.questions}
              answers={inspection.items}
            />
          ))}
        </View>
        <Notes notes={inspection?.notes} style={styles.categoryContainer} />
        <PageFooter />
      </Page>
    </Document>
  );
};
