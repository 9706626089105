import styles from './SimpleTabSystem.module.scss';
import { Text } from './typography';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { getRoute } from '../constants/strings';

export default function SimpleTabSystem({
  options,
  selected,
  setSelected,
  route,
  workspaceId,
}) {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.tabsContainer}>
        {options.map((option, index) => (
          <div
            role="button"
            tabIndex={0}
            className={styles.optionContainer}
            onClick={() => {
              setSelected(option.key);
              workspaceId
                ? navigate(getRoute(workspaceId, route, option.key))
                : navigate(`/${route}/${option.key}`);
            }}
            key={option + index}
          >
            <Text
              textAlign="center"
              noMargin
              color={selected === option.key ? 'primary' : 'secondary'}
            >
              {option.title}
            </Text>
            <div
              className={classNames(
                styles.selectedFooter,
                selected === option.key ? styles.show : styles.hide,
              )}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
