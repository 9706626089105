import styles from './ProgressModal.module.scss';
import { Text } from '../typography';
import { ProgressBar } from 'react-bootstrap';
import { Icon } from '@mui/material';

export default function ProgressModal({
  progress,
  title,
  message,
  icon,
  show,
}) {
  return show ? (
    <div className={styles.background}>
      <div className={styles.container}>
        <Text noMargin size="lg" weight="semiBold">
          {title}
        </Text>
        <br />
        <ProgressBar
          animated={progress !== 100}
          variant={progress === 100 ? 'success' : 'primary'}
          now={progress}
        />
        <br />
        <div className={styles.content}>
          <Icon sx={{ fontSize: '6rem' }}>{icon}</Icon>
          <Text noMargin textAlign="center" weight="semiBold">
            {message}
          </Text>
        </div>
      </div>
    </div>
  ) : null;
}
