import React, { useMemo } from 'react';
import { Text } from '../../components/typography';
import styles from './InspectionsList.module.scss';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import SimpleUserCard from '../../components/SimpleUserCard';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../constants/strings';
import { getLocalTime } from '../../utilities/time';
import { useState } from 'react';
import { convertInspectionFiltersToQueryParams } from '../../utilities/filtering';
import { assessmentTypes } from '../../constants/strings';
import { Icon } from '@mui/material';
import { useWorkspace } from '../../providers/WorkspaceProvider.jsx';
import { useWorkspacePermissions } from '../../providers/WorkspacePermissionsProvider.jsx';
import { getRoute } from '../../constants/strings';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys.js';
import TablePrototype from '../../components/TablePrototype.jsx';
import { useQueryParams } from '../../hooks/misc.js';
import emptyStateInspection from '../../assets/empty_state_images/inspections.svg';
import { getColor } from '../../constants/strings';

const inspectionsQuery = loader('./InspectionsList.graphql');

export default function InspectionsList({ parent }) {
  const { workspace } = useWorkspace();
  const { allWorkspaceUsers } = useWorkspacePermissions();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState('dateCreated');
  const [isAscending, setIsAscending] = useState(false);
  const [rawFilters, setRawFilters] = useState({});
  const { t } = useTranslation();
  const { getParam } = useQueryParams();
  const creatorFilter = getParam('creator');

  const { filters, subfilters } = useMemo(() => {
    return convertInspectionFiltersToQueryParams(rawFilters, workspace?.id);
  }, [rawFilters, workspace?.id]);

  const { loading, data: { inspections = [], inspectionsCount = 0 } = {} } =
    useQuery(inspectionsQuery, {
      skip: !page || !pageSize || !filters || !sortBy || !workspace?.id,
      variables: {
        options: {
          page,
          pageSize,
          sort: [{ field: sortBy, order: isAscending ? 'asc' : 'desc' }],
          filters,
          subfilters,
        },
      },
      fetchPolicy: 'cache-and-network',
    });

  const userOptions = useMemo(() => {
    const userOptions = allWorkspaceUsers.map((p) => ({
      label: `${p.firstName} ${p.lastName}`,
      value: p.id,
    }));
    return userOptions;
  }, [allWorkspaceUsers]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'inspectionType',
        header: t(keys.common.TYPE),
        size: 100,
        filterVariant: 'select',
        filterSelectOptions: [
          ...Object.keys(assessmentTypes)
            .filter((f) => f !== 'observation' && f !== 'incident')
            .map((type) => ({
              value: assessmentTypes[type].key,
              label: t(assessmentTypes[type].title),
            })),
        ],
        Cell: ({ cell }) => (
          <Icon
            baseClassName="material-icons-outlined"
            sx={{
              fontSize: '1.25rem',
              color: getColor(cell?.getValue()?.toLowerCase()),
            }}
          >
            {assessmentTypes[cell.getValue().toLowerCase()]?.icon ||
              'local_shipping'}
          </Icon>
        ),
      },
      {
        accessorFn: (row) => row?.template?.title,
        accessorKey: 'template.title',
        header: t(keys.common.TITLE),
        grow: true,
        enableSorting: false,
        minSize: 150,
      },
      {
        accessorKey: 'creator',
        header: t(keys.common.CREATOR),
        minSize: 150,
        grow: true,
        enableSorting: false,
        filterVariant: 'select',
        filterSelectOptions: userOptions,
        Cell: ({ cell }) => <SimpleUserCard size="sm" user={cell.getValue()} />,
      },
      {
        accessorKey: 'isDraft',
        header: t(keys.common.STATUS),
        Header: ({ column }) => (
          <div style={{ textWrap: 'nowrap' }}>{column.columnDef.header}</div>
        ),
        minSize: 150,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: [
          { value: 'true', label: t(keys.common.IN_PROGRESS) },
          { value: 'false', label: t(keys.common.COMPLETE) },
        ],
        Cell: ({ cell }) => (
          <Text noMargin weight="semiBold" size="sm">
            {cell.getValue()
              ? t(keys.common.IN_PROGRESS)
              : t(keys.common.COMPLETE)}
          </Text>
        ),
      },
      {
        accessorKey: 'passed',
        header: t(keys.common.PASSED),
        minSize: 150,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: [
          { value: 'true', label: t(keys.common.PASSED) },
          { value: 'false', label: t(keys.common.FAILED) },
        ],
        Cell: ({ cell }) => (
          <Icon
            baseClassName="material-icons-outlined"
            sx={{ color: cell.getValue() ? '#28a745' : '#e9222c' }}
          >
            {cell.getValue() ? 'check' : 'close'}
          </Icon>
        ),
      },
      {
        accessorKey: 'dateModified',
        header: t(keys.common.DATE_SUBMITTED),
        filterVariant: 'date-range',
        size: 350,
        Cell: ({ cell }) => (
          <Text noMargin size="sm" weight="semiBold">
            {getLocalTime(cell.getValue()).format('MMM Do, YYYY')}
          </Text>
        ),
      },
      {
        accessorKey: 'dateCreated',
        header: t(keys.common.DATE_CREATED),
        filterVariant: 'date-range',
        size: 350,
        Cell: ({ cell }) => (
          <Text noMargin size="sm" weight="semiBold">
            {getLocalTime(cell.getValue()).format('MMM Do, YYYY')}
          </Text>
        ),
      },
    ],
    [t, userOptions],
  );
  return (
    <div className={styles.container}>
      <TablePrototype
        isLoading={loading}
        rightClickNavigation={paths.assessment}
        columns={columns}
        data={inspections || []}
        count={inspectionsCount}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        setRawFilters={setRawFilters}
        setSortBy={setSortBy}
        setIsAscending={setIsAscending}
        onRowClick={(assessment) =>
          navigate(getRoute(workspace?.id, paths.assessment, assessment.id))
        }
        initialState={{
          columnVisibility: { passed: false },
          columnFilters: creatorFilter
            ? [{ id: 'creator', value: creatorFilter }]
            : [],
        }}
        emptyState={{
          title: t(keys.action.NOT_FOUND, {
            variable: t(keys.common.ASSESSMENTS),
          }),
          text:
            filters.length > 2 || subfilters.length > 0
              ? t(keys.assessments.EMPTY_STATE_FILTERS)
              : t(keys.assessments.EMPTY_STATE_MESSAGE),
          image: emptyStateInspection,
        }}
      />
    </div>
  );
}
