import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import styles from './ToolboxLibrary.module.scss';
import { Text } from '../../components/typography';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../constants/strings';
import {
  textCell,
  userCell,
  dateCellFromNow,
  userFilterOptions,
} from '../../components/tables/MTableComponents';
import TablePrototype from '../../components/TablePrototype';
import { NetworkStatus } from '@apollo/client';
import { useWorkspacePermissions } from '../../providers/WorkspacePermissionsProvider';
import { formatFilters } from '../../components/tables/MTableComponents';
import { toolboxTalkStatus } from '../../utilities/toolboxtalks';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FlagDisabled from '../flagDisabled/FlagDisabled';

const toolboxTalkQuery = loader('./ToolboxTalkGenerator.fetch.graphql');

export default function ToolboxLibrary() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState('dateCreated');
  const [isAscending, setIsAscending] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const { allUsers } = useWorkspacePermissions();
  const { t } = useTranslation();

  const filters = useMemo(() => {
    if (filterData?.length) {
      return (
        filterData
          ?.map((f) => formatFilters(f.id, f.value))
          .filter((filter) => filter !== null) || []
      );
    }
    return [];
  }, [filterData]);

  const {
    networkStatus,
    previousData: { toolboxTalkCount: oldCount } = {},
    data: { toolboxTalks = [], toolboxTalkCount = 0 } = {},
  } = useQuery(toolboxTalkQuery, {
    variables: {
      options: {
        page,
        pageSize,
        sort: [{ field: sortBy, order: isAscending ? 'asc' : 'desc' }],
        filters,
      },
    },
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: 'title',
        header: t(keys.common.TITLE),
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorKey: 'description',
        header: t(keys.common.DESCRIPTION),
        minSize: 300,
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorKey: 'creator',
        header: t(keys.common.CREATOR),
        enableSorting: false,
        Cell: ({ cell }) => userCell({ cell }),
        filterSelectOptions: userFilterOptions(allUsers),
        filterVariant: 'select',
      },
      {
        accessorKey: 'isDraft',
        header: t(keys.common.STATUS),
        Cell: ({ cell }) => (
          <Text noMargin weight="semiBold" size="sm" truncate>
            {!cell.getValue() ? 'Published' : 'Draft'}
          </Text>
        ),
        filterVariant: 'select',
        filterSelectOptions: [
          ...Object.values(toolboxTalkStatus).map(({ value, label }) => ({
            value,
            label,
          })),
        ],
      },
      {
        accessorKey: 'dateCreated',
        header: t(keys.common.DATE_CREATED),
        size: 350,
        Cell: ({ cell }) => dateCellFromNow({ cell }),
        filterVariant: 'date-range',
      },
    ],
    [allUsers, t],
  );

  const { enableCustomToolboxTalks } = useFlags();

  return enableCustomToolboxTalks ? (
    <div className={styles.container}>
      <TablePrototype
        isLoading={networkStatus !== NetworkStatus.ready}
        columns={columns}
        data={toolboxTalks || []}
        count={toolboxTalkCount || oldCount}
        setPage={setPage}
        setPageSize={setPageSize}
        setSortBy={setSortBy}
        setIsAscending={setIsAscending}
        setFilters={(filters) => {
          setFilterData(filters);
        }}
        initialState={{ showColumnFilters: true, density: 'compact' }}
        onRowClick={(tbt) => navigate(`/${paths.toolbox}/${tbt.id}`)}
      />
    </div>
  ) : (
    <FlagDisabled />
  );
}
