import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import SidebarPrototypePage from './pages/SidebarPrototypePage';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { MutationQueueProvider } from './providers/MutationQueueProvider';
import { useCurrentUser } from './providers/UserProvider';
import { ModalProvider } from './providers/ModalProvider';
import { WorkspaceProvider } from './providers/WorkspaceProvider';
import NoAccess from './pages/NoAccess';
import ConfirmationModalProvider from './providers/ConfirmationModalProvider';
import { WorkspacePermissionsProvider } from './providers/WorkspacePermissionsProvider';
import { ProductFruits } from 'react-product-fruits';
import { TemplateProvider } from './providers/TemplateProvider';
import { URLParamProvider } from './providers/URLParamProvider';

export function WebApp() {
  const ldClient = useLDClient();
  const { user, isAdmin } = useCurrentUser();
  const userInfo = {
    username: user?.email,
    firstname: user?.firstName,
    lastname: user?.lastName,
    props: { isAdmin: `${isAdmin}` },
  };

  useEffect(() => {
    if (user && ldClient) {
      ldClient.identify({
        key: user.id,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        custom: {
          'company-id': user.company.id,
          'company-name': user.company.name,
          role: user.role,
        },
      });
    }
  }, [ldClient, user]);

  return (
    <>
      <ProductFruits
        workspaceCode={process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE}
        language="en"
        user={userInfo}
      />
      <WorkspaceProvider>
        <WorkspacePermissionsProvider>
          <URLParamProvider>
            <TemplateProvider>
              <ModalProvider>
                <ConfirmationModalProvider>
                  <NoAccess>
                    <MutationQueueProvider user={user}>
                      <SidebarPrototypePage>
                        <Outlet />
                      </SidebarPrototypePage>
                    </MutationQueueProvider>
                  </NoAccess>
                </ConfirmationModalProvider>
              </ModalProvider>
            </TemplateProvider>
          </URLParamProvider>
        </WorkspacePermissionsProvider>
      </WorkspaceProvider>
    </>
  );
}

export default WebApp;
