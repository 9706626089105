import styles from './Dashboard.module.scss';
import { Text } from '../../components/typography';
import QuickAction from './QuickAction';
import { Spinner } from 'react-bootstrap';
import ActionItemSlimCard from './ActionItemSlimCard';
import SafetyShareCard from './SafetyShareCard';
import { useActionItemModal } from '../../hooks/misc';
import InspectionCard from '../../components/inspections/inspection_items/InspectionCard';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../constants/strings';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { getRoute } from '../../constants/strings';
import { useOnlineStatus } from '../../hooks/offline-hooks/offline-misc';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import CustomQuickAction from './CustomQuickAction';
import { announcementIcons } from '../../constants/strings';
import { modals } from '../../providers/modals';
import { useModal } from '../../providers/ModalProvider';
import DashboardCard from './desktop/DashboardCard';
import { useCurrentUser } from '../../providers/UserProvider';
import { assessmentTypes } from '../../constants/strings';
import moment from 'moment';
import party from '../../assets/empty_state_images/party_actions.svg';

export default function Dashboard({
  tasks = [],
  actionItems = [],
  inspections = [],
  loading = () => {},
  quickActions = [],
  customQuickActions = [],
  refetch = () => {},
  isEditing = false,
  announcements = [],
  setAnnouncementsExpanded,
  announcementExpanded,
}) {
  const { user } = useCurrentUser();
  const { workspace } = useWorkspace();
  const { openActionItemModal } = useActionItemModal();
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { t } = useTranslation();
  const online = useOnlineStatus();
  const now = moment();

  const emptyState = (
    <div className={styles.emptyStateContainer}>
      <img src={party} alt="party" className={styles.emptyStateImage} />
      <Text noMargin size="sm" color="secondary" weight="semiBold">
        {t(keys.dashboard.ACTION_ITEMS_EMPTY_STATE_TITLE)}
      </Text>
    </div>
  );

  return (
    <div className={styles.container}>
      <Text size="lg" weight="bold" noMargin color="accentPrimary">
        {t(keys.dashboard.DASHBOARD)}
      </Text>
      <br />
      <Text size="sm" weight="semiBold" noMargin color="secondaryLight">
        {t(keys.dashboard.QUICK_ACTIONS)}
      </Text>
      <div className={styles.quickActionsContainer}>
        {customQuickActions?.map((quickAction, index) => (
          <CustomQuickAction
            key={`${quickAction?.id}-${index}`}
            quickAction={quickAction}
            refetch={refetch}
            isEditing={isEditing}
          />
        ))}
        {quickActions.map(
          ({ title, color, icon, onClick, disabled, className }, idx) => (
            <QuickAction
              key={title + icon + idx}
              title={title}
              icon={icon}
              color={color}
              onClick={onClick}
              disabled={disabled}
              className={className}
            />
          ),
        )}
      </div>
      <div className={styles.categoryHeader}>
        <Text size="sm" weight="semiBold" noMargin color="secondaryLight">
          {t(keys.tasks.MY_TASKS)}
        </Text>
      </div>
      <div className={styles.actionItemsContainer}>
        {loading ? (
          <div className={styles.spinnerContainer}>
            <Spinner variant="primary" />
          </div>
        ) : tasks.length > 0 ? (
          tasks?.map((task, index) => (
            <DashboardCard
              key={`${task?.id}-${index}`}
              header={task?.title}
              subtext={`Due ${
                task.interval === 'WEEKLY'
                  ? now.clone().day(7).fromNow()
                  : task.interval === 'MONTHLY'
                  ? now.clone().add(1, 'month').startOf('month').fromNow()
                  : 'today'
              }`}
              icon={
                assessmentTypes[task?.template?.templateType.toLowerCase()]
                  ?.icon || 'local_shipping'
              }
              iconColor={
                assessmentTypes[task?.template?.templateType.toLowerCase()]
                  ?.color || 'yellow'
              }
              onClick={() => {
                openModal({
                  modalName: modals.createAssessment,
                  variables: {
                    participants: [user],
                    dataWorkspaceId: task.workspace.id,
                    type:
                      assessmentTypes[
                        task.template.templateType.toLowerCase()
                      ] || assessmentTypes.fleet,
                    template: task.template,
                  },
                });
              }}
            />
          ))
        ) : (
          emptyState
        )}
      </div>
      <div className={styles.categoryHeader}>
        <Text size="sm" weight="semiBold" noMargin color="secondaryLight">
          {t(keys.common.ACTION_ITEMS)}
        </Text>
        <Text
          className={styles.link}
          onClick={() => navigate(getRoute(workspace?.id, paths.actionItems))}
          size="sm"
          weight="semiBold"
          noMargin
          color="accentPrimary"
        >
          {t(keys.dashboard.SEE_ALL)}
        </Text>
      </div>
      <div className={styles.actionItemsContainer}>
        {loading ? (
          <div className={styles.spinnerContainer}>
            <Spinner variant="primary" />
          </div>
        ) : actionItems.length > 0 ? (
          actionItems?.map(({ id, title, priority, dueDate, creator }) => (
            <ActionItemSlimCard
              key={id}
              title={title}
              priority={priority}
              dueDate={dueDate}
              creator={creator}
              onClick={() => openActionItemModal(id, null)}
              disabled={!online}
            />
          ))
        ) : (
          emptyState
        )}
      </div>
      <Text size="sm" weight="semiBold" noMargin color="secondaryLight">
        {t(keys.dashboard.SAFETY_SHARE)}
      </Text>
      <SafetyShareCard />
      <div className={styles.categoryHeader}>
        <Text size="sm" weight="semiBold" noMargin color="secondaryLight">
          {t(keys.dashboard.RECENT_ASSESSMENTS)}
        </Text>
        <Text
          size="sm"
          weight="semiBold"
          noMargin
          color="accentPrimary"
          className={styles.link}
          onClick={() => navigate(getRoute(workspace?.id, paths.assessments))}
        >
          {t(keys.dashboard.SEE_ALL)}
        </Text>
      </div>
      <div className={styles.inspectionContainer}>
        {inspections.length > 0
          ? inspections?.map((inspection) => (
              <InspectionCard
                key={`inspection:${inspection.id}`}
                inspection={inspection}
                onClick={() =>
                  navigate(
                    getRoute(
                      inspection?.workspaceId,
                      paths.assessment,
                      inspection.id,
                    ),
                  )
                }
              />
            ))
          : emptyState}
      </div>
      <div className={styles.categoryHeader}>
        <Text size="sm" weight="semiBold" noMargin color="secondaryLight">
          {t(keys.dashboard.RECENT_ANNOUNCEMENTS)}
        </Text>
        <Text
          size="sm"
          weight="semiBold"
          noMargin
          color="accentPrimary"
          className={styles.link}
          onClick={() => setAnnouncementsExpanded(!announcementExpanded)}
        >
          {announcementExpanded
            ? t(keys.dashboard.SHOW_LESS)
            : t(keys.dashboard.SHOW_MORE)}
        </Text>
      </div>
      <div className={styles.inspectionContainer}>
        {announcements.length > 0
          ? announcements?.map(({ dateReleased, status, announcement, id }) => (
              <DashboardCard
                rightContent={
                  <img
                    className={styles.avatar}
                    src={announcement?.creator.avatarUrl}
                    alt={announcement?.creator.firstName}
                  />
                }
                header={announcement.title}
                subtext={dateReleased}
                key={`announcement:${announcement.id}`}
                icon={announcementIcons[status].icon}
                iconColor={announcementIcons[status].color}
                onClick={() => {
                  openModal({
                    modalName: modals.announcement,
                    variables: {
                      announcement,
                      status,
                      id,
                    },
                  });
                }}
              />
            ))
          : emptyState}
      </div>
    </div>
  );
}
