import { createContext, useContext } from 'react';
import { useQueryParams } from '../hooks/misc';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useWorkspace } from './WorkspaceProvider';

const templateQuery = loader('./TemplateProvider.graphql');

const TemplateProviderContext = createContext();
export const TemplateProvider = ({ children }) => {
  const { getParam } = useQueryParams();
  const type = getParam('type');
  const selectedWorkspace = getParam('workspace');
  const { workspaceId } = useWorkspace();

  const { data: { inspectionTemplates = [] } = {} } = useQuery(templateQuery, {
    skip: !workspaceId,
    variables: {
      workspaceId: `${selectedWorkspace || workspaceId}`,
    },
  });
  const templates =
    inspectionTemplates?.filter((t) => t.templateType === type) ?? [];
  return (
    <TemplateProviderContext.Provider value={{ templates }}>
      {children}
    </TemplateProviderContext.Provider>
  );
};
export const useTemplates = () => useContext(TemplateProviderContext);
