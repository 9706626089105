export const BUCKET_NAME =
  process.env.REACT_APP_RELEASE_STAGE.toLowerCase() === 'production'
    ? 'opas-production-assets'
    : 'opas-assets-dev';

export const S3_HEADER = (user) =>
  `https://${BUCKET_NAME}.s3.amazonaws.com/assets/${user.company.id}/`;

const CF_DOMAIN_NAME =
  process.env.REACT_APP_RELEASE_STAGE.toLowerCase() === 'production'
    ? 'app.opasmobile.com'
    : 'devassets.opasmobile.com';

export const CF_DOMAIN = (user) =>
  `https://${CF_DOMAIN_NAME}/assets/${user.company.id}/`;
