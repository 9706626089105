import { Suspense, createContext, useContext } from 'react';
import { loader } from 'graphql.macro';
import { NetworkStatus, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useOnlineStatus } from '../hooks/offline-hooks/offline-misc';
import { Navigate } from 'react-router-dom';
import CustomSpinner from '../components/CustomSpinner';

const userQuery = loader('./UserProvider.graphql');
const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const isOnline = useOnlineStatus();
  const { networkStatus, data: { user = {} } = {} } = useQuery(userQuery, {
    skip: !isAuthenticated,
    fetchPolicy: isOnline ? 'network-only' : 'cache-first',
  });
  const loading = networkStatus === NetworkStatus.loading;
  useEffect(() => {
    if (user?.id) {
      localStorage.setItem('user', JSON.stringify(user));
    }
  }, [user]);

  if (isLoading || loading) {
    return <CustomSpinner text={'Loading User...'} />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (!user.id && !isLoading && isAuthenticated && !loading) {
    return <></>;
  }
  const isAdmin = user.role === 'ADMIN';
  const team = user?.team || [];
  const isSupervisor = !!team.length;

  return (
    <Suspense fallback={<CustomSpinner text={'Loading User...'} />}>
      <UserContext.Provider value={{ user, isAdmin, team, isSupervisor }}>
        {children}
      </UserContext.Provider>
    </Suspense>
  );
};

export const useCurrentUser = () => useContext(UserContext);
