import styles from './IncidentTemplateList.module.scss';
import { Text } from '../../components/typography';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Label from '../../components/action_items/Label';
import React, { useState, useMemo } from 'react';
import { getLocalTime } from '../../utilities/time';
import { Spinner } from 'react-bootstrap';
import EmptyStateView from '../../components/empty_state_view/EmptyStateView';
import templatesImage from '../../assets/empty_state_images/templates.svg';
import { paths, sortTemplateStrings } from '../../constants/strings.js';
import TableSorting from '../../components/sorting/TableSorting';
import { convertIncidentTemplatesToQueryParams } from '../../utilities/filtering';
import TableFiltering from '../../components/sorting/TableFiltering';
import { templateStatusTypes } from '../../utilities/filtering';
import TablePagination from '../../components/pagination/TablePagination';
import { Icon } from '@mui/material';
import { useWorkspace } from '../../providers/WorkspaceProvider.jsx';
import { getRoute } from '../../constants/strings.js';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';

const templatesQuery = loader('./IncidentTemplateList.graphql');

export default function IncidentTemplates() {
  const { workspace } = useWorkspace();
  const [sortBy, setSortBy] = useState('dateCreated');
  const [isAscending, setIsAscending] = useState(false);
  const sortingOptions = ['userId', 'dateCreated', 'isDraft'];
  const [page, setPage] = useState(1);
  const pageSize = 11;
  const [rawFilters, setRawFilters] = useState({});
  const { t } = useTranslation();

  const filters = useMemo(() => {
    return convertIncidentTemplatesToQueryParams(rawFilters);
  }, [rawFilters]);

  const navigate = useNavigate();
  const {
    refetch,
    loading,
    data: { inspectionTemplates, inspectionTemplatesCount = 0 } = {},
  } = useQuery(templatesQuery, {
    skip: !workspace?.id,
    variables: {
      options: {
        page,
        pageSize,
        sort: [
          {
            field: sortBy,
            order: isAscending ? 'asc' : 'desc',
          },
        ],
        filters: [
          ...filters,
          {
            field: 'workspaceId',
            operator: 'or',
            value: [`${workspace?.id}`, null],
          },
        ],
      },
    },
  });

  return (
    <div className={styles.container}>
      <div className={styles.sort}>
        <div>
          <TableFiltering
            filters={filters}
            setFilters={setRawFilters}
            rawFilters={rawFilters}
            filterOptions={['Creator', 'Status']}
            statusOptions={templateStatusTypes}
            setPage={setPage}
          />
        </div>
        <TableSorting
          setSortBy={setSortBy}
          sortBy={sortBy}
          setIsAscending={setIsAscending}
          refetch={refetch}
          isAscending={isAscending}
          sortingOptions={sortingOptions}
          sortStrings={sortTemplateStrings}
        />
      </div>
      <br />
      <div className={styles.tableContainer}>
        {inspectionTemplates?.length ? (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>
                  <Text size="sm" weight="semiBold" noMargin noSelect>
                    {t(keys.common.TITLE)}
                  </Text>
                </th>
                <th>
                  <Text size="sm" weight="semiBold" noMargin noSelect>
                    {t(keys.common.CREATOR)}
                  </Text>
                </th>
                <th>
                  <Text size="sm" weight="semiBold" noMargin noSelect>
                    {t(keys.common.DATE)}
                  </Text>
                </th>
                <th>
                  <Text size="sm" weight="semiBold" noMargin noSelect>
                    {t(keys.common.STATUS)}
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {inspectionTemplates.map((inspectionTemplate) => (
                <tr
                  key={inspectionTemplate.id}
                  className={styles.row}
                  onClick={() =>
                    navigate(
                      getRoute(
                        workspace?.id,
                        paths.templates,
                        inspectionTemplate?.id,
                      ),
                    )
                  }
                >
                  <td>
                    <Text size="sm" noMargin noSelect>
                      {inspectionTemplate?.title || 'None'}
                    </Text>
                  </td>
                  <td>
                    <Text size="sm" noMargin noSelect>
                      {`${inspectionTemplate?.creator?.firstName} ${inspectionTemplate?.creator?.lastName}`}
                    </Text>
                  </td>
                  <td>
                    <Text size="sm" noMargin noSelect>
                      {getLocalTime(inspectionTemplate?.dateCreated).format(
                        'MM/DD/YY',
                      )}
                    </Text>
                  </td>
                  <td>
                    <div className={styles.hideOnMobile}>
                      {inspectionTemplate?.isDraft ? (
                        <Label
                          className={styles.label}
                          name={t(keys.common.DRAFT)}
                          color="yellow"
                        />
                      ) : (
                        <Label
                          className={styles.label}
                          name={t(keys.common.PUBLISHED)}
                          color="green"
                        />
                      )}
                    </div>
                    <div className={styles.hideOnDesktop}>
                      {inspectionTemplate?.isDraft ? (
                        <Icon sx={{ color: '#ffc107', fontSize: '1.2rem' }}>
                          edit_note
                        </Icon>
                      ) : (
                        <Icon
                          sx={{
                            color: 'green',
                            opacity: 0.7,
                            fontSize: '1.2rem',
                          }}
                        >
                          check_circle
                        </Icon>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <>
            {loading ? (
              <div className={styles.spinnerContainer}>
                <Spinner
                  animation="border"
                  variant="primary"
                  className={styles.spinner}
                />
              </div>
            ) : (
              <div className={styles.emptyStateContainer}>
                <EmptyStateView
                  title={
                    filters.length > 1
                      ? t(keys.action.NOT_FOUND, {
                          variable: t(keys.common.TEMPLATES),
                        })
                      : t(keys.templates.EMPTY_STATE_TITLE)
                  }
                  text={
                    filters.length > 1
                      ? t(keys.action.EMPTY_STATE_CHECK_FILTERS, {
                          variable: t(keys.templates.EMPTY_STATE_ADD_TEMPLATE),
                        })
                      : t(keys.templates.EMPTY_STATE_MESSAGE)
                  }
                  image={templatesImage}
                />
              </div>
            )}
          </>
        )}
      </div>
      <TablePagination
        pageSize={pageSize}
        count={inspectionTemplatesCount}
        setPage={setPage}
        page={page}
      />
    </div>
  );
}
