import styles from './Incidents.module.scss';
import { Text } from '../../components/typography';
import { Button } from '../../components';
import TabSystem from '../../components/TabSystem';
import IncidentTemplates from './IncidentTemplateList';
import IncidentList from './IncidentList';
import { searchParamKeys } from '../../constants/strings';
import { useQueryParams } from '../../hooks/misc';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { useModal } from '../../providers/ModalProvider';
import { modals } from '../../providers/modals';
import { assessmentTypes } from '../../constants/strings';
import { useCurrentUser } from '../../providers/UserProvider';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';

const defaultTab = 'incidents';

export default function Incidents({ disabled = false }) {
  const { getParam } = useQueryParams();
  const tab = getParam(searchParamKeys.tab, defaultTab);
  const { isWorkspaceAdmin } = useWorkspace();
  const { openModal } = useModal();
  const { user, isAdmin } = useCurrentUser();
  const { t } = useTranslation();
  const options = [
    {
      title: t(keys.incidents.INCIDENTS),
      element: IncidentList,
      key: 'incidents',
    },
    ...(isAdmin
      ? [
          {
            title: t(keys.common.TEMPLATES),
            element: IncidentTemplates,
            key: 'templates',
          },
        ]
      : []),
  ];

  return (
    <div className={disabled ? styles.disabled : null}>
      <div className={styles.container}>
        <Text size="lg" weight="bold" color="accentPrimary">
          {t(keys.incidents.INCIDENTS)}
        </Text>
        {(isWorkspaceAdmin || tab === 'incidents') && (
          <div className={styles.newButton}>
            <Button
              variant="primary"
              icon="add"
              size="md"
              value={t(keys.common.NEW)}
              onClick={() => {
                switch (tab) {
                  case 'incidents':
                    openModal({
                      modalName: modals.createIncident,
                      variables: {
                        participants: [user],
                      },
                    });
                    break;
                  case 'templates':
                    openModal({
                      modalName: modals.createTemplate,
                      variables: {
                        types: [assessmentTypes.incident],
                        type: assessmentTypes.incident,
                        modalTitle: t(keys.incidents.CREATE_INCIDENT_TEMPLATE),
                      },
                    });
                    break;
                  default:
                    throw new Error(`Invalid type ${tab}`);
                }
              }}
            />
          </div>
        )}
      </div>
      <TabSystem type={tab} options={options} />
    </div>
  );
}
