export const observationTypes = {
  POSITIVE: {
    icon: 'mood',
    key: 'FALSE',
    title: 'Positive',
    color: 'green',
  },
  NEGATIVE: {
    icon: 'flag',
    key: 'TRUE',
    title: 'Action',
    color: 'red',
  },
};
export const observationStatus = {
  NEW: { key: 'NEW', title: 'New', color: 'blue' },
  IN_PROGRESS: {
    key: 'IN_PROGRESS',
    title: 'In Progress',
    color: 'yellow',
  },
  INVALID: { key: 'INVALID', title: 'Invalid', color: 'red' },
  COMPLETE: { key: 'COMPLETE', title: 'Complete', color: 'green' },
};
