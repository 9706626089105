import React from 'react';
import TabSystem from '../../components/TabSystem';
import { Text } from '../../components/typography';
import styles from './Observations.module.scss';
import { useQueryParams } from '../../hooks/misc';
import { searchParamKeys } from '../../constants/strings';
import ObservationList from './ObservationList.jsx';
import Button from '../../components/Button.jsx';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys.js';
import { useModal } from '../../providers/ModalProvider.jsx';
import { modals } from '../../providers/modals.js';
import { assessmentTypes } from '../../constants/strings';
import { useWorkspace } from '../../providers/WorkspaceProvider.jsx';
import ObservationTemplates from './ObservationTemplates.jsx';
import { useCurrentUser } from '../../providers/UserProvider.jsx';
import moment from 'moment';

const defaultTab = 'observations';

export default function Observations({ disabled = false }) {
  const { getParam } = useQueryParams();
  const tab = getParam(searchParamKeys.tab, defaultTab);
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { workspaceId, observationTemplate } = useWorkspace();
  const { isAdmin } = useCurrentUser();

  const options = [
    {
      title: t(keys.observations.OBSERVATIONS),
      element: ObservationList,
      key: 'observations',
    },
    ...(isAdmin
      ? [
          {
            title: t(keys.common.TEMPLATES),
            element: ObservationTemplates,
            key: `templates`,
          },
        ]
      : []),
  ];

  return (
    <div className={disabled ? styles.disabled : null}>
      <div className={styles.headerContainer}>
        <Text size="lg" weight="bold" color="accentPrimary">
          {t(keys.observations.OBSERVATIONS)}
        </Text>
        <div className={styles.newButton}>
          <Button
            value={t(keys.common.NEW)}
            variant="primary"
            icon="add"
            size="md"
            onClick={() => {
              switch (tab) {
                case 'observations':
                  openModal({
                    modalName: modals.observation,
                    variables: {
                      participants: [],
                      workspaceId,
                      templateId: observationTemplate?.id,
                      questions:
                        observationTemplate?.categories[0]?.questions || [],
                      dateTime: new moment().format('YYYY-MM-DD HH:mm'),
                    },
                  });
                  break;
                case 'templates': {
                  openModal({
                    modalName: modals.createTemplate,
                    variables: {
                      types: [assessmentTypes.observation],
                      type: assessmentTypes.observation,
                      allWorkspaces: true,
                      options: {
                        allWorkspaces: false,
                        secondSight: false,
                        approvalRequired: false,
                      },
                    },
                  });
                  break;
                }
                default:
                  throw new Error(`Invalid type ${tab}`);
              }
            }}
          />
        </div>
      </div>
      <TabSystem type={tab} options={options} />
    </div>
  );
}
