import { timecardStatus } from '../../constants/misc';
import moment from 'moment';
import { getLocalTime } from '../../utilities/time';
import { keys } from '../../utilities/translator/translation_keys';

export const timecardStatusTypes = {
  DRAFT: { title: keys.timecards.DRAFT, variant: 'blue' },
  SUBMITTED: { title: keys.timecards.SUBMITTED, variant: 'yellow' },
  APPROVED: { title: keys.timecards.APPROVED, variant: 'green' },
  DENIED: { title: keys.timecards.DENIED, variant: 'red' },
  COMPLETE: { title: keys.timecards.COMPLETE, variant: 'purple' },
};

export const timecardTable = [
  {
    title: keys.common.USER,
    query: 'creator',
    type: 'user',
    className: 'medium',
  },
  {
    title: keys.timecards.START_TIME,
    query: 'startTime',
    type: 'date',
    className: 'medium',
  },
  {
    title: keys.timecards.END_TIME,
    query: 'endTime',
    type: 'date',
    className: 'medium',
    hide: 'hideOnMobile',
  },
  {
    title: keys.timecards.HOURS,
    query: 'billableHours',
    className: 'small',
  },
  {
    title: keys.timecards.PO_NUMBER,
    query: 'poNumber',
    className: 'medium',
    hide: 'hideOnMobile',
  },
  {
    title: keys.timecards.ACTIVITY,
    className: 'large',
    hide: 'hideOnMobile',
  },
  {
    title: keys.common.STATUS,
    query: 'status',
    type: 'label',
    className: 'small',
  },
];

export const requiredKeys = ['startTime', 'endTime', 'activity'];

export const API_DELAY = 1000;

export const reviewerButtons = [
  {
    key: 'APPROVE',
    value: keys.action.APPROVE,
    variant: 'success',
    title: keys.action.APPROVE_VARIABLE,
    description: keys.timecards.APPROVE_MESSAGE,
    status: timecardStatus.approved,
    textInput: false,
    navigate: 'team',
  },
  {
    key: 'DENY',
    value: keys.action.DENY,
    variant: 'danger',
    title: keys.action.DENY_VARIABLE,
    description: keys.timecards.DENY_MESSAGE,
    status: timecardStatus.denied,
    textInput: true,
    textInputTitle: keys.timecards.DENY_INPUT,
    navigate: 'team',
  },
];
export const submitButton = {
  key: 'SUBMIT',
  value: keys.action.SUBMIT,
  title: keys.timecards.SUBMIT_TITLE,
  description: keys.timecards.SUBMIT_MESSAGE,
  variant: 'warning',
  navigate: 'personal',
  icon: 'check_circle',
};
export const deleteButton = {
  key: 'DELETE',
  value: keys.action.DELETE,
  icon: 'delete',
  variant: 'danger',
  title: keys.action.DELETE_VARIABLE,
  description: keys.action.DELETE_CONFIRMATION,
  navigate: 'personal',
};

export const CSVHeaders = {
  __typename: ' ',
  dateCreated: 'Date Created',
  startTime: 'Start Time',
  endTime: 'End Time',
  description: 'Description',
  workspace: 'Workspace',
  dateModified: 'Date Last Modified',
  reviewer: 'Reviewer',
  creator: 'Creator',
  billableHours: 'Billable Hours',
  id: 'ID',
  status: 'Status',
  vehicle: 'Asset',
  poNumber: 'PO#',
  notes: 'Notes',
  dateSubmitted: 'Date Submitted',
  dateReviewed: 'Date Reviewed',
  resourceId: 'Resource ID',
  payrollId: 'Payroll ID',
  datePaid: 'Date Completed',
  activity: 'Activity',
};

export const formatCSVData = (timecard) => ({
  creator: `${timecard?.creator?.firstName} ${timecard?.creator?.lastName}`,
  startTime: getLocalTime(timecard.startTime).format('YYYY-MM-DD HH:mm'),
  endTime: getLocalTime(timecard.endTime).format('YYYY-MM-DD HH:mm'),
  description: timecard.description,
  status: timecard.status,
  billableHours: timecard.billableHours,
  poNumber: timecard.poNumber,
  activity:
    timecard.activity?.description || timecard.activity?.externalActivityId,
  vehicle:
    timecard?.vehicle &&
    `${timecard?.vehicle?.year || ''} ${timecard?.vehicle?.make} ${
      timecard?.vehicle?.model
    } ${timecard?.vehicle?.unitNumber || ''}`,
  notes: timecard.notes,
  reviewer:
    timecard?.reviewer &&
    `${timecard?.reviewer?.firstName} ${timecard?.reviewer?.lastName}`,

  dateReviewed:
    timecard?.dateReviewed &&
    getLocalTime(timecard.dateReviewed).format('YYYY-MM-DD HH:mm'),
  dateCreated: getLocalTime(timecard.dateCreated).format('YYYY-MM-DD hh:mm'),
  dateModified: getLocalTime(timecard.dateModified).format('YYYY-MM-DD hh:mm'),
  dateSubmitted: getLocalTime(timecard.dateSubmitted).format(
    'YYYY-MM-DD HH:mm',
  ),
  datePaid:
    timecard.datePaid &&
    getLocalTime(timecard.datePaid).format('YYYY-MM-DD HH:mm'),
  resourceId: `${timecard.creator?.externalActivityId || ''}`,
  payrollId: `${timecard.creator?.payrollId || ''}`,
  workspace: timecard?.workspace?.title,
});

export const sheetName = (filters) => {
  const endDate = moment();
  const startDate = moment(endDate).subtract(30, 'days');
  let fileName = 'timecards';
  let index = 0;

  const hasDateRange = filters.some(({ field }, i) => {
    index = i;
    return field === 'startTime';
  });

  if (hasDateRange) {
    fileName = `${filters[index].value[0].split(' ')[0]}_${
      filters[index].value[1].split(' ')[0]
    }-timecards`;
  } else {
    fileName = `${startDate.format('YYYY-MM-DD')}-${endDate.format(
      'YYYY-MM-DD',
    )}-timecards`;
  }

  return fileName;
};

export const formatDataPayroll = (timecards) => {
  const output = {};
  timecards.forEach((timecard) => {
    const key = `${timecard.creator.firstName}-${timecard.creator.lastName}-${timecard.status}`;
    if (!output[key]) {
      output[key] = {
        Name: `${timecard.creator.firstName} ${timecard.creator.lastName}`,
        '[Pay Codes] Regular Hours': 0,
        'Pay Run Status': 'Initial',
        '[Pay Codes] Holiday Hours': 0,
        '[Pay Codes] Time & A Half Hours': 0,
        Status: timecard.status,
        'Payroll ID': timecard.creator.payrollId,
        'Resource ID': timecard.creator.externalActivityId,
      };
    }
    output[key]['[Pay Codes] Regular Hours'] += timecard.billableHours;
  });

  const outputArray = Object.values(output);

  return outputArray;
};
export const paidButton = {
  key: 'PAID',
  value: keys.timecards.COMPLETE,
  variant: 'purple',
  title: keys.timecards.COMPLETE_TITLE,
  description: keys.timecards.COMPLETE_MESSAGE,
  navigate: 'team',
  icon: 'paid',
};

export const organizeTimecardsByActivity = (startDate, timecards) => {
  const headers = [];
  const activities = {};
  const tableFormat = {};

  if (startDate) {
    for (let i = 0; i < 7; i++) {
      const date = moment(startDate)
        .add(i + 1, 'days')
        .format('YYYY-MM-DD');
      headers.push(date);
    }
    headers.push('Total');
  }

  timecards?.forEach((timecard) => {
    const activityId = timecard?.activity?.id;
    if (!activities[activityId] || !activityId) {
      activities[activityId] = [];
    }
    activities[activityId].push({
      description: timecard.poNumber
        ? `${timecard.activity.description} (${timecard.poNumber})`
        : `${timecard.activity.description} `,
      date: moment(parseInt(timecard.startTime)).format('YYYY-MM-DD'),
      hours: timecard.billableHours,
    });
  });

  Object.entries(activities)?.forEach(([activityId, activityData]) => {
    if (!tableFormat[activityId]) {
      tableFormat[activityId] = { Activity: activityData[0].description };
    }

    headers.forEach((header) => {
      const totalHours = activityData.reduce((acc, entry) => {
        if (entry.date.includes(header)) {
          return acc + entry.hours;
        }
        return acc;
      }, 0);
      if (!tableFormat[activityId][header]) {
        tableFormat[activityId][header] = 0;
      }
      tableFormat[activityId][header] += totalHours;
    });
    const exactTotal = headers.reduce((acc, header) => {
      return acc + (tableFormat[activityId][header] || 0);
    }, 0);

    tableFormat[activityId]['Total'] = Math.round(exactTotal * 100) / 100.0;
  });

  const finalTotals = [
    ...headers.map((header) =>
      Object.values(tableFormat).reduce((acc, activity) => {
        return acc + (activity[header] || 0);
      }, 0),
    ),
  ];
  const forPDF = Object.values(tableFormat).map((obj) => Object.values(obj));

  return { forPDF, headers, finalTotals };
};
