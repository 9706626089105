import styles from './AnnouncementModal.module.scss';
import Modal from '../Modal';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import RichTextEditor from '../../rich_text/RichTextEditor';
import { showToast } from '../../../graphql/cache/modal';
import SimpleUserCard from '../../SimpleUserCard';
import { Text } from '../../typography';
import { useTranslation } from 'react-i18next';
import { keys } from '../../../utilities/translator/translation_keys';
import { useModal } from '../../../providers/ModalProvider';
import { modals } from '../../../providers/modals';

const snoozeAnnouncementMutation = loader('./AnnouncementModal.snooze.graphql');
const acceptAnnouncementMutation = loader('./AnnouncementModal.accept.graphql');

export default function AnnouncementModal() {
  const { t } = useTranslation();
  const { modalState, closeModal } = useModal();
  const { show, announcement, id, status } = modalState.announcementModal;
  const [snoozeAnnouncement] = useMutation(snoozeAnnouncementMutation);
  const [acceptAnnouncement] = useMutation(acceptAnnouncementMutation);
  const { creator, dateOfRelease } = announcement || {};
  const onHide = () => closeModal({ modalName: modals.announcement });

  return (
    <Modal
      cancelText={t(keys.announcements.SNOOZE)}
      submitText={t(keys.announcements.ACCEPT)}
      variant="success"
      title={announcement?.title}
      hideCancel={status !== 'UNREAD'}
      hideSubmit={status === 'READ'}
      disableCloseOnSubmit={true}
      onSubmit={(e) => {
        acceptAnnouncement({
          variables: {
            id,
            dateReleased: new moment(),
          },
        });
        onHide();
        e.stopPropogation();
      }}
      onClose={onHide}
      onCancel={() => {
        if (status === 'READ' || status === 'SNOOZED') {
          onHide();
        } else {
          snoozeAnnouncement({
            variables: {
              id,
              dateReleased: moment().add(1, 'days').format('yyyy-MM-DD'),
            },
          }).then(() => {
            showToast({
              title: t(keys.announcements.ANNOUNCEMENT_SNOOZED),
              message: t(keys.announcements.SNOOZED_MESSAGE),
            });
          });
        }
        onHide();
      }}
      open={show}
    >
      <div className={styles.content}>
        <RichTextEditor
          readOnly
          onChange={() => {}}
          id={announcement?.id}
          value={announcement?.content}
        />
        <div className={styles.modalText}>
          <SimpleUserCard user={creator} />
          <Text noMargin weight="semiBold" color="secondary">
            {dateOfRelease}
          </Text>
        </div>
      </div>
    </Modal>
  );
}
