import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import WorkflowStageFooter from '../../components/workflows/WorkflowStageFooter';
import WorkflowStageHeader from '../../components/workflows/WorkflowStageHeader';
import styles from './CreateVehicleImage.module.scss';
import noImage from '../../assets/icons/image_blank.png';
import { useApolloClient, useQuery, useMutation } from '@apollo/client';
import ImageCropModal from '../../components/image_uploads/ImageCropModal';
import { CF_DOMAIN } from '../../constants/aws';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import icons from '../../assets/icons';
import { useCurrentUser } from '../../providers/UserProvider';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';

const vehicleQuery = loader('./CreateVehicleImage.fetch.graphql');
const uploadUrlQuery = loader('../../graphql/queries/s3.graphql');
const vehicleMutation = loader('./CreateVehicleImage.update.graphql');

export default function CreateVehicleImage({
  vehicleId,
  workflow,
  completed,
  onCompleted,
  currentStage,
}) {
  const { t } = useTranslation();
  const client = useApolloClient();
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [updateVehicleImage] = useMutation(vehicleMutation);
  const { user } = useCurrentUser();
  const { data: { vehicles: [vehicle] = [{}] } = {} } = useQuery(vehicleQuery, {
    skip: !vehicleId,
    returnPartialData: true,
    variables: {
      vehicleId: `${vehicleId}`,
    },
  });

  return (
    <>
      <WorkflowStageHeader
        title={t(keys.assets.P2_HEADER)}
        subtitle={t(keys.assets.P2_SUBTEXT)}
        workflow={workflow}
        completed={completed}
      />
      {vehicle?.id ? (
        <div className={styles.imageContainer}>
          {vehicle.imageUrl ? (
            <img
              className={styles.avatar}
              src={vehicle.imageUrl}
              alt="Vehicle"
            />
          ) : (
            <img src={noImage} alt="none" className={styles.avatar} />
          )}
          <div
            className={styles.avatarButtonContainer}
            data-cy="image-edit-button"
            onClick={() => setImageModalOpen(true)}
          >
            <img
              className={styles.editButton}
              src={icons.white.edit}
              alt="edit icon"
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      <WorkflowStageFooter
        onNext={() => {
          onCompleted(currentStage);
        }}
      />
      {imageModalOpen && (
        <ImageCropModal
          title="Asset"
          onClose={() => setImageModalOpen(false)}
          onSave={(file) => {
            setImageModalOpen(false);

            const fileUUID = uuidv4();
            const fileParts = file.name.split('.');
            const fileName = fileUUID;
            const fileType = fileParts[1];
            client
              .query({
                query: uploadUrlQuery,
                variables: {
                  fileName: `assets/${user.company.id}/${fileName}.${fileType}`,
                  fileType,
                },
              })
              .then((data) => {
                const signedUrl = data.data.simpleStorageUploadUrl;
                const options = {
                  headers: {
                    'Content-Type': fileType,
                  },
                };
                axios.put(signedUrl, file, options).then((result) => {
                  if (result.status === 200) {
                    updateVehicleImage({
                      variables: {
                        vehicleId,
                        imageUrl: `${CF_DOMAIN(user)}${fileName}.${fileType}`,
                      },
                    });
                  }
                });
              });
          }}
        />
      )}
    </>
  );
}
