import moment from 'moment';
import 'moment/locale/fr-ca';
import i18n from '../i18n';

export function getLocalTime(timestamp) {
  if (typeof timestamp === 'string' && timestamp.includes('-')) {
    const m = new moment(timestamp);
    return m;
  }
  const epoch = parseInt(`${timestamp}`);
  const m = new moment(epoch);
  const currentLanguage = i18n.resolvedLanguage;
  moment.locale(currentLanguage || 'en');
  return m;
}

export async function sleep(milliseconds = 2500) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, milliseconds);
  });
}

export function formatTime(date, customOptions = {}) {
  let options = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  };
  let newDate = new Date(date);
  return newDate.toLocaleDateString(
    i18n.resolvedLanguage,
    customOptions || options,
  );
}
