import { useQuery } from '@apollo/client';
import { Icon } from '@mui/material';
import { loader } from 'graphql.macro';
import React, { useMemo } from 'react';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { Text } from './typography';
import styles from './LabelSelector.module.scss';
import Label from './action_items/Label';
import { openLabelCreationModal } from '../graphql/cache/modal';
import { closeModalComponents } from '../utilities/modal';
import { useTranslation } from 'react-i18next';
import { keys } from '../utilities/translator/translation_keys';

const labelQuery = loader('./LabelSelector.graphql');

export default function LabelSelector({
  selected,
  onSelectionChanged,
  className,
  onClose,
  setOnClose,
  testId,
  readOnly = false,
}) {
  const [search, setSearch] = useState('');
  const { refetch, loading, data: { labels = [] } = {} } = useQuery(labelQuery);
  const [modalOpen, setModalOpen] = useState(false);
  const ref = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    closeModalComponents(ref, setModalOpen);
  }, [ref]);

  useEffect(() => {
    setModalOpen(false);
    setOnClose(false);
  }, [onClose, setOnClose]);

  useEffect(() => setSearch(''), [modalOpen]);

  const filteredLabels = useMemo(() => {
    return (
      search?.length
        ? labels.filter((u) =>
            // TODO WITH LABELS!!!  Fix duplicates, fix any remaining UX, add label creation modal
            u.name.toLowerCase().includes(search.toLowerCase()),
          )
        : labels
    ).filter((l) => !selected?.some((m) => m.id === l.id));
  }, [labels, search, selected]);

  return (
    <div className={className}>
      {!!selected?.length ? (
        <div
          className={styles.labelContainer}
          onClick={() => {
            if (!readOnly) {
              setModalOpen(true);
            }
          }}
        >
          {selected.map((label) => (
            <Label
              className={styles.label}
              key={label.id}
              name={label.name}
              color={label.color}
              rowSized={false}
              canEdit={!readOnly}
              onClose={() => {
                onSelectionChanged('REMOVE', label);
              }}
            />
          ))}
          {!readOnly && (
            <Icon
              data-cy="label-selector-append-label"
              className={styles.addIcon}
            >
              add
            </Icon>
          )}
        </div>
      ) : readOnly ? (
        <Text weight="semiBold" noMargin>
          {t(keys.common.NONE)}
        </Text>
      ) : (
        <div
          data-cy={`${testId}-label-selector`}
          className={styles.buttonContainer}
          onClick={() => setModalOpen(true)}
        >
          <Icon fontSize="inherit" baseClassName="material-icons-outlined">
            sell
          </Icon>
          <Text size="md" noSelect weight="semiBold" color="secondary" noMargin>
            {t(keys.action.ADD, { variable: t(keys.common.LABELS) })}
          </Text>
        </div>
      )}
      {modalOpen && (
        <div ref={ref} className={styles.modalContainer}>
          <div className={styles.modalHeader}>
            <Icon
              className={styles.closeIcon}
              fontSize="inherit"
              baseClassName="material-icons-outlined"
              data-cy="label-selector-create-label"
              onClick={() =>
                openLabelCreationModal(labels, () => {
                  refetch();
                })
              }
            >
              add
            </Icon>
            <Text
              size="md"
              weight="semiBold"
              color="secondary"
              noSelect
              noMargin
            >
              {t(keys.common.LABELS)}
            </Text>
            <Icon
              className={styles.closeIcon}
              fontSize="inherit"
              data-cy="label-selector-close"
              baseClassName="material-icons-outlined"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              close
            </Icon>
          </div>
          <hr />
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Control
              className={styles.input}
              type="text"
              data-cy="input-label-selector-search"
              placeholder={t(keys.action.SEARCH, {
                variable: t(keys.common.LABELS),
              })}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Form>
          {loading ? (
            <div className={styles.spinnerContainer}>
              <Spinner
                className={styles.spinner}
                animation="border"
                variant="primary"
              />
            </div>
          ) : (
            <div className={styles.labelListContainer}>
              {filteredLabels?.map((label) => (
                <Label
                  className={styles.label}
                  key={label.id}
                  name={label.name}
                  color={label.color}
                  rowSized={false}
                  onClick={() => {
                    onSelectionChanged('ADD', label);
                  }}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
