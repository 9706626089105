import { paths } from '../constants/strings';
import { keys } from './translator/translation_keys';
import { t } from 'i18next';

export const actionMap = {
  ACTION_ITEM_ASSIGNED: ({ actionItem, creator }) => ({
    icon: 'task_alt',
    iconColor: 'primary',
    header: t(keys.common.ACTION_ITEMS),
    avatar: creator.avatarUrl,
    title: t(keys.dashboard.ACTION_ITEM_NOTIFIER),
    description: t(keys.dashboard.ACTION_ITEM_NOTIFIER_TEXT, {
      user: `${creator.firstName} ${creator.lastName}`,
      title: actionItem.title,
    }),
    url: `?actionitem=${actionItem.id}`,
  }),
  ACTION_ITEM_REMINDER: ({ actionItem, creator }) => ({
    icon: 'task_alt',
    iconColor: 'yellow',
    header: t(keys.common.ACTION_ITEM),
    avatar: creator.avatarUrl,
    title: t(keys.dashboard.ACTION_ITEM_DUE_NOTIFIER),
    description: t(keys.dashboard.ACTION_ITEM_DUE_TEXT, {
      variable:
        t(keys.dashboard[actionItem.lastNotification]) || t(keys.dashboard.DUE),
    }),
    url: `?actionitem=${actionItem.id}`,
  }),
  ACTION_ITEM_COMMENT: ({ actionItemComment, creator }) => ({
    icon: 'comment',
    iconColor: 'primary',
    header: t(keys.common.ACTION_ITEM),
    avatar: creator.avatarUrl,
    title: t(keys.dashboard.ACTION_ITEM_COMMENT_TITLE, {
      user: creator.firstName,
      actionItemTitle: actionItemComment.actionItem.title,
    }),
    description: actionItemComment.text,
    details: actionItemComment.text,
    url: `?actionitem=${actionItemComment.actionItem.id}`,
  }),
  ACTION_ITEM_COMPLETE: ({ actionItem, creator }) => ({
    icon: 'task_alt',
    iconColor: 'green',
    header: t(keys.common.ACTION_ITEM),
    avatar: creator.avatarUrl,
    title: t(keys.dashboard.ACTION_ITEM_NOTIFIER_COMPLETE_TITLE),
    description: t(keys.dashboard.ACTION_ITEM_NOTIFIER_COMPLETE_TEXT, {
      user: `${creator.firstName} ${creator.lastName}`,
      title: actionItem.title,
    }),
    url: `?actionitem=${actionItem.id}`,
  }),
  ACTION_ITEM_DUE_DATE: ({ actionItem, creator }) => ({
    icon: 'edit_calendar',
    iconColor: 'yellow',
    header: t(keys.common.ACTION_ITEM),
    avatar: creator.avatarUrl,
    title: t(keys.dashboard.ACTION_ITEM_NOTIFIER_DUE_DATE_TITLE),
    description: t(keys.dashboard.ACTION_ITEM_NOTIFIER_DUE_DATE_TEXT, {
      user: `${creator.firstName} ${creator.lastName}`,
      title: actionItem.title,
    }),
    url: `?actionitem=${actionItem.id}`,
  }),
  INSPECTION_COMPLETED: ({ inspection, creator, workspaceId }) => ({
    icon: inspection.passed ? 'done' : 'close',
    iconColor: inspection.passed ? 'green' : 'red',
    header: t(keys.common.ASSESSMENTS),
    avatar: creator.avatarUrl,
    title: `${inspection.template.title} ${
      inspection.passed ? t(keys.common.PASSED) : t(keys.common.FAILED)
    }`,
    description: t(keys.dashboard.ASSESSMENT_NOTIFIER, {
      user: `${creator.firstName} ${creator.lastName} ${
        inspection.participants.length > 1
          ? t(keys.dashboard.ASSESSMENT_PARTICIPANTS_NOTIFIER)
          : ''
      }`,
    }),
    url: `/workspace/${workspaceId}/${paths.assessment}/${inspection.id}`,
  }),
  INCIDENT_REPORTED: ({ creator, workspaceId, incident }) => ({
    icon: 'warning',
    iconColor: 'yellow',
    header: t(keys.common.INCIDENT),
    avatar: creator.avatarUrl,
    title: t(keys.dashboard.INCIDENT_NOTIFIER),
    description: t(keys.dashboard.INCIDENT_NOTIFIER_TEXT, {
      user: `${creator.firstName} ${creator.lastName}`,
    }),
    url: `/workspace/${workspaceId}/${paths.incident}/${incident?.id}`,
  }),
  EXPENSE_SUBMITTED: ({ creator, expense }) => ({
    icon: 'attach_money',
    iconColor: 'green',
    header: 'Expense',
    avatar: creator.avatarUrl,
    title: 'Expense Submitted',
    description: `${creator.firstName} ${creator.lastName} submitted an Expense`,
    url: `/${paths.expense}/?id=${expense?.id}`,
  }),
  EXPENSE_DENIED: ({ creator, expense }) => ({
    icon: 'attach_money',
    iconColor: 'red',
    header: t(keys.common.EXPENSE),
    avatar: creator.avatarUrl,
    title: t(keys.expenses.DENIED_TITLE),
    description: t(keys.expenses.DENIED_TEXT),
    url: `/${paths.expense}/?id=${expense?.id}`,
  }),
  TIMECARD_DENIED: ({ creator, timecard }) => ({
    icon: 'schedule',
    iconColor: 'red',
    header: 'Timecard',
    avatar: creator.avatarUrl,
    title: 'Timecard Denied',
    description: `Your Timecard was denied.`,
    url: `/${paths.timecard}/?id=${timecard?.id}`,
  }),
  FILE_EXPIRY_REMINDER: ({ creator, file, workspaceId }) => ({
    icon: 'text_snippet',
    iconColor: 'yellow',
    header: t(keys.common.FILE),
    avatar: creator.avatarUrl,
    title: t(keys.dashboard.FILE_EXPIRY, { variable: file.name }),
    description: t(keys.dashboard.FILE_EXPIRY_TEXT, {
      variable:
        t(keys.dashboard[file.lastNotification]) || t(keys.dashboard.DUE),
    }),
    url: `/workspace/${workspaceId}/${paths.files}/${
      file.workspaceId ? 'workspace' : 'global'
    }?file=${file.id}&parent=${file.parentId}`,
  }),
};
