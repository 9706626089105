import { useState, useMemo } from 'react';
import styles from './Tasks.module.scss';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import UserMultiSelector from '../../components/UserMultiSelector';
import { Text } from '../../components/typography';
import SimpleUserCard from '../../components/SimpleUserCard';
import DropdownCombo from '../../components/dropdowns/DropdownCombo';
import { dropdownTypes } from '../../components/dropdowns/dropdown';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import Button from '../../components/Button';
import DateRangeSelector from '../../components/DateRangeSelector';
import { convertTaskDataFiltersToQueryParams } from '../../utilities/filtering';
import TablePagination from '../../components/pagination/TablePagination';
import TableSorting from '../../components/sorting/TableSorting';
import { sortTaskStrings } from '../../constants/strings';
import { keys } from '../../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';
import emptyStateImage from '../../assets/empty_state_images/inspections.svg';
import EmptyStateView from '../../components/empty_state_view/EmptyStateView';

const getTasksQuery = loader('./Tasks.tasks.graphql');
const getTaskDataQuery = loader('./Tasks.getTaskData.graphql');
const sortingOptions = ['dateCreated', 'title'];

export default function TaskData() {
  const { t } = useTranslation();
  const { inWorkspaces } = useWorkspace();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [dateFilter, setDateFilter] = useState({
    min: null,
    max: null,
  });
  const [page, setPage] = useState(1);
  const pageSize = 11;
  const [sortBy, setSortBy] = useState('title');
  const [isAscending, setIsAscending] = useState(false);

  const { subfilters: dataSubfilters } = useMemo(
    () =>
      convertTaskDataFiltersToQueryParams({
        dateFilter,
        selectedWorkspace: selectedWorkspace?.id,
      }),
    [selectedWorkspace, dateFilter],
  );

  const { refetch, data: { getTasks: tasks = [], taskCount: count = 0 } = {} } =
    useQuery(getTasksQuery, {
      skip: !selectedWorkspace?.id,
      variables: {
        options: {
          page,
          pageSize,
          sort: [{ field: sortBy, order: isAscending ? 'asc' : 'desc' }],
          filters: {
            operator: 'eq',
            field: 'workspaceId',
            value: [`${selectedWorkspace?.id}`],
          },
        },
      },
    });

  const { data: { getTaskTableData: taskData = [] } = {} } = useQuery(
    getTaskDataQuery,
    {
      skip: selectedUsers?.length < 0 || !selectedWorkspace?.id,
      variables: {
        options: {
          subfilters: dataSubfilters,
        },
      },
    },
  );

  const findCellValue = (
    { template: { id: rowId }, assigneeInfo },
    { id: colId },
  ) => {
    const cell = taskData.find(
      ({ templateId, userId }) => templateId === rowId && userId === colId,
    );

    if (!assigneeInfo.map(({ userId }) => userId).includes(colId)) {
      return '-';
    }
    return cell ? cell.inspectionCount : '0';
  };

  return (
    <div className={styles.dataContainer}>
      <div className={styles.workspaceSelector}>
        <DropdownCombo
          type={dropdownTypes.WORKSPACE}
          preventStateChange
          highlight={true}
          items={inWorkspaces || []}
          selected={selectedWorkspace}
          onChange={(workspace) => {
            setSelectedWorkspace(workspace);
            setSelectedUsers([]);
          }}
          required={true}
        />
      </div>
      <div className={styles.spacer} />
      <div className={styles.userContainer}>
        <UserMultiSelector
          type={'byWorkspace'}
          workspaceId={selectedWorkspace?.id}
          selected={selectedUsers}
          onUserAdded={(user) => {
            setSelectedUsers([...selectedUsers, user]);
          }}
          onUserRemoved={(user) => {
            setSelectedUsers([
              ...selectedUsers.filter(({ id }) => id !== user.id),
            ]);
          }}
        />
      </div>
      <div className={styles.buttonContainer}>
        <DateRangeSelector
          onChange={({ min, max }) => {
            setDateFilter({ min, max });
          }}
          values={dateFilter}
        />
        <div className={styles.sortContainer}>
          <Button
            onClick={() => {
              setPage(1);
              setSelectedUsers([]);
              setSelectedWorkspace(null);
              setDateFilter({ min: null, max: null });
            }}
            outlined
            variant="secondary"
            value={t(keys.tasks.CLEAR_SELECTION)}
          />
          {tasks.length > 0 && (
            <TableSorting
              setSortBy={setSortBy}
              setIsAscending={setIsAscending}
              refetch={refetch}
              isAscending={isAscending}
              sortingOptions={sortingOptions}
              sortBy={sortBy}
              sortStrings={sortTaskStrings}
            />
          )}
        </div>
      </div>
      <div className={styles.spacer} />
      {tasks?.length > 0 ? (
        <div className={styles.table}>
          <TableContainer
            component={Paper}
            sx={{ maxWidth: '100%', overflowX: 'auto' }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: 200 }}></TableCell>
                  {selectedUsers.map((user, index) => (
                    <TableCell key={index} align="center" sx={{ width: 200 }}>
                      <SimpleUserCard user={user} />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tasks.map((task, index) => (
                  <TableRow
                    key={index}
                    sx={[
                      {
                        backgroundColor: index % 2 === 0 ? 'grey.100' : 'white',
                      },
                    ]}
                  >
                    <TableCell component="th" scope="row" sx={{ width: 200 }}>
                      <Text noMargin weight="semiBold">
                        {task.title}
                      </Text>
                    </TableCell>
                    {selectedUsers.map((user, cellIndex) => (
                      <TableCell
                        key={cellIndex}
                        align="center"
                        sx={{ width: 200 }}
                      >
                        <Text noMargin weight="semiBold">
                          {findCellValue(task, user)}
                        </Text>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <div className={styles.emptyStateContainer}>
          <EmptyStateView
            title={t(keys.tasks.NO_DATA_TITLE)}
            text={t(keys.tasks.NO_DATA_TEXT)}
            image={emptyStateImage}
          />
        </div>
      )}
      <TablePagination
        pageSize={pageSize}
        count={count}
        setPage={setPage}
        page={page}
      />
    </div>
  );
}
