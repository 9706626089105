import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useMemo, useState, useEffect } from 'react';
import styles from './TablePrototype.module.scss';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Box, Button, Checkbox } from '@mui/material';
import { keys } from '../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Text } from './typography';
import { useIsMobile, useQueryParams } from '../hooks/misc';
import { Menu, MenuItem } from '@mui/material';
import { useWorkspace } from '../providers/WorkspaceProvider';
import EmptyStateView from './empty_state_view/EmptyStateView';

export default function TablePrototype({
  isLoading,
  data = [],
  columns,
  count,
  page = 1,
  setPage = () => {},
  pageSize = 10,
  setPageSize = () => {},
  setRawFilters = null,
  setSortBy = () => {},
  setIsAscending,
  onRowClick = () => {},
  enableRowActions = false,
  rowActions = () => {},
  headerButtonOptions = {},
  initialState = {},
  rightClickNavigation = null,
  renderDetailPanel = undefined,
  enableExpanding = false,
  enableExpandAll = false,
  setFilters = () => {},
  onClearAllFilters = () => {},
  customToolbar = null,
  emptyState = {},
}) {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const [columnFilters, setColumnFilters] = useState(
    initialState?.columnFilters || [],
  );
  const { workspaceId } = useWorkspace();
  const [sorting, setSorting] = useState([{ id: 'dateCreated', desc: true }]);
  const [pagination, setPagination] = useState({
    pageIndex: page - 1,
    pageSize,
  });
  const [density, setDensity] = useState('compact');
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [showColumnFilters, setColumnShowFilters] = useState(true);
  const { deleteParam, getParam } = useQueryParams();

  useEffect(() => {
    const sidebar = document.getElementById('left-sidebar-prototype');
    if (sidebar.clientWidth < 150) {
      setSidebarCollapsed(true);
    } else {
      setSidebarCollapsed(false);
    }
  }, [isFullScreen]);

  useEffect(() => {
    setPage(pagination.pageIndex + 1);
    setPageSize(pagination.pageSize);
    const filters = [];
    const subFilters = ['creator', 'user'];
    if (!!setRawFilters) {
      if (columnFilters.length > 0) {
        columnFilters.forEach((filter) => {
          filters.push({
            key: subFilters.includes(filter.id) ? `${filter.id}Id` : filter.id,
            value: filter.value,
          });
        });
        setRawFilters({
          filterData: {
            reactTable: true,
            filters,
            Hidden: headerButtonOptions?.hiddenButton?.state,
          },
        });
      } else {
        setRawFilters({});
      }
    }

    if (sorting.length > 0) {
      setSortBy(sorting[0].id);
      setIsAscending(!sorting[0].desc);
    } else {
      setSortBy('dateCreated');
      setIsAscending(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, columnFilters, sorting]);

  const [contextMenu, setContextMenu] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleRightClick = (event, row) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null,
    );
    setSelectedRow(row);
  };

  const openInNewWindow = () => {
    if (selectedRow) {
      window.open(
        `/workspace/${
          selectedRow.original.workspaceId || workspaceId
        }/${rightClickNavigation}/${selectedRow.original.id}`,
      );
    }
    setContextMenu(null);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const emptyStatePosition = useMemo(() => {
    const offset = windowWidth / 32 - 24;
    return {
      marginLeft: `${offset}rem`,
    };
  }, [windowWidth]);

  const table = useMaterialReactTable({
    columns,
    data,
    enableGlobalFilter: false,
    enableFilterMatchHighlighting: false,
    enableFacetedValues: true,
    enableRowActions,
    enableColumnOrdering: true,
    enableColumnResizing: true,
    enableFullScreenToggle: !isMobile,
    rowCount: count,
    state: {
      isFullScreen,
      isLoading,
      showSkeletons: isLoading,
      columnFilters,
      pagination,
      sorting,
      density,
      showColumnFilters,
    },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    initialState,
    onShowColumnFiltersChange: setColumnShowFilters,
    onColumnFiltersChange: (value) => {
      const creatorFilter = getParam('creator');
      if (creatorFilter) {
        deleteParam('creator');
      }
      setColumnFilters(value);
      setFilters(value);
    },
    muiTableHeadCellProps: () => ({
      style: {
        fontSize: '12px',
        border: 'none',
      },
    }),
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onDensityChange: setDensity,
    onIsFullScreenChange: setIsFullScreen,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => onRowClick(row.original),
      onContextMenu: (e) => {
        if (rightClickNavigation) {
          handleRightClick(e, row);
        }
      },
    }),
    muiTablePaperProps: ({ table }) => ({
      style: table.getState().isFullScreen
        ? {
            top: '4rem',
            marginLeft: !sidebarCollapsed ? '13rem' : '4.375rem',
            width: `calc(100% - ${!sidebarCollapsed ? '13rem' : '4.375rem'})`,
            height: '100%',
          }
        : {
            border: 'none',
            padding: '0.5rem',
            boxShadow: 'none',
            width: '100%',
            borderRadius: '10px',
          },
    }),
    renderRowActionMenuItems: rowActions,
    enableExpandAll,
    enableExpanding,
    renderDetailPanel,
    renderEmptyRowsFallback: () => (
      <div
        className={styles.emptyStateContainer}
        style={!isMobile ? { ...emptyStatePosition } : null}
      >
        <EmptyStateView
          size={isMobile}
          title={emptyState.title}
          text={emptyState.text}
          image={emptyState.image}
        />
      </div>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <div className={styles.toolbarActionsContainer}>
        {headerButtonOptions?.backButton?.enabled && (
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              flexWrap: 'wrap',
            }}
          >
            <Button onClick={headerButtonOptions.backButton.onClick}>
              {t(keys.common.BACK)}
            </Button>
          </Box>
        )}
        {
          <Box
            sx={{
              display: 'flex',
              padding: '0.5rem',
              gap: '1rem',
              flexWrap: 'wrap',
              alignItems: 'center',
            }}
          >
            <Text
              noMargin
              hover={columnFilters.length}
              color={columnFilters?.length ? 'accentPrimary' : 'secondaryLight'}
              onClick={() => {
                setColumnFilters([]);
                setFilters([]);
                onClearAllFilters();
                if (headerButtonOptions?.hiddenButton) {
                  headerButtonOptions.hiddenButton.enabled = false;
                }
              }}
              disabled={columnFilters?.length < 1}
            >
              {t(keys.common.CLEAR_ALL_FILTERS).toUpperCase()}
            </Text>
            {headerButtonOptions?.hiddenButton?.enabled && (
              <div className={styles.hiddenButtonContainer}>
                <Checkbox
                  icon={<VisibilityOff />}
                  checkedIcon={<Visibility />}
                  onChange={(e) =>
                    setRawFilters(({ filterData }) => ({
                      filterData: { ...filterData, Hidden: e.target.checked },
                    }))
                  }
                />
                <Text size="sm" noMargin>
                  {t(keys.utilities.HIDDEN)}
                </Text>
              </div>
            )}
            {customToolbar}
          </Box>
        }
      </div>
    ),
  });

  return (
    <div className={styles.table}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MaterialReactTable table={table} />
        <Menu
          open={contextMenu !== null}
          onClose={() => setContextMenu(null)}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={() => openInNewWindow()}>
            {t(keys.action.OPEN_IN_NEW_WINDOW)}
          </MenuItem>
        </Menu>
      </LocalizationProvider>
    </div>
  );
}
