import React, { useMemo } from 'react';
import styles from './WorkflowStageHeader.module.scss';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';

export default function WorkflowStageHeader({
  title,
  subtitle,
  workflow,
  completed,
}) {
  const { t } = useTranslation();
  const progress = useMemo(() => {
    return {
      completed: completed.length,
      total: workflow.length,
      percent: Math.round(100 * (completed.length / workflow.length)),
    };
  }, [completed, workflow]);
  return (
    <div className={styles.container}>
      <p className={styles.progress}>{`${t(keys.common.PROGRESS)} (${
        progress.completed
      }/${progress.total}) %${progress.percent}`}</p>
      <p className={styles.title}>{title}</p>
      <p className={styles.subtitle}>{subtitle}</p>
    </div>
  );
}
