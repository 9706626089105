import React, { useState } from 'react';
import WorkflowStageHeader from '../../components/workflows/WorkflowStageHeader';
import WorkflowStageFooter from '../../components/workflows/WorkflowStageFooter';
import styles from './CreateVehicleFeatures.module.scss';
import { Form, Row, Col } from 'react-bootstrap';
import { Button } from '../../components';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { Heading, SubHeading } from '../../components/typography';
import { Icon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';

const vehicleFeatureMutation = loader('./CreateVehicleFeatures.create.graphql');
const vehicleFeaturesQuery = loader('./CreateVehicleFeatures.fetch.graphql');
const deleteVehicleFeatureMutation = loader(
  './CreateVehicleFeatures.delete.graphql',
);

export default function CreateVehicleFeatures({
  workflow,
  completed,
  onCompleted,
  currentStage,
  vehicleId,
}) {
  const { t } = useTranslation();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const { refetch, data: { vehicleFeatures = [] } = {} } = useQuery(
    vehicleFeaturesQuery,
    {
      skip: !vehicleId,
      variables: {
        vehicleId,
      },
    },
  );

  const [addVehicleFeature, { loading }] = useMutation(vehicleFeatureMutation, {
    refetchQueries: [vehicleFeaturesQuery, 'GetVehicleFeaturesForCreation'],
    awaitRefetchQueries: true,
  });
  const [deleteVehicleFeature] = useMutation(deleteVehicleFeatureMutation);

  return (
    <div className={styles.container}>
      <WorkflowStageHeader
        title={t(keys.assets.P3_HEADER)}
        subtitle={t(keys.assets.P3_SUBTEXT)}
        workflow={workflow}
        completed={completed}
      />
      <div className={styles.featureContainer}>
        {vehicleFeatures.map((feature) => (
          <div className={styles.featureDisplay} key={feature.id}>
            <div>
              <Heading className={styles.heading}>{feature.title}</Heading>
              <SubHeading className={styles.subheading}>
                {feature.description}
              </SubHeading>
            </div>
            <Icon
              className={styles.closeIcon}
              sx={{ fontSize: '1rem', alignSelf: 'center' }}
              onClick={() => {
                deleteVehicleFeature({ variables: { id: feature.id } }).then(
                  () => refetch(),
                );
              }}
            >
              close
            </Icon>
          </div>
        ))}
      </div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          addVehicleFeature({
            variables: {
              vehicleId,
              title,
              description,
            },
          }).then(() => {
            setTitle('');
            setDescription('');
          });
          return false;
        }}
      >
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>{t(keys.assets.FEATURE_NAME)}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t(keys.assets.FEATURE_PLACEHOLDER)}
              data-cy="input-feature-title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>{t(keys.common.DESCRIPTION)}</Form.Label>
            <Form.Control
              data-cy="input-feature-description"
              as="textarea"
              type="text"
              placeholder={t(keys.assets.FEATURE_DESCRIPTION_PLACEHOLDER)}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Button
          testId="feature-create"
          type="submit"
          disabled={loading || !title.length || !vehicleId}
          value={t(keys.action.ADD, { variable: null })}
        />
      </Form>
      <WorkflowStageFooter
        onNext={() => {
          onCompleted(currentStage);
        }}
      />
    </div>
  );
}
