import { useQuery } from '@apollo/client';
import { Icon } from '@mui/material';
import classNames from 'classnames';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { Text } from '../typography';
import NotificationCard from './NotificationCard';
import styles from './NotificationList.module.scss';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';

const notifierQuery = loader('./NotificationList.graphql');

export default function NotificationList({
  show,
  onHide,
  refetchCount,
  containerRef,
  expand = false,
}) {
  const [page, setPage] = useState(1);
  const [showFullText, setShowFullText] = useState(expand);
  const pageSize = 50;
  const { t } = useTranslation();

  const { data: { notifiers = [] } = {} } = useQuery(notifierQuery, {
    skip: !show,
    variables: {
      page,
      pageSize,
    },
    onCompleted: () => {
      refetchCount();
    },
    fetchPolicy: 'no-cache',
  });

  return show ? (
    <div
      ref={containerRef}
      className={classNames(
        styles.container,
        show ? styles.show : styles.hide,
        showFullText ? styles.extendedContainer : styles.collapsedContainer,
      )}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.header}>
        <Text size="md" weight="bold" color="accentPrimary" noMargin>
          {t(keys.dashboard.NOTIFICATIONS)}
        </Text>
        <div className={styles.buttonContainer}>
          <Text
            className={styles.expandButton}
            size="sm"
            noMargin
            weight="semiBold"
            color="secondary"
            onClick={() => setShowFullText(!showFullText)}
          >
            {t(keys.dashboard.EXPAND_NOTIFICATIONS)}
            <Icon fontSize="0.5rem">
              {showFullText ? 'expand_less' : 'expand_more'}
            </Icon>
          </Text>
          <Icon
            fontSize="inherit"
            baseClassName="material-icons-outlined"
            className={styles.closeButton}
            onClick={() => onHide()}
          >
            close
          </Icon>
        </div>
      </div>
      <div
        className={classNames(
          styles.notificationContainer,
          showFullText
            ? styles.extendedNotificationContainer
            : styles.collapsedNotificationContainer,
        )}
      >
        {notifiers?.length ? (
          notifiers.map((notifier) => (
            <NotificationCard
              onHide={onHide}
              key={notifier.id}
              notifier={notifier}
              showFullText={showFullText}
            />
          ))
        ) : (
          <Text>
            {t(keys.action.NOT_FOUND, {
              variable: t(keys.dashboard.NOTIFICATIONS),
            })}
          </Text>
        )}
        {notifiers?.length % pageSize === 0 ? (
          <Text
            className={styles.moreButton}
            onClick={() => {
              setPage(page + 1);
            }}
            nomargin
            size="md"
            color="accentPrimary"
            weight="semiBold"
            textAlign="center"
          >
            {t(keys.dashboard.SHOW_MORE)}
          </Text>
        ) : null}
      </div>
    </div>
  ) : null;
}
