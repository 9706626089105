import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Text } from '../../components/typography';
import Button from '../../components/Button';
import styles from './VehicleDetailsPage.module.scss';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Spinner, Table } from 'react-bootstrap';
import SimpleUserCard from '../../components/SimpleUserCard';
import Label from '../../components/action_items/Label';
import { assessmentTypes, paths } from '../../constants/strings';
import ContextMenu from '../../components/ContextMenu';
import {
  openEditVehicleModal,
  openEditVehicleFeaturesModal,
  openFileViewer,
} from '../../graphql/cache/modal';
import { getLocalTime } from '../../utilities/time';
import { Icon } from '@mui/material';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import noImage from '../../assets/icons/image_blank.png';
import ActionItemCard from '../../components/action_items/ActionItemCard';
import icons from '../../assets/icons';
import ImageCropModal from '../../components/image_uploads/ImageCropModal';
import { CF_DOMAIN } from '../../constants/aws';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useApolloClient } from '@apollo/client';
import { assetFields, vehicleKeys } from '../../utilities/vehicles';
import { useActionItemModal } from '../../hooks/misc';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { useParams } from 'react-router-dom';
import { getRoute } from '../../constants/strings';
import { useCurrentUser } from '../../providers/UserProvider';
import { useModal } from '../../providers/ModalProvider';
import { modals } from '../../providers/modals';
import { useTranslation } from 'react-i18next';
import {
  getTranslationKey,
  keys,
} from '../../utilities/translator/translation_keys';
import FileCard from '../../components/files/FileCard';
import classNames from 'classnames';
import moment from 'moment';

const deleteMutation = loader(
  '../../components/vehicles/VehicleCard.delete.graphql',
);
const uploadUrlQuery = loader('../../graphql/queries/s3.graphql');

const vehicleQuery = loader('./VehicleDetailsPage.vehicle.graphql');
const inspectionQuery = loader('./VehicleDetailsPage.inspections.graphql');
const deleteFeatureMutation = loader(
  './VehicleDetailsPage.deleteFeature.graphql',
);
const vehicleFeaturesQuery = loader('./CreateVehicleFeatures.fetch.graphql');
const actionItemsQuery = loader('./VehicleDetailsPage.actionItems.graphql');
const addFileMutation = loader('./VehicleDetailsPage.addFile.graphql');
const addActionItemMutation = loader(
  '../../components/inspections/inspection_items/InspectionItemText.actionItem.graphql',
);
const vehicleMutation = loader('./CreateVehicleImage.update.graphql');

const generateInformationRow = (points, vehicle) => (
  <div>
    <div className={styles.row}>
      <Text size="md" noMargin weight="bold">
        {points[0].title}
      </Text>
      <Text size="md" noMargin weight="bold" textAlign="right">
        {points[1]?.title}
      </Text>
    </div>
    <div className={styles.row}>
      <Text size="md" weight="semiBold" noMargin color="secondary">
        {points[0].title === 'Odometer'
          ? `${
              vehicle[points[0].key] !== null
                ? `${vehicle[points[0].key]} ${vehicle?.isMetric ? 'km' : 'mi'}`
                : 'None'
            } `
          : vehicle[points[0].key] || 'None'}
      </Text>
      <Text
        size="md"
        noMargin
        color="secondary"
        weight="semiBold"
        textAlign="right"
      >
        {vehicle[points[1]?.key] ? vehicle[points[1].key] || 'None' : null}
      </Text>
    </div>
  </div>
);

export default function VehicleDetailsPage() {
  const { user: currentUser, isAdmin } = useCurrentUser();
  const client = useApolloClient();
  const { id } = useParams();
  const { workspace, isWorkspaceAdmin } = useWorkspace();
  const navigate = useNavigate();
  const { openActionItemModal } = useActionItemModal();
  const [deleteVehicle] = useMutation(deleteMutation);
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [expandedLists, setExpandedLists] = useState({
    features: false,
    actionItems: false,
    files: false,
  });
  const [vehicleImageModalOpen, setVehicleImageModalOpen] = useState(false);
  const [addActionItem] = useMutation(addActionItemMutation);
  const [updateVehicleImage] = useMutation(vehicleMutation);
  const [deleteFeature] = useMutation(deleteFeatureMutation, {
    refetchQueries: [vehicleFeaturesQuery, 'GetVehicleFeaturesAfterDelete'],
    awaitRefetchQueries: true,
  });
  const [addFile] = useMutation(addFileMutation);
  const { openModal, openConfirmationModal, closeModal } = useModal();
  const { t } = useTranslation();
  const namespace = 'assets';
  const {
    refetch,
    loading: vehicleLoading,
    data: { vehicles: [vehicle] = [{}] } = {},
  } = useQuery(vehicleQuery, {
    skip: !id,
    variables: { id: `${id}` },
  });

  const dataType = useMemo(
    () => (vehicleKeys.includes(vehicle?.type) ? 'vehicle' : 'asset'),
    [vehicle?.type],
  );

  const { data: { inspections = [] } = {} } = useQuery(inspectionQuery, {
    skip: !id,
    variables: { vehicleId: `${id}` },
  });
  const {
    refetch: refetchActionItems,
    loading: loadingActionItems,
    data: vehicleActionItems,
  } = useQuery(actionItemsQuery, {
    skip: !id,
    variables: {
      vehicleId: `${id}`,
    },
  });

  return vehicleLoading ? (
    <div className={styles.spinnerContainer}>
      <Spinner
        className={styles.spinner}
        animation="border"
        variant="primary"
      />
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.header}>
        <Text color="accentPrimary" size="lg" noMargin weight="bold">
          {t(keys.common.ASSET)}
        </Text>
        <div className={styles.buttonDisplay}>
          <Button
            value={t(keys.assets.INSPECT)}
            icon="search"
            size="md"
            onClick={() => {
              openModal({
                modalName: modals.createAssessment,
                variables: {
                  types: [assessmentTypes.fleet],
                  asset: vehicle,
                  participants: [currentUser],
                  type: assessmentTypes.fleet,
                },
              });
            }}
          />
        </div>
      </div>
      <Text size="md" weight="semiBold" noMargin color="secondary">
        {`${t(getTranslationKey(vehicle.type, namespace))} - ${t(
          getTranslationKey(vehicle.subtype, namespace),
        )}`}
      </Text>
      <div className={styles.contentContainer}>
        <div className={styles.leftContainer}>
          <div className={styles.header}>
            <Text noMargin weight="bold" size="lg">
              {`${vehicle.year || ''} ${vehicle.make} ${vehicle.model}`}
            </Text>
            {isEditing ? (
              <Icon
                className={styles.moreButton}
                onClick={() => setIsEditing(false)}
              >
                close
              </Icon>
            ) : (
              <div
                className={styles.moreButton}
                onClick={(e) => {
                  e.stopPropagation();
                  setContextMenuOpen(!contextMenuOpen);
                }}
              >
                <Icon
                  baseClassName="material-icons-outlined"
                  className={styles.moreButton}
                >
                  more_horiz
                </Icon>
                <div className={styles.contextMenu}>
                  <ContextMenu
                    open={contextMenuOpen}
                    options={[
                      {
                        title: t(keys.action.EDIT),
                        icon: 'edit',
                        disabled: !isWorkspaceAdmin,
                        onClick: () => {
                          setIsEditing(!isEditing);
                          setContextMenuOpen(false);
                        },
                      },
                      {
                        title: t(keys.action.DELETE),
                        icon: 'delete',
                        variant: 'red',
                        disabled: !isAdmin,
                        onClick: () => {
                          openConfirmationModal({
                            title: t(keys.assets.DELETE_ASSET),
                            description: t(keys.action.DELETE_CONFIRMATION, {
                              variable: `${vehicle.year || ''} ${
                                vehicle.make
                              } ${vehicle.model} ${vehicle.unitNumber}`,
                            }),
                            variant: 'danger',
                            onSubmit: () => {
                              deleteVehicle({
                                variables: { vehicleId: vehicle.id },
                              }).then(() => {
                                closeModal({ modalName: modals.confirmation });
                                navigate(getRoute(workspace?.id, paths.assets));
                              });
                            },
                          });
                        },
                      },
                    ]}
                    setOpen={setContextMenuOpen}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={styles.imageContainer}>
            <div className={styles.vehicleImage}>
              <img
                src={vehicle.imageUrl ? vehicle.imageUrl : noImage}
                className={
                  vehicle.imageUrl ? styles.image : styles.defaultImage
                }
                alt={`${vehicle.year || ''} ${vehicle.make} ${vehicle.model}`}
              />
              {isEditing && (
                <div className={styles.editVehicleImageContainer}>
                  <img
                    className={styles.editButton}
                    src={icons.white.edit}
                    alt="edit icon"
                    onClick={() => setVehicleImageModalOpen(true)}
                  />
                </div>
              )}
            </div>
          </div>

          <div className={styles.detailsContainer}>
            <Text color="accentPrimary" size="lg" noMargin weight="bold">
              {t(keys.assets.INSPECTION_HISTORY)}
            </Text>
            {!!inspections?.length ? (
              <div className={styles.inspectionList}>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Text size="md" weight="semiBold" noMargin noSelect>
                          {t(keys.common.TITLE)}
                        </Text>
                      </th>
                      <th>
                        <Text size="md" weight="semiBold" noMargin noSelect>
                          {t(keys.common.CREATOR)}
                        </Text>
                      </th>
                      <th>
                        <Text size="md" weight="semiBold" noMargin noSelect>
                          {t(keys.common.DATE)}
                        </Text>
                      </th>
                      <th>
                        <Text size="md" weight="semiBold" noMargin noSelect>
                          {t(keys.common.STATUS)}
                        </Text>
                      </th>
                      <th>
                        <Text size="md" weight="semiBold" noMargin noSelect>
                          {t(keys.assets.OUTCOME)}
                        </Text>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {inspections.map((inspection) => (
                      <tr
                        key={inspection.id}
                        className={styles.inspectionRow}
                        onClick={() =>
                          navigate(
                            getRoute(
                              workspace?.id,
                              paths.assessment,
                              inspection?.id,
                            ),
                          )
                        }
                      >
                        <td>
                          <Text size="md" noMargin noSelect>
                            {inspection?.template?.title}
                          </Text>
                        </td>
                        <td>
                          <SimpleUserCard user={inspection?.creator} />
                        </td>
                        <td>
                          <Text size="md" noMargin noSelect>
                            {getLocalTime(inspection?.dateCreated).fromNow()}
                          </Text>
                        </td>
                        <td>
                          {inspection?.isDraft ? (
                            <Label
                              className={styles.label}
                              name={t(keys.common.IN_PROGRESS)}
                              color="yellow"
                            />
                          ) : (
                            <Label
                              className={styles.label}
                              name={t(keys.common.COMPLETE)}
                              color="green"
                            />
                          )}
                        </td>
                        <td>
                          {inspection.isDraft ? (
                            <Label
                              className={styles.label}
                              name={t(keys.common.IN_PROGRESS)}
                              color="yellow"
                            />
                          ) : (
                            <Label
                              className={styles.label}
                              name={
                                inspection.passed
                                  ? t(keys.common.PASSED)
                                  : t(keys.common.FAILED)
                              }
                              color={inspection.passed ? 'green' : 'red'}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : (
              <Text
                noMargin
                size="md"
                weight="semiBold"
                color="secondary"
                textAlign="center"
              >
                {t(keys.assets.NO_INSPECTIONS_FOUND)}
              </Text>
            )}
          </div>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.rightDetailsContainer}>
            <div className={styles.containerHeader}>
              <Text color="accentPrimary" size="lg" noMargin weight="bold">
                {t(keys.common.INFORMATION)}
              </Text>
              {isEditing && (
                <div className={styles.editButton}>
                  <Icon
                    baseClassName="material-icons-outlined"
                    color="disabled"
                    onClick={() => openEditVehicleModal(vehicle)}
                  >
                    create
                  </Icon>
                </div>
              )}
            </div>

            {generateInformationRow(
              [
                {
                  title: t(assetFields.make[dataType].title),
                  key: 'make',
                },
                { title: t(keys.assets.MODEL), key: 'model' },
              ],
              vehicle,
            )}
            {generateInformationRow(
              [
                { title: t(keys.assets.YEAR), key: 'year' },
                { title: t(keys.common.COLOUR), key: 'color' },
              ],
              vehicle,
            )}
            {dataType === 'vehicle' &&
              generateInformationRow(
                [
                  { title: t(keys.assets.ODOMETER), key: 'odometer' },
                  { title: t(keys.assets.LICENSE_PLATE), key: 'licensePlate' },
                ],
                vehicle,
              )}
            {generateInformationRow(
              [
                { title: t(keys.assets.UNIT_NUMBER), key: 'unitNumber' },
                {
                  title: t(assetFields.vin[dataType].title),
                  key: 'vehicleIdentificationNumber',
                },
              ],
              vehicle,
            )}
            {generateInformationRow(
              [{ title: t(keys.common.LOCATION), key: 'location' }],
              vehicle,
            )}
          </div>
          <div className={styles.expandableSection}>
            <div className={styles.containerHeader}>
              <Text color="accentPrimary" size="lg" noMargin weight="bold">
                {t(keys.assets.FEATURES)}
              </Text>
              <div className={styles.buttonContainer}>
                {isWorkspaceAdmin && (
                  <div
                    className={styles.addButton}
                    onClick={() =>
                      openEditVehicleFeaturesModal(vehicle, refetch)
                    }
                  >
                    <Icon
                      baseClassName="material-icons-outlined"
                      color="primary"
                    >
                      add
                    </Icon>
                  </div>
                )}
                <Icon
                  className={styles.expandIcon}
                  baseClassName="material-icons-outlined"
                  color="primary"
                  onClick={() =>
                    setExpandedLists({
                      ...expandedLists,
                      features: !expandedLists.features,
                    })
                  }
                >
                  {!expandedLists.features ? 'expand_more' : 'expand_less'}
                </Icon>
              </div>
            </div>
            <div
              className={classNames(
                styles.featuresDisplay,
                !expandedLists.features && styles.hide,
              )}
            >
              {!!vehicle.features?.length ? (
                vehicle.features.map((feature) => (
                  <div
                    className={styles.featuresBlock}
                    key={`feature-${feature.id}`}
                  >
                    <div key={feature.id}>
                      <Text size="md" weight="bold" noMargin>
                        {feature.title}
                      </Text>
                      <Text
                        size="md"
                        weight="semiBold"
                        color="secondary"
                        noMargin
                      >
                        {feature.description}
                      </Text>
                    </div>
                    {isEditing && (
                      <div className={styles.editButton}>
                        <Icon
                          baseClassName="material-icons-outlined"
                          color="disabled"
                          onClick={() => {
                            openConfirmationModal({
                              title: t(keys.action.DELETE_VARIABLE, {
                                variable: t(keys.assets.FEATURE),
                              }),
                              description: t(keys.action.DELETE_CONFIRMATION, {
                                variable: feature.title,
                              }),
                              variant: 'danger',
                              onSubmit: () => {
                                deleteFeature({
                                  variables: {
                                    id: feature.id,
                                  },
                                }).then(() => {
                                  refetch();
                                  closeModal({
                                    modalName: modals.confirmation,
                                  });
                                });
                              },
                            });
                          }}
                        >
                          delete
                        </Icon>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className={styles.emptyStateContainer}>
                  <Text color="secondary" weight="semiBold" textAlign="center">
                    {t(keys.assets.NO_FEATURES_FOUND)}
                  </Text>
                </div>
              )}
            </div>

            <div className={styles.actionItemLink}>
              <Text color="accentPrimary" size="lg" noMargin weight="bold">
                {t(keys.common.ACTION_ITEMS)}
              </Text>
              <div className={styles.buttonContainer}>
                <div
                  className={styles.addButton}
                  onClick={() => {
                    addActionItem({
                      variables: {
                        title: `${vehicle.make} ${vehicle.model}`,
                        type: 'VEHICLE',
                        vehicleId: vehicle.id,
                        workspaceId: workspace.id,
                      },
                    }).then(
                      ({
                        data: {
                          addActionItem: { id },
                        },
                      }) => {
                        openActionItemModal(id, 'VEHICLE', refetchActionItems);
                      },
                    );
                  }}
                >
                  <Icon
                    baseClassName="material-icons-outlined"
                    color="primary"
                    data-cy="asset-details-add-action-item"
                  >
                    add
                  </Icon>
                </div>
                <Icon
                  className={styles.expandIcon}
                  baseClassName="material-icons-outlined"
                  color="primary"
                  onClick={() =>
                    setExpandedLists({
                      ...expandedLists,
                      actionItems: !expandedLists.actionItems,
                    })
                  }
                >
                  {!expandedLists.actionItems ? 'expand_more' : 'expand_less'}
                </Icon>
              </div>
            </div>
            <div
              className={classNames(
                styles.featuresDisplay,
                !expandedLists.actionItems && styles.hide,
              )}
            >
              {!loadingActionItems ? (
                <div className={styles.actionItemList}>
                  {vehicleActionItems?.actionItems?.length ? (
                    <div>
                      {vehicleActionItems?.actionItems.map((actionItem) => (
                        <ActionItemCard
                          key={actionItem.id}
                          onClick={() =>
                            openActionItemModal(
                              actionItem.id,
                              refetchActionItems,
                            )
                          }
                          actionItem={actionItem}
                          className={styles.actionItemCard}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className={styles.emptyStateContainer}>
                      <Text
                        color="secondary"
                        weight="semiBold"
                        textAlign="center"
                      >
                        {t(keys.action.NOT_FOUND, {
                          variable: t(keys.common.ACTION_ITEMS),
                        })}
                      </Text>
                    </div>
                  )}
                </div>
              ) : (
                <div className={styles.spinnerContainer}>
                  <Spinner
                    className={styles.spinner}
                    animation="border"
                    variant="primary"
                  />
                </div>
              )}
            </div>
            <div className={styles.actionItemLink}>
              <Text color="accentPrimary" size="lg" noMargin weight="bold">
                {t(keys.common.FILES)}
              </Text>
              <div className={styles.buttonContainer}>
                {isAdmin && (
                  <div
                    className={styles.addButton}
                    onClick={() => {
                      openModal({
                        modalName: modals.fileUpload,
                        variables: {
                          asset: vehicle,
                          from: 'ASSET',
                          onSubmit: ({
                            url,
                            size,
                            fileType,
                            extension,
                            name,
                            expires,
                            userId,
                            status,
                            downloadAllowed,
                            type,
                          }) =>
                            addFile({
                              variables: {
                                url,
                                size,
                                fileType,
                                extension,
                                name,
                                vehicleId: vehicle.id,
                                userId,
                                expires: expires
                                  ? moment(parseInt(expires)).format(
                                      'YYYY-MM-DD',
                                    )
                                  : null,
                                status,
                                downloadAllowed,
                                type,
                                hidden: true,
                              },
                            }).then(() => refetch()),
                        },
                      });
                    }}
                  >
                    <Icon
                      baseClassName="material-icons-outlined"
                      color="primary"
                      data-cy="asset-details-add-file"
                    >
                      add
                    </Icon>
                  </div>
                )}
                <Icon
                  className={styles.expandIcon}
                  baseClassName="material-icons-outlined"
                  color="primary"
                  onClick={() =>
                    setExpandedLists({
                      ...expandedLists,
                      files: !expandedLists.files,
                    })
                  }
                >
                  {!expandedLists.files ? 'expand_more' : 'expand_less'}
                </Icon>
              </div>
            </div>
            <div
              className={classNames(
                styles.featuresDisplay,
                !expandedLists.files && styles.hide,
              )}
            >
              {!vehicleLoading ? (
                <div className={styles.listContainer}>
                  {vehicle?.files?.length ? (
                    <div className={styles.fileList}>
                      {vehicle?.files.map((file, index) => (
                        <FileCard
                          key={`${file.id}-${index}`}
                          onClick={() =>
                            openFileViewer(file, () =>
                              navigate(
                                getRoute(
                                  workspace?.id,
                                  paths.files,
                                  `${
                                    file.workspaceId ? 'workspace' : 'global'
                                  }`,
                                  `?file=${file.id}&parent=${file.parentId}&hidden=${file.hidden}`,
                                ),
                              ),
                            )
                          }
                          refetchFiles={refetch}
                          readOnly={!isWorkspaceAdmin}
                          simple={true}
                          file={file}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className={styles.emptyStateContainer}>
                      <Text
                        color="secondary"
                        weight="semiBold"
                        textAlign="center"
                      >
                        {t(keys.action.NOT_FOUND, {
                          variable: t(keys.common.FILES),
                        })}
                      </Text>
                    </div>
                  )}
                </div>
              ) : (
                <div className={styles.spinnerContainer}>
                  <Spinner
                    className={styles.spinner}
                    animation="border"
                    variant="primary"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {vehicleImageModalOpen && (
        <ImageCropModal
          title={t(keys.assets.ASSET_IMAGE)}
          onClose={() => setVehicleImageModalOpen(false)}
          onSave={(file) => {
            setVehicleImageModalOpen(false);

            const fileUUID = uuidv4();
            const fileParts = file.name.split('.');
            const fileName = fileUUID;
            const fileType = fileParts[1];
            client
              .query({
                query: uploadUrlQuery,
                variables: {
                  fileName: `assets/${currentUser.company.id}/${fileName}.${fileType}`,
                  fileType,
                },
              })
              .then((data) => {
                const signedUrl = data.data.simpleStorageUploadUrl;
                const options = {
                  headers: {
                    'Content-Type': fileType,
                  },
                };
                axios.put(signedUrl, file, options).then((result) => {
                  if (result.status === 200) {
                    updateVehicleImage({
                      variables: {
                        vehicleId: vehicle.id,
                        imageUrl: `${CF_DOMAIN(
                          currentUser,
                        )}${fileName}.${fileType}`,
                      },
                    });
                  }
                });
              });
          }}
        />
      )}
    </div>
  );
}
