import Button from '../Button';
import { Text } from '../typography';
import styles from './Modal.module.scss';
import * as MuiModalComponents from '@mui/material/Modal';
import { Icon } from '@mui/material';
import { keys } from '../../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';
import Label from '../action_items/Label';

export const MODAL_CLOSE_EVENT = {
  submitted: 'SUBMITTED',
  canceled: 'CANCELED',
};

export default function Modal({
  title,
  open,
  onClose,
  children,
  variant = 'primary',
  hideCancel = false,
  hideSubmit = false,
  submitText,
  cancelText,
  submitDisabled = false,
  onSubmit,
  allowClose = true,
  submitButtonIcon = null,
  disableCloseOnSubmit = false,
  disableCloseOnCancel = false,
  onCancel = () => {},
  footer,
  beta = false,
  subtitle = undefined,
  titleImage = undefined,
  overflow = 'scroll',
}) {
  const MuiModal = MuiModalComponents.default;
  const { t } = useTranslation();

  const handleClose = (event, reason) => {
    if (!allowClose && reason === 'backdropClick') {
      return;
    }
    onClose(event, reason);
  };

  return (
    <MuiModal
      className={styles.background}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus={true}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.titleContainer}>
            <div className={styles.leftTitle}>
              {titleImage}
              <div>
                <Text noMargin noSelect size="lg" weight="semiBold">
                  {title}
                </Text>
                <Text noMargin color="secondary" size="sm" weight="semiBold">
                  {subtitle}
                </Text>
              </div>
            </div>

            {!!beta && <Label name="Beta" color="Blue" />}
          </div>

          {allowClose && (
            <Icon
              onClick={() => onClose(null, MODAL_CLOSE_EVENT.canceled)}
              className={styles.closeIcon}
            >
              close
            </Icon>
          )}
        </div>
        <div style={{ overflow }} className={styles.contentContainer}>
          {children}
        </div>
        <div className={styles.footer}>
          {footer && footer}
          {!hideCancel && (
            <Button
              outlined
              variant="secondary"
              onClick={() => {
                if (!disableCloseOnCancel) {
                  onClose(null, MODAL_CLOSE_EVENT.canceled);
                }
                onCancel();
              }}
              value={cancelText || t(keys.action.CANCEL)}
            />
          )}
          {!hideSubmit && (
            <Button
              disabled={submitDisabled}
              variant={variant}
              value={submitText || t(keys.action.SUBMIT)}
              icon={submitButtonIcon}
              onClick={() => {
                onSubmit();
                if (!disableCloseOnSubmit) {
                  onClose(null, MODAL_CLOSE_EVENT.submitted);
                }
              }}
            />
          )}
        </div>
      </div>
    </MuiModal>
  );
}
