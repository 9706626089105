export function exportToCSV({ data, headerMap = {}, sheetName }) {
  if (!data || data.length === 0) {
    console.error('No data to export.');
    return;
  }

  const localHeaders = Object.keys(data[0]);
  const headers = localHeaders.map((header) => headerMap[header] || header);

  const csvContent = [
    headers.join(','),
    ...data.map((col) =>
      localHeaders.map((header) => removeCommas(col[header])).join(','),
    ),
  ].join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
  const link = document.createElement('a');

  link.href = window.URL.createObjectURL(blob);
  link.download = `${sheetName}.csv`;

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
}

function removeCommas(value) {
  if (typeof value === 'string' && value.includes(',')) {
    return `"${value.replace(/"/g, '""')}"`;
  }
  return value;
}

export function exportToExcel({
  data,
  headerMap = {},
  sheetName,
  exportedBy,
  dateRange,
  type,
  fileName,
  reportType,
  errorCallback,
}) {
  if (!data || data.length === 0) {
    console.error('No data to export.');
    return;
  }

  try {
    const XLSX = require('xlsx');
    const info = [
      ['Exported by', exportedBy],
      [`${type} Period`, dateRange],
      ['Exported On', `${new Date().toLocaleString()}`],
      reportType,
      [],
      Object.keys(data[0]).map((key) => headerMap[key] || key),
      ...data.map((row) => Object.values(row)),
    ];

    const ws = XLSX.utils.json_to_sheet(info, { skipHeader: true });

    const wb = XLSX.utils.book_new();

    ws['!cols'] = Array.from(
      { length: XLSX.utils.decode_range(ws['!ref']).e.c + 1 },
      () => ({ width: 20 }),
    );

    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);
    link.download = `${fileName}.xlsx`;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  } catch (error) {
    console.error('Error during export:', error);
    if (errorCallback) errorCallback(error);
  }
}
