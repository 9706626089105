import Modal from '../Modal';
import styles from './FileUploadModal.module.scss';
import { useModal } from '../../../providers/ModalProvider';
import { modals } from '../../../providers/modals';
import { useWorkspace } from '../../../providers/WorkspaceProvider';
import { loader } from 'graphql.macro';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { CF_DOMAIN } from '../../../constants/aws';
import { useApolloClient } from '@apollo/client';
import { useCurrentUser } from '../../../providers/UserProvider';
import { Text } from '../../typography';
import { MAX_FILE_UPLOAD_SIZE } from '../../../constants/misc';
import { Form } from 'react-bootstrap';
import { DropdownText } from '../../dropdowns/DropdownText';
import {
  fileStatusTypes,
  fileDescriptionTypes,
} from '../../../../src/utilities/files';
import DropdownCombo from '../../dropdowns/DropdownCombo';
import DatePickerComponent from '../DatePickerComponent';
import UserSelector from '../../UserSelector';
import moment from 'moment';
import { useInspection } from '../../../hooks/offline-hooks/createInspectionhook';
import { useTranslation } from 'react-i18next';
import { keys } from '../../../utilities/translator/translation_keys';
import FileUploadInput from '../../files/FileUploadInput';
import { dropdownTypes } from '../../dropdowns/dropdown';
import VehicleCardList from '../../vehicles/VehicleCardList';

const uploadUrlQuery = loader('../../../graphql/queries/s3.graphql');

export default function FileUploadModal() {
  const { workspaceId, workspaceVehicles } = useWorkspace();
  const { user: currentUser } = useCurrentUser();
  const client = useApolloClient();
  const { modalState, closeModal, updateModal } = useModal();
  const { t } = useTranslation();
  const {
    modalTitle,
    show,
    status,
    type,
    asset,
    expires,
    user,
    downloadAllowed,
    onLoading,
    name,
    size,
    fileToUpload,
    simple,
    allWorkspaces,
    onSubmit,
    assessment,
    previousAdditional,
    accept,
    from,
  } = modalState.fileUploadModal;
  const { addUploadFileResults } = useInspection();

  const onHide = () => closeModal({ modalName: modals.fileUpload });

  const update = (variables) =>
    updateModal({ modalName: modals.fileUpload, variables: { ...variables } });

  const handleAssessmentFile = (file) => {
    onLoading(file.size);
    addUploadFileResults(onSubmit, file, name, previousAdditional);
  };
  const handleUpload = (
    file,
    { user, asset, expiry, name, status, type, workspaceId, downloadAllowed },
  ) => {
    onLoading(file.size);
    const fileUUID = uuidv4();
    const fileParts = file.name.split('.');
    const fileName = fileUUID;
    const fileType = fileParts[fileParts.length - 1];
    client
      .query({
        query: uploadUrlQuery,
        variables: {
          fileName: `assets/${currentUser?.company?.id}/${fileName}.${fileType}`,
          fileType,
        },
      })
      .then((data) => {
        const signedUrl = data.data.simpleStorageUploadUrl;
        const options = {
          headers: {
            'Content-Type': fileType === 'pdf' ? 'application/pdf' : fileType,
          },
        };
        axios.put(signedUrl, file, options).then((result) => {
          if (result.status === 200) {
            onSubmit({
              url: `${CF_DOMAIN(currentUser)}${fileName}.${fileType}`,
              size: file.size,
              extension: fileType,
              fileType: file.type.length ? file.type : fileType,
              name: name || file.name,
              userId: user?.id,
              vehicleId: asset?.id,
              expires: expiry,
              status,
              type,
              workspaceId,
              downloadAllowed,
            });
          }
        });
      });
  };

  return (
    <Modal
      open={show || false}
      overflow={from === 'ASSET' ? 'visible' : 'scroll'}
      onClose={onHide}
      title={modalTitle ? modalTitle : t(keys.files.UPLOAD_FILE)}
      submitText={t(keys.action.UPLOAD)}
      submitButtonIcon={'cloud_upload'}
      submitDisabled={!size || size > MAX_FILE_UPLOAD_SIZE || !name}
      onSubmit={() =>
        assessment
          ? handleAssessmentFile(fileToUpload)
          : handleUpload(fileToUpload, {
              user,
              expiry: expires ? `${moment(expires).unix() * 1000}` : undefined,
              asset,
              name,
              status,
              type,
              workspaceId: allWorkspaces ? null : workspaceId,
              downloadAllowed,
            })
      }
    >
      <div className={styles.input}>
        <FileUploadInput
          accept={accept}
          onChange={(e) => {
            update({
              fileToUpload: e.target.files[0],
              name: e.target.files[0].name,
              size: e.target.files[0].size,
            });
          }}
        />
        {size > MAX_FILE_UPLOAD_SIZE && (
          <Text color="red" weight="semibold">
            {t(keys.files.FILE_SIZE_WARNING, { size: '100MB' })}
          </Text>
        )}
      </div>
      {!!size && (
        <div className={styles.content}>
          {from === 'ASSET' && (
            <>
              <Text noMargin weight="semiBold">
                {t(keys.common.ASSET)}
              </Text>
              <VehicleCardList vehicle={asset} readOnly={true} />
            </>
          )}
          <div className={styles.field}>
            <Text className={styles.label} noMargin weight="semiBold">
              {`${t(keys.common.TITLE)}*`}
            </Text>
            <Form.Control
              type="text"
              placeholder={t(keys.files.TITLE_PLACEHOLDER)}
              value={name}
              onChange={(e) =>
                update({
                  name:
                    e.target.value.charAt(0).toUpperCase() +
                    e.target.value.slice(1),
                })
              }
            />
          </div>
          {!simple && (
            <div>
              {from !== 'ASSET' && (
                <DropdownText
                  title={t(keys.common.STATUS)}
                  selected={status}
                  onChange={(selected) => update({ status: selected })}
                  onRemove={() => update({ status: null })}
                  items={fileStatusTypes.sort()}
                  namespace="files"
                />
              )}
              <DropdownText
                title={t(keys.common.TYPE)}
                selected={type}
                onChange={(selected) => update({ type: selected })}
                onRemove={() => update({ type: null })}
                items={fileDescriptionTypes.sort()}
                fixedDropdownSize={true}
                namespace="files"
              />
              {from !== 'ASSET' && (
                <DropdownCombo
                  items={workspaceVehicles}
                  type={dropdownTypes.ASSET}
                  title={t(keys.common.ASSET)}
                  onChange={(selected) => {
                    update({ asset: selected });
                  }}
                  selected={asset}
                />
              )}
              <div className={styles.field}>
                <Text className={styles.label} noMargin weight="semiBold">
                  {t(keys.action.EXPIRES)}
                </Text>
                <DatePickerComponent
                  selected={expires}
                  onChange={(date) => {
                    update({ expires: date });
                  }}
                  isClearable
                />
                {from !== 'ASSET' && (
                  <>
                    <Text className={styles.label} noMargin weight="bold">
                      {t(keys.common.USER)}
                    </Text>
                    <UserSelector
                      disableRemove={from === 'USER'}
                      className={styles.avatarBox}
                      type="user"
                      selected={user}
                      onClose={() => {
                        update({ user: null });
                      }}
                      onSelectionChanged={(user) => {
                        update({ user });
                      }}
                    />
                  </>
                )}
              </div>
              <Form.Check
                inline
                className={styles.check}
                checked={downloadAllowed}
                onChange={(e) => {
                  update({ downloadAllowed: e.target.checked });
                }}
                label={t(keys.files.ALLOW_DOWNLOAD)}
                type="checkbox"
                value={downloadAllowed}
              />
            </div>
          )}
          <br />
        </div>
      )}
    </Modal>
  );
}
