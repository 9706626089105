import { createContext, useContext, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { formatFilters } from '../components/tables/table';
import { useWorkspace } from './WorkspaceProvider';
import { nonFilterParams } from '../components/tables/table';
import { useQueryParams } from '../hooks/misc';
import { useCurrentUser } from './UserProvider';

const URLParamContext = createContext();
export const URLParamProvider = ({ children }) => {
  const location = useLocation();
  const { workspaceId } = useWorkspace();
  const { deleteParam, getParam } = useQueryParams();
  const { user } = useCurrentUser();
  const hasAssignee = getParam('assignee') === `${user.id}`;
  const allWorkspaces = getParam('allWorkspaces');

  useEffect(() => {
    if (!hasAssignee && !!allWorkspaces) {
      deleteParam('allWorkspaces');
    }
  }, [allWorkspaces, deleteParam, hasAssignee]);

  const getParams = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const queryParams = {};

    const filters = [
      { field: 'workspaceId', operator: 'eq', value: `${workspaceId}` },
    ];
    if (getParam('open') === 'true') {
      filters.push({
        field: 'status',
        operator: 'not',
        value: ['DONE', 'DUPLICATE'],
      });
    }
    for (const [key, value] of params.entries()) {
      queryParams[key] = value;
    }
    queryParams.pageIndex = parseInt(queryParams.pageIndex);
    queryParams.pageSize = parseInt(queryParams.pageSize);

    for (const [key, value] of Object.entries(queryParams)) {
      let newValue = undefined;
      if (!nonFilterParams.includes(key) && value !== '__' && !!value) {
        if (value.includes('__')) {
          newValue = value.split('__');
        }
        const filter = formatFilters(key, newValue || value);
        filters.push(filter);
      }
      if (key === 'allWorkspaces' && hasAssignee) {
        const index = filters.findIndex(
          (filter) => filter.field === 'workspaceId',
        );
        if (index !== -1) {
          filters.splice(index, 1);
        }
      }
    }
    queryParams.sort = [
      {
        field: queryParams.sort,
        order:
          queryParams.desc === 'true' ? 'desc NULLS last' : 'asc NULLS last',
      },
    ];

    queryParams.filters = filters;
    return queryParams;
  }, [getParam, hasAssignee, location.search, workspaceId]);

  const { pageSize, pageIndex, filters, sort } = getParams || {};

  const tableParams = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const params = {};

    searchParams.forEach((value, key) => {
      if (key === 'pageIndex' || key === 'pageSize') {
        params[key] = parseInt(value);
      }
      if (value === '__') {
        deleteParam(key);
      }
      if (value.includes('__')) {
        params[key] = value.split('__');
      } else {
        params[key] = value;
      }
    });

    const tableFilters = Object.entries(params)
      .filter(([key]) => !nonFilterParams.includes(key))
      .map(([key, value]) => ({ id: key, value }));

    const tablePageIndex = params.pageIndex || 0;
    const tablePageSize = params.pageSize || 10;

    return { tableFilters, tablePageIndex, tablePageSize };
  }, [deleteParam, location.search]);

  const { tableFilters, tablePageIndex, tablePageSize } = tableParams || {};

  return (
    <URLParamContext.Provider
      value={{
        pageSize,
        page: pageIndex + 1,
        filters,
        sort,
        tableFilters,
        tablePageIndex,
        tablePageSize,
      }}
    >
      {children}
    </URLParamContext.Provider>
  );
};
export const useURLParams = () => useContext(URLParamContext);
