/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import styles from './MTable.module.scss';
import { Text } from '../typography';
import { useQueryParams } from '../../hooks/misc';
import { useTableURLParams } from './table';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { tableOptions, tableStyle } from './MTableComponents';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useURLParams } from '../../providers/URLParamProvider';

export default function MTTable({
  data = [],
  columns = [],
  header,
  rowCount,
  isLoading,
  customToolbar,
  onRowClick = () => {},
}) {
  const { upsertParams } = useQueryParams();
  const { tableFiltersToURL } = useTableURLParams();
  const { tableFilters, tablePageSize, tablePageIndex } = useURLParams();
  const { t } = useTranslation();
  const [sorting, setSorting] = useState([{ id: 'dateCreated', desc: true }]);

  const [pagination, setPagination] = useState({
    pageIndex: tablePageIndex,
    pageSize: tablePageSize,
  });

  const [filters, setFilters] = useState(tableFilters);
  useEffect(() => tableFiltersToURL(filters), [filters]);
  useEffect(() => {
    upsertParams({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sort: sorting[0]?.id || 'dateCreated',
      desc: sorting[0]?.desc,
    });
  }, [pagination, sorting]);

  const table = useMaterialReactTable({
    data,
    columns,
    ...tableOptions,
    ...tableStyle,
    state: {
      pagination,
      columnFilters: tableFilters,
      isLoading,
      sorting,
    },
    initialState: {
      pagination,
      columnFilters: tableFilters,
      density: 'compact',
      showColumnFilters: true,
    },
    rowCount,
    onPaginationChange: setPagination,
    onColumnFiltersChange: setFilters,
    onSortingChange: setSorting,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => onRowClick(row.original),
    }),
    renderTopToolbarCustomActions: () => (
      <div className={styles.toolbar}>
        <Text
          noMargin
          hover={!!tableFilters?.length}
          color={!!tableFilters?.length ? 'accentPrimary' : 'secondaryLight'}
          onClick={() => {
            setFilters([]);
          }}
          disabled={!!!tableFilters}
        >
          {t(keys.common.CLEAR_ALL_FILTERS).toUpperCase()}
        </Text>
        {customToolbar}
      </div>
    ),
  });

  return (
    <div
      className={`${styles.page} ${
        table.getState().isFullScreen ? styles.fullScreen : ''
      }`}
    >
      <div className={styles.header}>{header}</div>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MaterialReactTable table={table} />
      </LocalizationProvider>
    </div>
  );
}
