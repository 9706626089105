import { keys } from './translator/translation_keys';

export const vehicles = {
  LV: {
    string: 'Light Vehicle',
    CAR: {
      string: 'Car',
    },
    SUV: {
      string: 'SUV',
    },
    VAN: {
      string: 'Van',
    },
    TRAILER: {
      string: 'Trailer',
    },
    MOTORCYCLE: {
      string: 'Motorcycle',
    },
    TRUCK: {
      string: 'Truck',
    },
    OTHER: {
      string: 'Other',
    },
  },
  RV: {
    string: 'Recreation Vehicle',
    ATV: {
      string: 'ATV',
    },
    BOAT: {
      string: 'Boat',
    },
    SNOWMOBILE: {
      string: 'Snowmobile',
    },
    OTHER: {
      string: 'Other',
    },
  },
  HE: {
    string: 'Heavy Equipment',
    CRN: {
      string: 'Crane',
    },
    BULLDOZER: {
      string: 'Bulldozer',
    },
    GENERATOR: {
      string: 'Generator (Towable)',
    },
    COMPRESSOR: {
      string: 'Compressor (Towable)',
    },
    FORKLIFT: {
      string: 'Forklift',
    },
    FEL: {
      string: 'Front End Loader',
    },
    SS: {
      string: 'Skid-steer',
    },
    EXC: {
      string: 'Excavator',
    },
    SEMI: {
      string: 'Semi / Lorry',
    },
    TEL: {
      string: 'Telehandler',
    },
    DUMP: {
      string: 'Dump Truck',
    },
    VAC: {
      string: 'Vac Truck',
    },
    HT: {
      string: 'Heavy Truck',
    },
    BAR: {
      string: 'Barge',
    },
    OTHER: {
      string: 'Other',
    },
  },
  IT: {
    string: 'IT & Office',
    COMP: {
      string: 'Computer',
    },
    PHONE: {
      string: 'Phone',
    },
    TABLET: {
      string: 'Tablet',
    },
    CAM: {
      string: 'Camera',
    },
    FURN: {
      string: 'Furniture',
    },
    OTHER: {
      string: 'Other',
    },
  },
  TOOL: {
    string: 'Tools & Equipment',
    TB: {
      string: 'Test Bench',
    },
    HANDTOOL: {
      string: 'Hand Tools',
    },
    PWRTOOL: {
      string: 'Power Tools',
    },
    WELD: {
      string: 'Welder',
    },
    COMP: {
      string: 'Compressor',
    },
    LIFT: {
      string: 'Lifting Equipment',
    },
    INSP: {
      string: 'Inspection Equipment',
    },
    HYDRAULIC: {
      string: 'Hydraulic Equipment',
    },
    DRONE: {
      string: 'Drone',
    },
    PUMP: {
      string: 'Pump',
    },
    PPE: {
      string: 'Personal Protective Equipment',
    },
    FSUIT: {
      string: 'Flight Suit',
    },
    FTOOLS: {
      string: 'Flight Tools',
    },
    HARNESS: {
      string: 'Harness',
    },
    HELMET: {
      string: 'Helmet',
    },
    IMSUIT: {
      string: 'Immersion Suit',
    },
    LANYARD: {
      string: 'LANYARD',
    },
    LIGHT: {
      string: 'Light',
    },
    OTHER: {
      string: 'Other',
    },
  },
  PROPERTY: {
    string: 'Property',
    LAND: {
      string: 'Land',
    },
    BUILDING: {
      string: 'Building',
    },
    APPLIANCES: {
      string: 'Appliances',
    },
    FIXED_TANK: {
      string: 'Fixed Tank',
    },
    SEACAN_CONTAINER: {
      string: 'Seacan/Container',
    },
    OTHER: {
      string: 'Other',
    },
  },
  INDUST: {
    string: 'Industrial Equipment',
    HYDROT: {
      string: 'Hydro Test',
    },
    OVCRANE: {
      string: 'Overhead Crane',
    },
    PUMP: {
      string: 'Pump',
    },
    MOBILE_TANK: {
      string: 'Mobile Tank',
    },
    OTHER: {
      string: 'Other',
    },
  },
  EE: {
    string: 'Emergency Equipment',
    FIRSTAID: {
      string: 'First Aid',
    },
    EYEWASHSTATIONS: {
      string: 'Eye Wash Stations',
    },
    AEDs: {
      string: 'AEDs',
    },
    SCBA: {
      string: 'SCBA',
    },
    RESCUEKIT: {
      string: 'Rescue Kit',
    },
    FIRESAFETYEQUIPMENT: {
      string: 'Fire Safety Equipment',
    },
    LIFEPRESERVER: {
      string: 'Life Preserver',
    },
    LIFERAFT: {
      string: 'Life Raft',
    },
    PERSONALLOCATORBEACON: {
      string: 'Personal Locator Beacon',
    },
    RESCUEEQUIPMENT: {
      string: 'Rescue Equipment',
    },
    OTHER: {
      string: 'Other',
    },
  },
};

export const vehicleKeys = [
  'LV',
  'RV',
  'HE',
  'Light Vehicle',
  'Recreation Vehicle',
  'Heavy Equipment',
];

export const assetFields = {
  make: {
    vehicle: {
      title: keys.assets.MAKE,
      tooltip: 'Ford, Audi, etc.',
    },
    asset: {
      title: keys.assets.BRAND,
      tooltip: keys.assets.BRAND,
    },
  },
  model: {
    vehicle: {
      title: keys.assets.MODEL,
      tooltip: 'F-150, Silverado, etc.',
    },
    asset: {
      title: keys.assets.MODEL,
      tooltip: keys.assets.MODEL,
    },
  },
  vin: {
    vehicle: {
      title: keys.assets.VIN,
      tooltip: '5YJSA1DG9DFP13705, etc.',
    },
    asset: {
      title: keys.assets.SERIAL_NUMBER,
      tooltip: '5YJSA1DG9DFP13705, etc.',
    },
  },
};

export const assetTypeStringLookup = {
  'Light Vehicle': 'LIGHT_VEHICLE',
  'Recreation Vehicle': 'RECREATION_VEHICLE',
  'Heavy Equipment': 'HEAVY_EQUIPMENT',
  'IT & Office': 'IT_&_OFFICE',
  'Tools & Equipment': 'TOOLS_&_EQUIPMENT',
  Property: 'PROPERTY',
  'Industrial Equipment': 'INDUSTRIAL_EQUIPMENT',
  'Emergency Equipment': 'EMERGENCY_EQUIPMENT',
};

export const assetSubtypeStringLookup = {
  Car: 'CAR',
  SUV: 'SUV',
  Van: 'VAN',
  Trailer: 'TRAILER',
  Motorcycle: 'MOTORCYCLE',
  Truck: 'TRUCK',
  ATV: 'ATV',
  Boat: 'BOAT',
  Snowmobile: 'Snowmobile',
  Other: 'OTHER',
  Crane: 'CRANE',
  Bulldozer: 'BULLDOZER',
  Generator: 'GENERATOR',
  Compressor: 'COMPRESSOR',
  Forklift: 'FORKLIFT',
  'Front End Loader': 'FRONT_END_LOADER',
  'Skid-steer': 'SKID_STEER',
  Excavator: 'EXCAVATOR',
  'Semi / Lorry': 'SEMI_LORRY',
  Telehandler: 'TELEHANDLER',
  'Dump Truck': 'DUMP_TRUCK',
  'Vac Truck': 'VAC_TRUCK',
  'Heavy Truck': 'HEAVY_TRUCK',
  Barge: 'BARGE',
  Computer: 'COMPUTER',
  Phone: 'PHONE',
  Tablet: 'TABLET',
  Camera: 'CAMERA',
  Furniture: 'FURNITURE',
  'Test Bench': 'TEST_BENCH',
  'Hand Tools': 'HAND_TOOLS',
  'Power Tools': 'POWER_TOOLS',
  Welder: 'WELDER',
  'Lifting Equipment': 'LIFTING_EQUIPMENT',
  'Inspection Equipment': 'INSPECTION_EQUIPMENT',
  'Hydraulic Equipment': 'HYDRAULIC_EQUIPMENT',
  Drone: 'DRONE',
  Pump: 'PUMP',
  Land: 'LAND',
  Appliances: 'APPLIANCES',
  Building: 'BUILDING',
  'Fixed Tank': 'FIXED_TANK',
  'Seecan/Container': 'SEECAN_CONTAINER',
  'Hydro Test': 'HYDRO_TEST',
  'Overhead Crane': 'OVERHEAD_CRANE',
  'Mobile Tank': 'MOBILE_TANK',
  'First Aid': 'FIRST_AID',
  'Eye Wash Stations': 'EYE_WASH_STATIONS',
  AEDs: 'AEDS',
  SCBA: 'SCBA',
  'Rescue Kit': 'RESCUE_KIT',
  'Fire Safety Equipment': 'FIRE_SAFETY_EQUIPMENT',
  'Personal Protective Equipment': 'PERSONAL_PROTECTIVE_EQUIPMENT',
  'Flight Suit': 'FLIGHT_SUIT',
  Harness: 'HARNESS',
  Helmet: 'HELMET',
  'Immersion Suit': 'IMMERSION_SUIT',
  Lanyard: 'LANYARD',
  Light: 'LIGHT',
  'Life Preserver': 'LIFE_PRESERVER',
  'Life Raft': 'LIFE_RAFT',
  'Personal Locator Beacon': 'PERSONAL_LOCATOR_BEACON',
  'Rescue Equipment': 'RESCUE_EQUIPMENT',
};
