import { loader } from 'graphql.macro';
import MTTable from '../../components/tables/MTable';
import { NetworkStatus, useQuery } from '@apollo/client';
import { useMemo, useState } from 'react';
import { useURLParams } from '../../providers/URLParamProvider';
import {
  useTableComponents,
  CustomDateRangeFilter,
  checkDueDateColor,
} from '../../components/tables/MTableComponents';
import { useTranslation } from 'react-i18next';
import { Text } from '../../components/typography';
import { keys } from '../../utilities/translator/translation_keys';
import styles from './ActionItemList.module.scss';
import { Button } from '../../components';
import { useActionItemModal } from '../../hooks/misc';
import { Form } from 'react-bootstrap';
import { useQueryParams } from '../../hooks/misc';
import { useCurrentUser } from '../../providers/UserProvider';
import {
  priorityStringLookup,
  statusStringLookup,
} from '../../components/action_items/action_item_utilities';
import Label from '../../components/action_items/Label';

const actionItemQuery = loader('./ActionItemList.fetch.graphql');
const maxLabels = 3;

export default function ActionItemList() {
  const { page, pageSize, filters, sort } = useURLParams();
  const { t } = useTranslation();
  const { openActionItemModal } = useActionItemModal();
  const { upsertParam, upsertParams, deleteParam, getParam, deleteParams } =
    useQueryParams();
  const [dateSelectors, setDateSelectors] = useState({
    dueDateMin: getParam('dueDate')?.split('__')[0] || '',
    dueDateMax: getParam('dueDate')?.split('__')[1] || '',
    dateCreatedMin: getParam('dateCreated')?.split('__')[0] || '',
    dateCreatedMax: getParam('dateCreated')?.split('__')[1] || '',
  });
  const { user } = useCurrentUser();
  const { dateCell, textCell, userCell, userFilterOptions } =
    useTableComponents();

  const {
    refetch,
    networkStatus,
    previousData: { actionItemsCount: oldCount } = {},
    data: { actionItems = [], actionItemsCount = 0 } = {},
  } = useQuery(actionItemQuery, {
    skip: !page || !pageSize,
    variables: {
      options: {
        page,
        pageSize,
        filters,
        sort,
      },
    },
  });
  const getChecks = () => {
    const assignee = getParam('assignee');
    const allWorkspaces = getParam('allWorkspaces');
    if (assignee === `${user.id}` && !!allWorkspaces) {
      return true;
    }
    return false;
  };
  const assignedToMeCheck = getChecks();
  const openCheck = getParam('open');

  const columns = useMemo(
    () => [
      {
        accessorKey: 'title',
        header: t(keys.common.TITLE),
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorKey: 'assignee',
        header: t(keys.common.ASSIGNEE),
        enableSorting: false,
        Cell: ({ cell }) => userCell({ cell }),
        filterVariant: 'select',
        filterSelectOptions: userFilterOptions,
      },
      {
        accessorKey: 'priority',
        header: t(keys.action_items.PRIORITY),
        filterVariant: 'select',
        size: 120,
        filterSelectOptions: Object.entries(priorityStringLookup).map(
          ([key, v]) => ({ label: t(v.name), value: key }),
        ),
        Cell: ({ cell }) => {
          const status = cell.row.original.status;
          return (
            <Text
              noMargin
              weight="semiBold"
              size="sm"
              truncate
              color={
                status === 'DONE' || status === 'DUPLICATE'
                  ? 'secondary'
                  : priorityStringLookup[cell.getValue()].color
              }
            >
              {t(priorityStringLookup[cell.getValue()].name)}
            </Text>
          );
        },
      },
      {
        accessorKey: 'status',
        header: t(keys.common.STATUS),
        size: 120,
        filterVariant: 'select',
        filterSelectOptions: Object.entries(statusStringLookup).map(
          ([key, v]) => ({ label: t(v.title), value: key }),
        ),
        Cell: ({ cell }) => {
          const status = cell.row.original.status;
          return (
            <Text
              noMargin
              weight="semiBold"
              size="sm"
              truncate
              color={
                status === 'DONE'
                  ? 'secondary'
                  : statusStringLookup[cell.getValue()].color
              }
            >
              {t(statusStringLookup[cell.getValue()].title)}
            </Text>
          );
        },
      },
      {
        accessorKey: 'creator',
        header: t(keys.common.CREATOR),
        enableSorting: false,
        Cell: ({ cell }) => userCell({ cell }),
        filterVariant: 'select',
        filterSelectOptions: userFilterOptions,
      },
      {
        accessorKey: 'dueDate',
        header: t(keys.action_items.DUE_DATE),
        Cell: ({ cell }) => {
          const status = cell.row.original.status;
          const color = checkDueDateColor({ dueDate: cell.getValue(), status });
          return dateCell({ cell }, color);
        },
        size: 275,
        grow: true,
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dueDateMin}
            maxDate={dateSelectors.dueDateMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dueDateMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dueDateMax: value })
            }
          />
        ),
      },
      {
        accessorKey: 'dateCreated',
        header: t(keys.common.DATE_CREATED),
        Cell: ({ cell }) => dateCell({ cell }),
        size: 275,
        grow: true,
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dateCreatedMin}
            maxDate={dateSelectors.dateCreatedMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCreatedMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCreatedMax: value })
            }
          />
        ),
      },
      {
        accessorKey: 'labels',
        header: t(keys.common.LABELS),
        enableColumnFilter: false,
        enableSorting: false,
        size: 300,
        Cell: ({ cell }) => {
          const labels = cell.getValue() || [];
          return (
            <div className={styles.labelContainer}>
              {labels?.slice(0, maxLabels).map((label) => (
                <Label
                  key={label.id}
                  className={styles.label}
                  name={label.name}
                  color={label.color.toLowerCase()}
                />
              ))}
              {labels?.length > maxLabels && (
                <Text
                  size="sm"
                  weight="semiBold"
                  noMargin
                  color="accentPrimary"
                >{`+ ${labels.length - maxLabels}`}</Text>
              )}
            </div>
          );
        },
      },
    ],
    [dateCell, dateSelectors, t, textCell, userCell, userFilterOptions],
  );

  return (
    <MTTable
      isLoading={networkStatus !== NetworkStatus.ready}
      data={actionItems}
      columns={columns}
      onRowClick={(actionItem) =>
        openActionItemModal(actionItem.id, null, refetch)
      }
      header={
        <div className={styles.header}>
          <div>
            <Text size="lg" weight="bold" color="accentPrimary" noMargin>
              {t(keys.common.ACTION_ITEMS)}
            </Text>
          </div>
          <Button
            variant="primary"
            icon="add"
            size="md"
            testId="action-item"
            value={t(keys.common.NEW)}
            onClick={() => {
              openActionItemModal(null, 'VEHICLE', refetch);
            }}
          />
        </div>
      }
      rowCount={actionItemsCount || oldCount}
      customToolbar={
        <div className={styles.check}>
          <Form.Check
            size={'small'}
            checked={assignedToMeCheck}
            onChange={(e) => {
              if (e.target.checked) {
                upsertParams({ assignee: user.id, allWorkspaces: true });
              } else {
                deleteParams(['assignee', 'allWorkspaces']);
              }
              refetch();
            }}
          />
          <Text noMargin size="sm">
            {t(keys.action_items.ASSIGNED_TO_ME)}
          </Text>
          <Form.Check
            size={'small'}
            checked={!!openCheck}
            onChange={(e) => {
              if (e.target.checked) {
                upsertParam('open', true);
              } else {
                deleteParam('open');
              }
            }}
          />
          <Text noMargin size="sm">
            {t(keys.action_items.HIDE_COMPLETED)}
          </Text>
        </div>
      }
    />
  );
}
